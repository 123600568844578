import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AvatarEditor from '..';
import { closeAvatarEditor, uploadAvatar } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.avatarEditor.isOpen,
  isUploading: state.avatarEditor.isUploading
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeAvatarEditor()),
  upload: (blob: Blob) => dispatch(uploadAvatar(blob))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AvatarEditor)
);
