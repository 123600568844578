import { RestService } from '../RestService';

export class VersionService extends RestService {
  async getVersion(): Promise<string> {
    const url = `${this.baseUrl}/version`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    if (response.status >= 200 && response.status <= 299) {
      return (await response.json()).version;
    }

    return '0.0.0';
  }
}
