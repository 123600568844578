import { createAction } from 'redux-actions';
import types from '../types';

export const requestActivity = createAction(types.REQUEST_ACTIVITY);
export const requestActivitySucceeded = createAction(
  types.REQUEST_ACTIVITY_SUCCEEDED
);
export const requestActivityFailed = createAction(
  types.REQUEST_ACTIVITY_FAILED
);

export const requestAlarm = createAction(types.REQUEST_ALARM);
export const requestAlarmSucceeded = createAction(
  types.REQUEST_ALARM_SUCCEEDED
);
export const requestAlarmFailed = createAction(types.REQUEST_ALARM_FAILED);

export const acceptAlarm = createAction(types.ACCEPT_ALARM);
export const acceptAlarmSucceeded = createAction(types.ACCEPT_ALARM_SUCCEEDED);
export const acceptAlarmFailed = createAction(types.ACCEPT_ALARM_FAILED);

export const alarmStartClose = createAction(types.ALARM_START_CLOSE);
export const alarmCancelClose = createAction(types.ALARM_CANCEL_CLOSE);
export const alarmCloseEdit = createAction(types.ALARM_CLOSE_EDIT);

// deprecated?
export const acknowledgeAlarm = createAction(types.ACKNOWLEDGE_ALARM);
// deprecated?
export const acknowledgeAlarmSucceeded = createAction(
  types.ACKNOWLEDGE_ALARM_SUCCEEDED
);
// deprecated?
export const acknowledgeAlarmFailed = createAction(
  types.ACKNOWLEDGE_ALARM_FAILED
);

export const alarmClose = createAction(types.ALARM_CLOSE);
export const alarmCloseSucceeded = createAction(types.ALARM_CLOSE_SUCCEEDED);
export const alarmCloseFailed = createAction(types.ALARM_CLOSE_FAILED);

export const requestAlarmEvents = createAction(types.REQUEST_ALARM_EVENTS);
export const requestAlarmEventsSucceeded = createAction(
  types.REQUEST_ALARM_EVENTS_SUCCEEDED
);
export const requestAlarmEventsFailed = createAction(
  types.REQUEST_ALARM_EVENTS_FAILED
);

export const refetchedAlarms = createAction(types.REFETCHED_ALARMS);
