// @ts-nocheck

import { handleActions } from 'redux-actions';
import { DUMMY_USER_ID } from '../../../../../../constants';
import types from '../../types';

const initialState = {};

export default handleActions(
  {
    [types.REQUEST_RECIPIENTS]: (state, { payload }) => initialState,
    [types.REQUEST_RECIPIENTS_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      [payload.deviceId]: payload.recipients
    }),
    [types.CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      [payload.deviceId]: [
        ...state[payload.deviceId].filter((recipient) => {
          if (
            payload.recipient?.role === 'wearer' &&
            payload.recipient?.user?.id !== DUMMY_USER_ID
          ) {
            // Remove old recipient after updating from no email to email
            return recipient?.user?.id !== DUMMY_USER_ID;
          }
          return (
            payload.recipient.user?.id !== recipient.user?.id ||
            payload.recipient.device.id !== recipient.device.id
          );
        }),
        payload.recipient
      ]
    }),
    [types.DELETE_RECIPIENT_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      [payload.deviceId]: [
        ...state[payload.deviceId].filter(
          (recipient) =>
            payload.recipient.user?.id !== recipient.user?.id ||
            payload.recipient.device.id !== recipient.device.id
        )
      ]
    })
  },
  initialState
);
