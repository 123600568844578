import { useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { types as colors } from '../../../../services/DeviceUtils';

const getOption = (steps) => {
  const MAX_STEPS = 7000;

  let activeColor = colors.GREEN.color;
  if (steps < 5500) activeColor = colors.YELLOW.color;
  if (steps < 3500) activeColor = colors.ORANGE.color;
  if (steps < 2000) activeColor = colors.RED.color;

  return {
    animationDuration: 0,
    grid: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    series: [
      {
        type: 'pie',
        startAngle: -90,
        emphasis: {
          scale: false
        },
        cursor: 'default',
        pointerEvents: 'none',
        textDecoration: 'none',
        animation: false,
        radius: ['100%', '80%'],
        avoidLabelOverlap: false,
        label: {
          padding: 0,
          fontSize: 20,
          fontStyle: 'bold',
          color: activeColor,
          show: true,
          position: 'center',
          formatter: (p) => {
            // This label is rendered for every object in data, to prevent it from rendering
            // twice the formatter only returns if the name in the data object is Completed
            return steps;
          }
        },
        labelLine: {
          padding: 0,
          show: false
        },
        data: [
          {
            value: steps,
            name: 'Active',
            itemStyle: {
              color: activeColor
            }
          },
          {
            value: MAX_STEPS - steps,
            name: 'Inactive',
            itemStyle: {
              color: colors.GREY.colorLight
            }
          }
        ]
      }
    ]
  };
};

const ActivityChart = ({ steps, deviceId, requestActivity, factor }) => {
  useEffect(() => {
    requestActivity(deviceId);
  }, [deviceId, requestActivity]);

  return (
    <ReactEcharts
      option={getOption(Math.round(steps * factor))}
      style={{ height: '100px', width: '100px' }}
    />
  );
};

export default ActivityChart;
