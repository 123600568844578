import { useEffect } from 'react';
import styled from 'styled-components';
import { Avatar as MatAvatar } from '@material-ui/core';
import { matchesScope } from '../DisplayController';
import { DisplayScope } from '../../services/AuthenticationService';
import DefaultImage from '../Avatar/assets/defaultAvatar.png';
const StyledAvatar = styled(MatAvatar)`
  width: 100px !important;
  height: 100px !important;
  cursor: pointer !important;
  margin-bottom: 15px;
`;

const Avatar = ({
  deviceId,
  blobUrl,
  openAvatarEditor,
  requestAvatarImage
}: any) => {
  useEffect(() => {
    if (!blobUrl) {
      requestAvatarImage(deviceId);
    }
  }, [deviceId, blobUrl, requestAvatarImage]);

  return (
    <StyledAvatar
      onClick={() => {
        if (matchesScope(DisplayScope.OWNER, deviceId))
          openAvatarEditor(deviceId);
      }}
      src={blobUrl ? blobUrl : DefaultImage}
    />
  );
};

export default Avatar;
