import { useState } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FullWidthButton } from '../../../../components/Buttons';

import { resetPassword, setPassword } from './actions';
import TextElement from '../../../../components/TextElement';
import Logo from '../../../../assets/logo.png';
import {
  Panel,
  ButtonDiv,
  LogoImg,
  Content,
  LinkText
} from '../../../../components/Panel';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResetPasswordPanel = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  let history = useHistory();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  return (
    <Container>
      <Panel>
        <Content>
          <LogoImg src={Logo} alt="logo" />
          {!token && (
            <>
              <TextElement
                title={'Email'}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                width="100%"
              ></TextElement>
              <ButtonDiv>
                <FullWidthButton
                  variant="contained"
                  color="primary"
                  disabled={email === ''}
                  onClick={() =>
                    dispatch(resetPassword(email.toLowerCase().trim()))
                  }
                >
                  Zurücksetzen
                </FullWidthButton>
              </ButtonDiv>
            </>
          )}
          {token && (
            <>
              <TextElement
                title={'Neues Passwort'}
                required
                password
                type={'password'}
                value={newPassword}
                name="password"
                onChange={(e) => setNewPassword(e.target.value)}
                width="100%"
              ></TextElement>
              <TextElement
                title={'Neues Passwort wiederholen'}
                required
                password
                type={'password'}
                value={confirmNewPassword}
                name="confirmPassword"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                width="100%"
              ></TextElement>
              <ButtonDiv>
                <FullWidthButton
                  variant="contained"
                  color="primary"
                  disabled={
                    newPassword.length < 8 || newPassword !== confirmNewPassword
                  }
                  onClick={() =>
                    dispatch(setPassword({ password: newPassword, token }))
                  }
                >
                  Zurücksetzen
                </FullWidthButton>
              </ButtonDiv>
            </>
          )}
          <LinkText onClick={() => history.push(`/login`)}>Einloggen</LinkText>
        </Content>
      </Panel>
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  ResetPasswordPanel
);
