import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from '../reducer';
import sagas from '../sagas';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
export const configureStore = (preloadedState: any = {}) => {
  let store;
  if (process.env.NODE_ENV === 'development') {
    try {
      store = createStore(
        createRootReducer(history),
        preloadedState,
        compose(
          applyMiddleware(sagaMiddleware, routerMiddleware(history)),
          // @ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );
    } catch {
      console.warn('Could not use redux dev tool, using logger instead');
      const logger = (store) => (next) => (action) => {
        console.group(action.type);
        console.info('dispatching', action);
        let result = next(action);
        console.log('next state', store.getState());
        console.groupEnd();
        return result;
      };
      store = createStore(
        createRootReducer(history),
        preloadedState,
        applyMiddleware(sagaMiddleware, routerMiddleware(history), logger)
      );
    }
  } else {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
    );
  }

  sagaMiddleware.run(sagas);

  return store;
};
