import { RestService } from '../RestService';

type Beacon = {
  major: number;
  minor: number;
  lat: number;
  lng: number;
  location: string;
  address: string;
  postalCode: string;
  town: string;
  changedBy: string;
  deviceId: string;
};

export class BeaconService extends RestService {
  async getDeviceBeacons(deviceId: string): Promise<Beacon[]> {
    const url = `${this.baseUrl}/beacons/${deviceId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      return (await response.json()) as Beacon[];
    }

    return [];
  }

  async updateBeacon(beacon: Beacon): Promise<boolean> {
    const url = `${this.baseUrl}/beacons`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(beacon),
      method: 'PUT'
    });

    if (response.status >= 200 && response.status <= 299) {
      return true;
    }
    return false;
  }
}
