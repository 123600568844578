export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCEDED = 'RESET_PASSWORD_SUCCEDED';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED';
export const SET_PASSWORD_SUCCEDED = 'SET_PASSWORD_SUCCEDED';

const TYPES = {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCEDED,
  RESET_PASSWORD_FAILED,
  SET_PASSWORD,
  SET_PASSWORD_FAILED,
  SET_PASSWORD_SUCCEDED
};

export default TYPES;
