import { Component } from 'react';
import styled from 'styled-components';
import AvatarEditor from '../../../../components/AvatarEditor/container';
import DeviceTile from '../../../../components/DeviceTile/container';
import CloseAlarmModal from '../../../../components/DeviceTile/components/CloseAlarmModal/container';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

class GridOverview extends Component<any> {
  componentDidMount() {
    const { requestDevices } = this.props;
    requestDevices();
  }

  render() {
    const { deviceList } = this.props;
    return (
      <Wrapper>
        {deviceList.devices.map((device) => (
          <DeviceTile
            key={device.id}
            device={device}
            showSettings={true}
            showPopover={false}
            showId={true}
          />
        ))}
        <AvatarEditor />
        <CloseAlarmModal />
      </Wrapper>
    );
  }
}

export default GridOverview;
