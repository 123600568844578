import DeviceTile from '../../../../../../components/DeviceTile/container';
import LoadingIndicator from '../../../../../../components/LoadingIndicator';
import styled from 'styled-components';

const TileWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Info = ({ device }) => {
  return (
    <TileWrapper>
      {!device.loading && device.device && (
        <DeviceTile
          device={device.device}
          showSettings={false}
          showId={false}
        />
      )}
      {device.loading && <LoadingIndicator />}
    </TileWrapper>
  );
};

export default Info;
