import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  showTileView: false,
  addButtonModalOpen: false
};

export default handleActions(
  {
    [types.TOGGLE_LIST_VIEW]: (state, action) => ({
      ...state,
      showTileView: !state.showTileView
    }),
    [types.TOGGLE_ADD_BUTTON_MODAL]: (state, action) => ({
      ...state,
      addButtonModalOpen: !state.addButtonModalOpen
    }),
    [types.ADD_BUTTON_SUCCEEDED]: (state, action) => ({
      ...state,
      addButtonModalOpen: false
    })
  },
  initialState
);
