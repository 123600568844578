import { handleActions } from 'redux-actions';
import types from '../types';

interface IAlarmEvents {
  [deviceId: string]: {
    events: any;
  };
}

const initialState: IAlarmEvents = {};
export default handleActions(
  {
    [types.REQUEST_ALARM_EVENTS]: (state, action: any) => ({
      ...state
    }),
    [types.REQUEST_ALARM_EVENTS_SUCCEEDED]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        events: action.payload.events
      }
    }),
    [types.REQUEST_ALARM_EVENTS_FAILED]: (state, action: any) => ({
      ...state
    })
  },
  initialState
);
