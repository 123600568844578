import styled from 'styled-components';

const ListItemDiv = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(95, 150, 57, 0.1);

  ${({ selected }) =>
    selected &&
    `
    background-color: rgba(32, 33, 36, 0.06);
`}
`;

const ListItem = ({ children, onClick = () => {}, selected = false }) => (
  <ListItemDiv onClick={onClick} selected={selected}>
    {children}
  </ListItemDiv>
);

export default ListItem;
