// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  firstName: '',
  lastName: '',
  email: ''
};

export default handleActions(
  {
    [types.REQUEST_BUTTON_INFO]: (state, { payload }) => initialState,
    [types.REQUEST_OWNER_SUCCEEDED]: (state, { payload }) => payload
  },
  initialState
);
