import { createAction } from 'redux-actions';
import types from '../types';

export const requestAvatarImage = createAction(types.REQUEST_AVATAR_IMAGE);
export const requestAvatarImageSucceeded = createAction(
  types.REQUEST_AVATAR_IMAGE_SUCCEEDED
);
export const requestAvatarImageFailed = createAction(
  types.REQUEST_AVATAR_IMAGE_FAILED
);
