import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { alarmClose, alarmCloseEdit, alarmCancelClose } from '../../../actions';

import EditModal from '..';
import { getRecipients } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  open: state.closeAlarmModal.open,
  alarmReason: state.closeAlarmModal.alarmReason,
  optionalText: state.closeAlarmModal.optionalText,
  alarmId: state.closeAlarmModal.alarmId,
  severity: state.closeAlarmModal.severity,
  trigger: state.closeAlarmModal.trigger,
  deviceId: state.closeAlarmModal.deviceId,
  recipients: getRecipients(state),
});

const mapDispatchToProps = (dispatch) => ({
  edit: (value, scope) => dispatch(alarmCloseEdit({ scope, value })),
  save: (payload) => dispatch(alarmClose(payload)),
  close: () => dispatch(alarmCancelClose())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditModal)
);
