import { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {
  getDeviceStatus,
  getStatusColor,
  getAlarmState,
  getLocationType,
  getTimeZone,
  getFirmwareVersion
} from '../../../../services/DeviceUtils';

import MyPagination from '../Pagination';
import {
  List,
  ListItem,
  Header,
  Cell,
  LabeledDot
} from '../../../../components/List';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & :not:last-child {
    padding-right: 15px;
  }
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
`;
const Title = styled.p`
  font-family: Montserrat Bold;
  font-size: 18px;
  color: #2d3436;
  margin-bottom: 35px;
`;

const PagesizeSelectorDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const PagesizeText = styled.p`
  font-family: Montserrat;
  color: #2d3436;
  font-size: 16px;
  line-height: 38px;
  margin-right: 20px;
`;

const PagesizeSelector = styled(Pagination)`
  margin-left: auto;
  margin-right: auto;

  ul {
    margin-bottom: 0px !important;
  }
`;

const PagesizeSelectorItem = styled(PaginationItem)`
  &:hover {
    button {
      color: #ea6000;
    }
  }

  ${(props) =>
    props.active &&
    `
      button { 
        color: #fff !important;
        background-color: #EA6000 !important;
        border-color: #EA6000 !important;
      }
    `}
`;

const PagesizeSelectorLink = styled(PaginationLink)`
  color: #ea6000;
`;

const getLastSeen = (timestamp) => {
  const now = moment();
  const lastSeen = moment(timestamp);
  const diff = moment.duration(now.diff(lastSeen));

  return `${diff.asDays() > 1 ? `${Math.floor(diff.asDays())}d` : ''}  ${
    Math.floor(diff.asHours()) % 24
  }h ${Math.floor(diff.asMinutes()) % 60}m`;
};

class Table extends Component<any, any> {
  getRows(devices) {
    let deviceRows = devices.map((device: any) => {
      const statusColor = getStatusColor(getDeviceStatus(device));

      /*
      const config = device.c8y_Configuration
        ? JSON.parse(device.c8y_Configuration.config)
        : {};
        */
      return (
        //@ts-ignore
        <ListItem
          key={device.id}
          onClick={() => this.props.selectDevice(device.id)}
          selected={this.props.selectedDeviceId === device.id}
        >
          <Cell width={'120px'} type="custom">
            <LabeledDot
              color={statusColor.color}
              bgColor={statusColor.colorLight}
              textStyle={{ color: '#3e3e3e' }}
            ></LabeledDot>
          </Cell>
          <Cell width={'120px'} type="custom">
            {device.name ? device.name.slice(device.name.length - 6) : '-'}
          </Cell>

          <Cell width={'170px'} css={{ minWidth: '170px' }} type="text">
            {getAlarmState(device)}
          </Cell>
          <Cell width={'140px'} css={{ minWidth: '140px' }} type="text">
            {getLocationType(device)}
          </Cell>
          <Cell width={'140px'} css={{ minWidth: '140px' }} type="text">
            {getTimeZone(device)}
          </Cell>
          <Cell width={'140px'} css={{ minWidth: '140px' }} type="text">
            {getFirmwareVersion(device)}
          </Cell>
          <Cell width={'110px'} css={{ minWidth: '110px' }} textAlign="right">
            {device.c8y_Battery
              ? `${device.c8y_Battery.level.value}${device.c8y_Battery.level.unit}`
              : '-'}
          </Cell>
          <Cell width={'140px'} textAlign="right" css={{ minWidth: '140px' }}>
            {device.c8y_lastSeen
              ? getLastSeen(device.c8y_lastSeen.lastMessage)
              : '-'}
          </Cell>
          <Cell
            pushToRight
            type="button"
            onClick={() => {
              this.props.history.push(
                `/device/${device.id}`
                // `/device`
              );
            }}
            width={'80px'}
            css={{ minWidth: '80px' }}
          >
            Detail
          </Cell>
        </ListItem>
      );
    });
    return deviceRows;
  }

  getSortIndicators(sortMetric) {
    if (sortMetric !== this.props.deviceList.sortMetric) return null;
    else {
      if (this.props.deviceList.sortAsc)
        return <FontAwesomeIcon icon={['far', 'chevron-up']}></FontAwesomeIcon>;
      else
        return (
          <FontAwesomeIcon icon={['far', 'chevron-down']}></FontAwesomeIcon>
        );
    }
  }

  render() {
    let { deviceList, selectDevicePageSize } = this.props;

    if (deviceList.devices.length > 0) {
      const currentPage = deviceList.currentPage;
      const totalPages = Math.ceil(
        deviceList.devices.length / deviceList.pageSize
      );
      const cursor = currentPage * deviceList.pageSize;
      const pageDevices = deviceList.devices.slice(
        cursor,
        cursor + deviceList.pageSize
      );
      const sortDevices = this.props.sortDevices;

      return (
        <Wrapper>
          <TitleRow>
            <Title>Secufy Sicherheitsbegleiter</Title>
            <PagesizeSelectorDiv>
              <PagesizeText>Anzahl je Seite:</PagesizeText>
              <PagesizeSelector>
                <PagesizeSelectorItem
                  active={deviceList.pageSize === 5}
                  disabled={false}
                  key={5}
                >
                  <PagesizeSelectorLink
                    onClick={() => {
                      selectDevicePageSize(5);
                    }}
                  >
                    5
                  </PagesizeSelectorLink>
                </PagesizeSelectorItem>
                <PagesizeSelectorItem
                  active={deviceList.pageSize === 10}
                  disabled={false}
                  key={10}
                >
                  <PagesizeSelectorLink
                    onClick={() => {
                      selectDevicePageSize(10);
                    }}
                  >
                    10
                  </PagesizeSelectorLink>
                </PagesizeSelectorItem>
                <PagesizeSelectorItem
                  active={deviceList.pageSize === 20}
                  disabled={false}
                  key={20}
                >
                  <PagesizeSelectorLink
                    onClick={() => {
                      selectDevicePageSize(20);
                    }}
                  >
                    20
                  </PagesizeSelectorLink>
                </PagesizeSelectorItem>
                <PagesizeSelectorItem
                  active={deviceList.pageSize === 50}
                  disabled={false}
                  key={50}
                >
                  <PagesizeSelectorLink
                    onClick={() => {
                      selectDevicePageSize(50);
                    }}
                  >
                    50
                  </PagesizeSelectorLink>
                </PagesizeSelectorItem>
                <PagesizeSelectorItem
                  active={deviceList.pageSize === 100}
                  disabled={false}
                  key={100}
                >
                  <PagesizeSelectorLink
                    onClick={() => {
                      selectDevicePageSize(100);
                    }}
                  >
                    100
                  </PagesizeSelectorLink>
                </PagesizeSelectorItem>
              </PagesizeSelector>
            </PagesizeSelectorDiv>
          </TitleRow>
          <List>
            <Header>
              <Cell
                width={'120px'}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('status')}
              >
                STATUS {this.getSortIndicators('status')}
              </Cell>
              <Cell
                width={'120px'}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('id')}
              >
                GERÄTE ID {this.getSortIndicators('id')}
              </Cell>

              <Cell
                width={'170px'}
                css={{ minWidth: '170px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('alarmState')}
              >
                ALARMSTATUS {this.getSortIndicators('alarmState')}
              </Cell>
              <Cell
                width={'140px'}
                css={{ minWidth: '140px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('location')}
              >
                POSITIONSART {this.getSortIndicators('location')}
              </Cell>
              <Cell
                width={'140px'}
                css={{ minWidth: '140px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('timeZone')}
              >
                ZEITZONE {this.getSortIndicators('timeZone')}
              </Cell>
              <Cell
                width={'140px'}
                css={{ minWidth: '140px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('firmwareVersion')}
              >
                FIRMWARE {this.getSortIndicators('firmwareVersion')}
              </Cell>
              <Cell
                width={'110px'}
                css={{ minWidth: '110px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('battery')}
                textAlign="right"
              >
                BATTERIE {this.getSortIndicators('battery')}
              </Cell>
              <Cell
                width={'140px'}
                css={{ minWidth: '140px' }}
                type="text"
                textStyle={{ bold: true }}
                onClick={() => sortDevices('lastSeen')}
                textAlign="right"
              >
                KONTAKT {this.getSortIndicators('lastSeen')}
              </Cell>
              <Cell
                pushToRight
                width={'80px'}
                css={{ minWidth: '80px' }}
              ></Cell>
            </Header>
            {this.getRows(pageDevices)}
          </List>

          {deviceList.devices.length > 0 && (
            <MyPagination
              totalPages={totalPages}
              currentPage={currentPage}
              onAction={(page: number) => {
                this.props.selectPage(page);
              }}
            ></MyPagination>
          )}
        </Wrapper>
      );
    } else return null;
  }
}

export default Table;
