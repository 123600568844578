import styled from 'styled-components';

import Text from '../Text';
import Dot from '../LabeledDot';
import Button from '../Button';

const CellDiv = styled.div<any>`
  padding: 10px;
  display: flex;
  flex-direction: row;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ pushToRight }) => pushToRight && `margin-left: auto;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ textAlign }) =>
    textAlign &&
    `
    p {
      text-align: right;
      width: 100%;
    }
  `}
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media (max-width: 991.92px) {
    display: none;
    }
  `}
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer
  `}

  @media (max-width: 991.92px) {
    margin-left: 0px;
  }

  ${({ css }) => css && css}
`;

const getElement = (props: any) => {
  const { children, textStyle, type, color, onClick, size } = props;
  switch (type) {
    case 'dot':
      return (
        <Dot textStyle={textStyle} color={color} size={size}>
          {children}
        </Dot>
      );
    case 'button':
      return (
        //@ts-ignore
        <Button textStyle={textStyle} onClick={onClick}>
          {children}
        </Button>
      );
    case 'custom':
      return children;

    case 'text':
    default:
      return <Text textStyle={textStyle}>{children}</Text>;
  }
};

const Cell = (props: any) => {
  const {
    pushToRight,
    width,
    hideOnMobile,
    css,
    textAlign,
    onClick = () => {}
  } = props;

  return (
    <CellDiv
      pushToRight={pushToRight}
      width={width}
      hideOnMobile={hideOnMobile}
      css={css}
      onClick={onClick}
      textAlign={textAlign}
    >
      {getElement(props)}
    </CellDiv>
  );
};

export default Cell;
