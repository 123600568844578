import { put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import types from '../types';
import {
  uploadAvatarSucceeded,
  uploadAvatarFailed,
  deleteAvatarSucceeded,
  deleteAvatarFailed
} from '../actions';
import { ImageService } from '../../../services/ImageService';
import { getAvatarImageName } from '../../Avatar/utils';

const getAvatarEditorDeviceId = (state) => state.avatarEditor.deviceId;

function* uploadAvatar(action) {
  try {
    const deviceId = yield select(getAvatarEditorDeviceId);
    const imageService = new ImageService();

    const blob = action.payload;
    const imageName = getAvatarImageName(deviceId);

    const imageUrl = yield call(
      imageService.uploadImage.bind(imageService),
      imageName,
      blob
    );

    yield put(uploadAvatarSucceeded({ deviceId, imageUrl }));
  } catch (error) {
    toast.error('Could not upload profile picture.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(uploadAvatarFailed());
  }
}

function* deleteAvatar(action) {
  try {
    const deviceId = action.payload;
    const imageService = new ImageService();

    const imageName = getAvatarImageName(deviceId);

    yield call(imageService.deleteImage.bind(imageService), imageName);

    yield put(deleteAvatarSucceeded({ deviceId }));
  } catch (error) {
    toast.error('Could not delete profile picture.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(deleteAvatarFailed());
  }
}

const sagas = [
  takeEvery(types.UPLOAD_AVATAR, uploadAvatar),
  takeEvery(types.DELETE_AVATAR, deleteAvatar)
];

export default sagas;
