import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ActivityChart from '..';
import { requestActivity } from '../../../actions';
import { getSteps } from '../../../selectors';

const mapStateToProps = (state, ownProps) => ({
  steps: getSteps(state, ownProps)
});

const mapDispatchToProps = (dispatch) => ({
  requestActivity: (deviceId) => dispatch(requestActivity({ deviceId }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivityChart)
);
