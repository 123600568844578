import styled from 'styled-components';
import sizeMe from 'react-sizeme';
import { Nav, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { AuthenticationService } from '../../../services/AuthenticationService';

const Container = styled(Nav)`
  display: flex;
  flex-direction: row;

  padding-top: env(safe-area-inset-top);

  width: 100%;
  height: calc(46px + env(safe-area-inset-top));
  background-color: #ffffff;
  box-shadow: 1px 0px 4px #aaaaaa;

  z-index: 1020;
`;

// Helps to center logo for mobile (same with as logout button)
const LeftDummy = styled.div`
  width: 79px;

  @media (min-width: 700px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  display: flex;
  margin: 0 auto;
  height: 100%;
  object-fit: contain;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 700px) {
    margin-left: 20px;
  }
`;

const LogoutButton = styled(Button)`
  height: 35px;
  width: 110px;

  border-radius: 8px;
  background-color: #ea6000;
  border-color: #ea6000;

  color: #ffffff;
  font-size: 15px;

  font-family: 'Montserrat Medium';
  &:hover {
    background-color: #ea6000;
    border-color: #ea6000;
  }

  &:focus {
    background-color: #ea6000;
    border-color: #ea6000;
  }
`;

const LogoutElement = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  padding: 5.5px 0;

  @media (max-width: 700px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: #ea6000;
    border-color: #ea6000;
  }
`;

const LogoutIconDiv = styled.div`
  margin: auto 0px;
`;

const Navbar = (props) => {
  const hideLogout = props.hideLogout || false;
  const { width } = props.size;
  const authService = AuthenticationService.instance;
  const history = useHistory();

  return (
    <Container>
      <LeftDummy></LeftDummy>
      <StyledImg src={Logo} alt="logo" />
      {!hideLogout && (
        <LogoutElement>
          {width >= 700 ? (
            <LogoutButton
              onClick={async () => {
                await authService.logout();
                history.push('/login');
              }}
            >
              Abmelden
            </LogoutButton>
          ) : (
            <LogoutIconDiv
              onClick={async () => {
                await authService.logout();
                history.push('/login');
              }}
            >
              <FontAwesomeIcon
                color="#707070"
                size="lg"
                icon={['far', 'sign-out-alt']}
              />
            </LogoutIconDiv>
          )}
        </LogoutElement>
      )}
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(Navbar);
