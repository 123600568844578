import { Component } from 'react';
import styled from 'styled-components';
import SubHeader from '../SubHeader';
import { limitUnicodeString } from '../../../../../../utils';
import { InputArea } from '../InputArea';
import { matchesScope } from '../../../../../../components/DisplayController';
import { DisplayScope } from '../../../../../../services/AuthenticationService';
import InfoPopover from '../../../../../../components/InfoPopover';

let linebreak = '';
for (let i = 0; i < 256; i++) linebreak += ' ';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/** Page Content */
class EmergencyPlans extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  render() {
    const { edit, save, emergencyPlans, device } = this.props;

    return (
      <Div>
        <SubHeader>
          <p>Notfallplan:</p>
          <InfoPopover id={'emergencyPlans'} title={'Notfallplan'}>
            {
              'Sie sollten sich einen Ablaufplan der auszuführenden Aktionen im Notfall oder bei Warnungen überlegen. Ein gut durchdachter Notfallplan kann im Falle eines Alarms äußerst hilfreich sein, da diese Informationen durch die hinterlegten Sicherheitskontakte an eine Rettungsstelle oder Rettungskraft mitgeteilt werden können.'
            }
          </InfoPopover>
        </SubHeader>
        <p>Ablauf bei einem Alarm Zuhause:</p>

        <InputArea
          name="alertPlanHome"
          placeholder={`Klicken Sie in ein Feld, um einen Notfallplan zu tippen oder einzufügen. Die grauen vorausgefüllten Beispieltexte verschwinden, sobald Sie zu tippen beginnen.${linebreak}
          ${linebreak}
z.B. Es wurde ein Notfall bei Max Mustermann gemeldet. Er befindet sich aktuell zu Hause (Adresse). Bitte gehe wie folgt vor:${linebreak}
1. Probiere Max unter seiner privaten Festnetznummer (06131/123456) zu erreichen.${linebreak}
2. Probiere Max unter seiner privaten Mobilfunknummer (0151/123456) zu erreichen.${linebreak}
3. Falls beide Kontaktversuche nicht erfolgreich waren, kontaktiere Sohn Michael Mustermann und kläre, wer zu Max fährt.${linebreak}
4. Falls keiner der Sicherheitskontakte zu Max kann, dann wähle den Notruf!`}
          type={'text'}
          value={emergencyPlans.alertPlanHome}
          onChange={(e) => {
            edit('alertPlanHome', limitUnicodeString(e.target.value, 1500));
          }}
          onBlur={() => save()}
          disabled={!matchesScope(DisplayScope.OWNER, device.device?.id)}
        ></InputArea>

        <p></p>
        <p></p>
        <p>Ablauf bei einem Alarm außer Haus:</p>

        <InputArea
          name="alertPlanOutside"
          placeholder={`Klicken Sie in ein Feld, um einen Notfallplan zu tippen oder einzufügen. Die grauen vorausgefüllten Beispieltexte verschwinden, sobald Sie zu tippen beginnen.${linebreak}
          ${linebreak}
z.B. Es wurde ein Notfall bei Max Mustermann gemeldet. Er befindet sich aktuell bei der ermittelten Adresse. Bitte gehe wie folgt vor:${linebreak}
1. Probiere Max unter seiner privaten Mobilfunknummer (0151/123456) zu erreichen.${linebreak}
2. Falls der Kontaktversuch nicht erfolgreich war, kontaktiere Sohn Michael Mustermann und kläre, wer zu Max fährt.${linebreak}
3. Falls keiner der kein Sicherheitskontakte zu Max kann, dann wähle den Notruf!`}
          type={'text'}
          value={emergencyPlans.alertPlanOutside}
          onChange={(e) =>
            edit('alertPlanOutside', limitUnicodeString(e.target.value, 1500))
          }
          onBlur={() => save()}
          disabled={!matchesScope(DisplayScope.OWNER, device.device?.id)}
        ></InputArea>

        <p></p>
        <p></p>
        <p>Ablauf bei einer Warnung:</p>

        <InputArea
          name="warningPlan"
          placeholder={`Klicken Sie in ein Feld, um einen Notfallplan zu tippen oder einzufügen. Die grauen vorausgefüllten Beispieltexte verschwinden, sobald Sie zu tippen beginnen.${linebreak}
          ${linebreak}
z.B. Wir haben erkannt, dass der Sicherheitsbegleiter von Max Mustermann aktuell nicht in Verwendung ist bzw. nicht getragen wird.${linebreak}
Bitte kontaktiere Max unter seiner privaten Telefonnummer und vergewissere dich, dass er den Sicherheitsbegleiter mit ausreichend Akku direkt mit sich führt.`}
          type={'text'}
          value={emergencyPlans.warningPlan}
          onChange={(e) =>
            edit('warningPlan', limitUnicodeString(e.target.value, 1500))
          }
          onBlur={() => save()}
          disabled={!matchesScope(DisplayScope.OWNER, device.device?.id)}
        ></InputArea>
      </Div>
    );
  }
}

export default EmergencyPlans;
