import styled from 'styled-components';

const OuterDiv = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 20px;
  font-family: Montserrat;
  cursor: pointer;
`;

const CheckBoxDiv = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  margin: auto 0px;
  pointer-events: none;
`;

const Label = styled.p`
  margin-left: 20px;
  margin-bottom: 0px;
`;

interface ICheckbox {
  checked: boolean;
  onChange: (boolean) => void;
  children?: string;
}

const Checkbox = ({ checked, onChange, children = '' }: ICheckbox) => {
  const id = Math.random();
  return (
    <OuterDiv onClick={() => onChange(!checked)}>
      <CheckBoxDiv className="checkbox">
        <input
          type="checkbox"
          id={'custom-checkbox-' + id}
          checked={checked}
          readOnly
        />
        <label htmlFor={'custom-checkbox-' + id}></label>
      </CheckBoxDiv>
      <Label>{children}</Label>
    </OuterDiv>
  );
};
export default Checkbox;
