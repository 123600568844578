import { AuthenticationService } from '../AuthenticationService';
import { HttpService } from '../HttpService';

export class RestService extends HttpService {
  protected authService: AuthenticationService;

  constructor() {
    super();
    this.authService = AuthenticationService.instance;
  }

  protected async getAuthHeader() {
    const token = await this.authService.getAccessToken();
    if (!token) return '';
    return `Bearer ${token}`;
  }
}
