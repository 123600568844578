import styled from 'styled-components';
import { Button as ReactstrapButton } from 'reactstrap';

const StyledButton = styled(ReactstrapButton)`
  border: 1px solid #ea6000;
  background-color: #fcefe6;
  font-size: 14px;
  font-family: Montserrat Bold !important;
  font-weight: bold;
  color: #ea6000;
  margin-top: -5px;
  margin-bottom: -5px;

  :hover {
    border: 1px solid #ea6000;
    background-color: #ea6000;
    color: #fff;
  }
`;

const Button = ({ children, onClick }) => (
  <StyledButton onClick={onClick}>{children}</StyledButton>
);

export default Button;
