import InternalButton from '@material-ui/core/Button';
import styled from 'styled-components';

const InternalFullWidthButton = styled(InternalButton)`
  width 100%;
`;

const ButtonWrapper = styled.div`
  .MuiButton-containedPrimary {
    color: #fff !important;
    background-color: #ea6000 !important;
  }

  .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
`;

const FullWidthButtonWrapper = styled(ButtonWrapper)`
  width: 100%;
`;

export const FullWidthButton = (props) => (
  <FullWidthButtonWrapper>
    <InternalFullWidthButton {...props} />
  </FullWidthButtonWrapper>
);

export const Button = (props) => (
  <ButtonWrapper>
    <InternalButton {...props} />
  </ButtonWrapper>
);
