import styled from 'styled-components';

const RowDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 991.92px) {
    flex-direction: column;
  }
`;

const Row = ({ children }) => <RowDiv>{children}</RowDiv>;

export default Row;
