import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { editEmergencyPlans, saveEmergencyPlans } from '../../../actions';

import EmergencyPlans from '..';

const mapStateToProps = (state, ownProps) => ({
  emergencyPlans: state.emergencyPlans,
  device: state.device
});

const mapDispatchToProps = (dispatch) => ({
  edit: (scope, value) => dispatch(editEmergencyPlans({ scope, value })),
  save: () => dispatch(saveEmergencyPlans())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmergencyPlans)
);
