import moment from 'moment';
import { C8yBaseService } from '../C8yBaseService';

export interface IMeasurement {
  [key: string]: { value: number; unit: string };
}
export interface IMeasurementBase {
  id: string;
  self: string;
  time: string;
  type: string;
  source: { id: string };
  secufy_Actipoints: IMeasurement | string | { id: string };
}

export class MeasurementService extends C8yBaseService {
  private async fetchMeasurementsFromCumulocity(
    deviceId: string,
    now: string,
    dateFrom: string,
    type: string,
    count: number,
    currentPage: number
  ) {
    console.log('fetching page', currentPage);
    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/measurement/measurements?source=${deviceId}&dateTo=${now}&dateFrom=${dateFrom}&type=${type}&pageSize=${count}&currentPage=${currentPage}&revert=true`,
      {
        headers: {
          Authorization,
          'Content-Type': 'application/json'
        },
        method: 'GET'
      }
    );
    if (response.status >= 200 && response.status <= 299) {
      return (await response.json()).measurements;
    }
    return [];
  }

  async getMeasurements(
    deviceId: string,
    days: number,
    type: string = 'secufy_Actipoints',
    count: number = 65536 // Cumulocity will set this to 2000 anyways
  ) {
    const now = encodeURIComponent(
      moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    );

    const dateFrom = encodeURIComponent(
      moment().subtract(days, 'days').utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    );

    let currentPage = 1;
    let measurements: Array<any> = [];
    let newMeasurements: Array<IMeasurementBase> = [];
    do {
      newMeasurements = await this.fetchMeasurementsFromCumulocity(
        deviceId,
        now,
        dateFrom,
        type,
        count,
        currentPage
      );
      let mappedMeasurements = newMeasurements.map((m) => {
        let retMeasurement = { time: m.time };
        for (const key in m[type]) {
          retMeasurement[key] = m[type][key]['value'];
        }
        return retMeasurement;
      });
      measurements = measurements.concat(mappedMeasurements);
      currentPage++;
    } while (newMeasurements.length && measurements.length < count);

    return measurements;
  }
}
