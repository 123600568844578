import styled from 'styled-components';

const PinDiv = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  top: -10px;
  left: -10px;
  background-color: ${({ color }) => color};
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  border-color: #fff;
  border-width: 5px;
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);

  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  cursor: pointer;
`;

const PinTitle = styled.div`
  position: absolute;
  top: -52px;
  z-index: 50;
  height: 0px;

  div {
    left: calc(-50% + 5px);
    position: relative;
    border: 0px solid #006fe6;
    color: #ea6000;
    border-radius: 5px;
    margin: 8px 0px;
    padding: 4px 10px;
    color: #fff !important;
    background-color: #ea6000 !important;
    white-space: nowrap;
    cursor: pointer;
    box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);

    :after {
      background: linear-gradient(
        45deg,
        rgba(234, 96, 0, 1) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
      content: '';
      height: 15px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 15px;
    }
  }

  h6 {
    margin-bottom: 0px !important;
  }
`;

const Pin = ({ name, onClick, color = '#ea6000' }) => (
  <PinDiv onClick={onClick} color={color}>
    {name && (
      <PinTitle>
        <div>
          <h6>{name}</h6>
        </div>
      </PinTitle>
    )}
  </PinDiv>
);
export default Pin;
