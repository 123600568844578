import moment from 'moment';

type StatusColorSet = {
  color: string;
  colorLight: string;
};
type StatusColorSets = {
  [key: string]: StatusColorSet;
};

const RED: StatusColorSet = {
  color: '#f44336',
  colorLight: '#fbbdb8'
};

const ORANGE: StatusColorSet = {
  color: '#ff9800',
  colorLight: '#'
};

const YELLOW: StatusColorSet = {
  color: '#ffeb3b',
  colorLight: '#fff7b1'
};

const GREEN: StatusColorSet = {
  color: '#4caf50',
  colorLight: '#c6e6c7'
};

const BLUE: StatusColorSet = {
  color: '#03a9f4',
  colorLight: '#cceefc'
};

const GREY: StatusColorSet = {
  color: '#9e9e9e',
  colorLight: '#d9d9d9'
};

const LOCATION_TYPES = {
  gnss: 'GNSS',
  cellular: 'Cellular',
  ble: 'BLE'
};

export const types: StatusColorSets = {
  RED,
  ORANGE,
  GREEN,
  YELLOW,
  BLUE,
  GREY,
  UNKOWN: GREY,
  INFO: BLUE,
  ONLINE: GREEN,
  WARNING: YELLOW,
  OFFLINE: RED,
  GOOD: GREEN
};

export const getStatusColor = (status: string): StatusColorSet => {
  if (typeof types[status] !== 'undefined') return types[status];
  else return types.UNKOWN;
};

export const getAlarmState = (device) => {
  var value = '-';
  try {
    if (
      device.c8y_ActiveAlarmsStatus.critical === 0 &&
      device.c8y_ActiveAlarmsStatus.warning === 0
    ) {
      value = 'OK';
    }

    if (device.c8y_ActiveAlarmsStatus.critical > 0) {
      value = 'ALARM';
      if (device.c8y_ActiveAlarmsStatus.critical > 1) {
        value = value + ' (' + device.c8y_ActiveAlarmsStatus.critical + ')';
      }
    }
    if (device.c8y_ActiveAlarmsStatus.warning > 0) {
      value = 'WARNUNG';
      if (device.c8y_ActiveAlarmsStatus.warning > 1) {
        value = value + ' (' + device.c8y_ActiveAlarmsStatus.warning + ')';
      }
    }

    return value;
  } catch {
    return value;
  }
};

export const getLocationType = (device) => {
  try {
    const type = device.c8y_PositionMeta.type;
    return LOCATION_TYPES[type];
  } catch {
    return '-';
  }
};

export const getTimeZone = (device) => {
  try {
    const timeZone = device.secufy_Time.timezone;
    return timeZone;
  } catch {
    return '-';
  }
};

export const getFirmwareVersion = (device) => {
  try {
    const firmwareVersion = device.secufy_Hardware.firmwareVersion.toString(16);
    return firmwareVersion;
  } catch {
    return '-';
  }
};

export const getMarkerColor = (device) => {
  try {
    const type = device.c8y_PositionMeta.type;
    if (type === 'ble') return BLUE.color;
    if (type === 'gnss') return GREEN.color;
    if (type === 'cellular') return YELLOW.color;
    return GREY.color;
  } catch {
    return GREY.color;
  }
};

export const getDeviceStatus = (device) => {
  try {
    const timestamp = device.c8y_lastSeen.lastMessage;
    const batteryLevel = device.c8y_Battery.level.value;
    if (typeof timestamp === 'undefined') return 'OFFLINE';

    const now = moment();
    const lastSeen = moment(timestamp);
    const diff = moment.duration(now.diff(lastSeen));

    if (diff.asHours() < 25 && batteryLevel > 25) return 'GREEN';
    if (diff.asHours() < 49 && batteryLevel > 15) return 'YELLOW';
    return 'RED';
  } catch {
    return 'UNKNOWN';
  }
};
