import styled from 'styled-components';
import { Spinner } from 'reactstrap';

const SpinnerWrapper = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px auto;

  &.spinner-border {
    border-top-color: #ea6000;
    border-left-color: #ea6000;
    border-bottom-color: #ea6000;
  }
`;

const LoadingIndicators = () => (
  <SpinnerWrapper>
    <StyledSpinner />
  </SpinnerWrapper>
);

export default LoadingIndicators;
