import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Toc from '@material-ui/icons/Toc';
import ViewComfy from '@material-ui/icons/ViewComfy';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

import Layout from '../../components/Layout';
import WithAuthentication from '../../components/WithAuthentication';
import ListOverview from './components/ListOverview/container';
import GridOverview from './components/GridOverview/container';
import { addButton } from './actions';
import { Button, FullWidthButton } from '../../components/Buttons';
import { matchesScope } from '../../components/DisplayController';
import { DisplayScope } from '../../services/AuthenticationService';
import { DeviceService } from '../../services/DeviceService';
import { RefetchService } from '../../services/RefetchService';

const AddButtonDiv = styled.div`
  margin-left: auto !important;
  margin-right: 0px !important;
`;

const FallbackHelperButtonDiv = styled.div`
  margin-left: 20px !important;
`;

const SaveButtonDiv = styled.div`
  margin-top: 40px !important;
  margin-right: auto !important;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
`;

const EmailTextField = styled(TextField)`
  margin-top: 20px !important;
`;

export const Title = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  p {
    font-family: Montserrat Bold;
    font-size: 24px;
    color: #2d3436;
    margin-bottom: 0px;
  }

  padding-bottom: 30px;

  ${({ withBorder }) =>
    withBorder &&
    `
  border-bottom-style: solid;
  border-bottom-color: #bdc3c7;
  border-bottom-width: 1px;  
  `}

  margin-bottom: 40px;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  height: 36px !important;
  margin-left: 50px;
  background-color: transparent !important;

  button {
    padding: 0px !important;
  }
`;

/** Page Content */
const Overview = (props) => {
  const {
    size,
    toggleListView,
    showTileView,
    addButtonModalOpen,
    acceptAlarm,
    toggleAddButtonModal,
    refetchedDeviceList
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // Anything in here is fired on component mount.
    const refetchService = RefetchService.instance;
    if (!matchesScope(DisplayScope.SECUFY_ADMIN)) {
      refetchService.addRefetchFunction({
        id: 'refetchDeviceList',
        func: refetchDeviceList
        // args: [refetchedDeviceList]
      });
    }

    return () => {
      // Anything in here is fired on component unmount.
      const refetchService = RefetchService.instance;
      refetchService.removeRefetchFunction('refetchDeviceList');
    };
  });

  const refetchDeviceList = async () => {
    const deviceService = new DeviceService();
    const devices = await deviceService.getDevices();
    refetchedDeviceList(devices);

    // TODO JUST CALL TRIGGER ACTION
    // REFETCH_DEVICE_LIST => saga (... insert code above into sage) => REFETCH_DEVICE_LIST_SUCCEEDED
  };

  const isMobile = size.width < 600;
  const [buttonId, setButtonId] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    if (params.get('acceptAlarm') !== null) {
      acceptAlarm(params.get('acceptAlarm'));
    }
  }, [acceptAlarm, params]);

  return (
    <WithAuthentication>
      <Layout>
        <Title>
          <p>Übersicht</p>

          {!isMobile && matchesScope(DisplayScope.SECUFY_ADMIN) && (
            <StyledBottomNavigation
              value={showTileView ? 1 : 0}
              onChange={() => {
                toggleListView();
              }}
            >
              <BottomNavigationAction icon={<Toc />} />
              <BottomNavigationAction icon={<ViewComfy />} />
            </StyledBottomNavigation>
          )}

          {matchesScope(DisplayScope.SECUFY_ADMIN) && (
            <FallbackHelperButtonDiv>
              {size.width >= 700 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.history.push(`/fallbackhelper`);
                  }}
                >
                  Notfallkontakte
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.history.push(`/fallbackhelper`);
                  }}
                >
                  #
                </Button>
              )}
            </FallbackHelperButtonDiv>
          )}

          <AddButtonDiv>
            {size.width >= 700 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleAddButtonModal()}
              >
                Sicherheitsbegleiter hinzufügen
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleAddButtonModal()}
              >
                +
              </Button>
            )}
          </AddButtonDiv>
        </Title>
        {showTileView ||
        isMobile ||
        !matchesScope(DisplayScope.SECUFY_ADMIN) ? (
          <GridOverview />
        ) : (
          <ListOverview />
        )}
      </Layout>
      <Dialog
        onClose={() => toggleAddButtonModal()}
        aria-labelledby="simple-dialog-title"
        open={addButtonModalOpen}
      >
        <DialogTitle id="simple-dialog-title">
          Sicherheitsbegleiter hinzufügen
        </DialogTitle>
        <ModalContent>
          <TextField
            id="buttonIdInput"
            label="Sicherheitsbegleiter ID"
            onChange={(e) => setButtonId(e.target.value)}
            value={buttonId}
          />
          <EmailTextField
            id="buyerEmailInput"
            label="Email Adresse des Käufers"
            onChange={(e) => setBuyerEmail(e.target.value)}
            value={buyerEmail}
          />
          <SaveButtonDiv>
            <FullWidthButton
              variant="contained"
              color="primary"
              onClick={() => dispatch(addButton({ buttonId, buyerEmail }))}
              disabled={buttonId.length < 5}
            >
              Hinzufügen
            </FullWidthButton>
          </SaveButtonDiv>
        </ModalContent>
      </Dialog>
    </WithAuthentication>
  );
};

export default sizeMe()(Overview);
