import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import LoadingIndicators from '../../components/LoadingIndicator';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

/** Page Content */
const VerifyButtonOwnership = ({
  confirmOwnership,
  verifying,
  verified,
  verifyFailed
}) => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  if (token) {
    if (!verifying && !verified && !verifyFailed) {
      confirmOwnership(token);
    }

    if (verifying) {
      return (
        <Layout hideLogout>
          <CenteredDiv>
            <LoadingIndicators />
          </CenteredDiv>
        </Layout>
      );
    }

    if (verifyFailed) {
      return (
        <Layout hideLogout>
          <CenteredDiv>
            <p>Etwas ist schiefgelaufen. Versuche es später erneut.</p>
          </CenteredDiv>
        </Layout>
      );
    }

    return (
      <Layout hideLogout>
        <CenteredDiv>
          <p>Die Verwaltungsrechte wurden erfolgreich übertragen.</p>
        </CenteredDiv>
      </Layout>
    );
  } else {
    history.push('/');
    return <Layout hideLogout />;
  }
};

export default VerifyButtonOwnership;
