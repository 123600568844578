export class HTTPError extends Error {
  constructor(message?: string, public statusCode?: number) {
    super(message);
  }
}

export class HttpService {
  protected baseUrl: string = '/app/v1';

  protected setBaseUrl() {
    if (process.env.REACT_APP_PLATFORM !== 'web') {
      if (!process.env.REACT_APP_ENDPOINT)
        throw new Error(
          'REACT_APP_ENDPOINT env variable must be provided for mobile platforms!'
        );
      this.baseUrl = `${process.env.REACT_APP_ENDPOINT}${this.baseUrl}`;
    }
  }

  constructor() {
    this.setBaseUrl();
  }
}
