import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Avatar from '..';
import { openAvatarEditor } from '../../AvatarEditor/actions';
import { requestAvatarImage } from '../actions';
import { getAvatarBlobUrl } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  blobUrl: getAvatarBlobUrl(state, ownProps)
});

const mapDispatchToProps = (dispatch) => ({
  requestAvatarImage: (deviceId) => dispatch(requestAvatarImage(deviceId)),
  openAvatarEditor: (deviceId) => dispatch(openAvatarEditor(deviceId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Avatar));
