// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  alertPlanHome: '',
  alertPlanOutside: '',
  warningPlan: ''
};

export default handleActions(
  {
    [types.GET_DEVICE_SUCCEEDED]: (state, { payload }) => {
      return payload.secufy_EmergencyPlans
        ? payload.secufy_EmergencyPlans
        : initialState;
    },
    [types.EDIT_EMERGENCY_PLANS]: (state, { payload }) => {
      const { scope, value } = payload;

      return {
        ...state,
        [scope]: value
      };
    }
  },
  initialState
);
