import jwt from 'jsonwebtoken';
import store from 'store';
import { HttpService } from './HttpService';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  error?: string;
  resendMail?: string;
};

export enum DisplayScope {
  SECUFY_ADMIN = 30,
  OWNER = 20,
  HELPER = 10
}

export enum ConfirmMailState {
  SUCCESS,
  FAILURE,
  TOKEN_EXPIRED_AND_MAIL_SENT
}

/**
 * Manages user credentials
 */
export class AuthenticationService extends HttpService {
  private static _instance: AuthenticationService;
  private _accessToken: string | null = null;
  private _refreshToken: string | null = null;
  private _hasAccess = false;
  private _verifyingAccess = false;
  private _emailConfirmed = false;
  private refreshTokenRequest: Promise<boolean> | null = null;
  private _decodedJwt: any = null;

  constructor() {
    super();

    const accessToken = store.get('accessToken');
    if (accessToken && !this.isExpired(accessToken)) {
      this.accessToken = accessToken;
    }

    const refreshToken = store.get('refreshToken');
    if (refreshToken && !this.isExpired(refreshToken))
      this._refreshToken = refreshToken;
  }

  public static get instance() {
    if (!this._instance) {
      this._instance = new AuthenticationService();
    }

    return this._instance;
  }

  public get userId(): string | null {
    return this._decodedJwt?.userId || null;
  }

  private isExpired(token): boolean {
    try {
      const decoded = jwt.decode(token);
      return Date.now() >= (decoded.exp - 60) * 1000;
    } catch {
      return true;
    }
  }

  private set accessToken(token: string | null) {
    if (token) {
      this._decodedJwt = jwt.decode(token);
      this._emailConfirmed = this._decodedJwt.confirmed;
    }

    store.set('accessToken', token);
    this._accessToken = token;
  }

  private set refreshToken(token: string | null) {
    store.set('refreshToken', token);
    this._refreshToken = token;
  }

  public get hasAccess() {
    return this._hasAccess;
  }

  public get verifyingAccess() {
    return this._verifyingAccess;
  }

  public get emailConfirmed() {
    return this._emailConfirmed;
  }

  public isDeviceOwner(deviceId: string) {
    return this._decodedJwt.buttons?.some((buttonId) => buttonId === deviceId);
  }

  public globalScope() {
    if (this._decodedJwt === null) return DisplayScope.HELPER;
    if (this._decodedJwt.scopes?.some((scope) => scope === 'secufy-admin'))
      return DisplayScope.SECUFY_ADMIN;
    if (this._decodedJwt.buttons?.length > 0) return DisplayScope.OWNER;

    return DisplayScope.HELPER;
  }

  public deviceScope(deviceId: string) {
    if (this._decodedJwt === null) return DisplayScope.HELPER;
    if (this._decodedJwt.scopes?.some((scope) => scope === 'secufy-admin'))
      return DisplayScope.SECUFY_ADMIN;
    if (this.isDeviceOwner(deviceId)) return DisplayScope.OWNER;

    return DisplayScope.HELPER;
  }

  public async getAccessToken(): Promise<string> {
    if (this._accessToken && !this.isExpired(this._accessToken))
      return this._accessToken;
    if (this._refreshToken && !this.isExpired(this._refreshToken)) {
      const success = await this.refreshAccessToken();
      if (success) return this._accessToken!;
    }
    return '';
  }

  public async testRefreshToken() {
    this._verifyingAccess = true;
    const success = await this.refreshAccessToken();
    this._verifyingAccess = false;

    return success;
  }

  private refreshAccessToken(): Promise<boolean> {
    if (this.refreshTokenRequest === null) {
      this.refreshTokenRequest = this._refreshAccessToken();
    }

    return this.refreshTokenRequest!;
  }

  /**
   * Renews token
   */
  private async _refreshAccessToken(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const url = `${this.baseUrl}/refreshToken`;

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //@ts-ignore
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this._refreshToken}`
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer' // no-referrer, *client
      });

      const json = await response.json();

      if (response.status === 200) {
        this.accessToken = json.accessToken;
        this.refreshToken = json.refreshToken;
        this._hasAccess = true;
        resolve(true);
      } else {
        resolve(false);
      }

      this.refreshTokenRequest = null;
    });
  }
  /**
   * Check if user has access to cumulocity
   * @returns user credentials vaild
   */
  async login(email: string, password): Promise<void> {
    const url = `${this.baseUrl}/login`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json',
        UseXBasic: true
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({
        email,
        password
      })
    });

    const json = (await response.json()) as LoginResponse;

    if (response.status === 200) {
      this.accessToken = json.accessToken;
      this.refreshToken = json.refreshToken;
      this._hasAccess = true;
    } else {
      throw new Error(json.error);
    }
  }

  /**
   * Signup user
   * @returns user credentials vaild
   */
  public async signup(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    const url = `${this.baseUrl}/signup`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName
      })
    });
    const json = (await response.json()) as LoginResponse;

    if (response.status === 200) {
      this.accessToken = json.accessToken;
      this.refreshToken = json.refreshToken;
      this._hasAccess = true;
    } else {
      throw new Error(json.error);
    }
  }

  public async confirmEmail(token: string): Promise<ConfirmMailState> {
    const url = `${this.baseUrl}/verifyemail`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const json = (await response.json()) as LoginResponse;

    if (response.status === 200) {
      this.accessToken = json.accessToken;
      this.refreshToken = json.refreshToken;
      this._hasAccess = true;
      return ConfirmMailState.SUCCESS;
    } else if (response.status === 401 && json.resendMail) {
      const resendMailSuccess = await this.resendEmail();
      console.log(`Token Expired: Resend Mail Success: ${resendMailSuccess}`);
      return resendMailSuccess
        ? ConfirmMailState.TOKEN_EXPIRED_AND_MAIL_SENT
        : ConfirmMailState.FAILURE;
    } else {
      throw new Error(json.error);
    }
  }

  public async resendEmail(): Promise<boolean> {
    const url = `${this.baseUrl}/signup/resendconfirmation`;
    const token = await this.getAccessToken();
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.status === 204;
  }

  public logout() {
    this.accessToken = null;
    this.refreshToken = null;
  }

  async resetPassword(email: string): Promise<boolean> {
    const url = `${this.baseUrl}/resetPassword`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });

    return response.status === 204;
  }

  async setPassword(password: string, token: string): Promise<boolean> {
    const url = `${this.baseUrl}/setPassword`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password })
    });

    return response.status === 204;
  }
}
