import { Component } from 'react';
import styled from 'styled-components';
import Select from '../Select';
import SubHeader from '../SubHeader';
import { Input } from '../Input';
import { limitUnicodeString } from '../../../../../../utils';
import InfoPopover from '../../../../../../components/InfoPopover';

import FilledRecipient from '../../components/FilledHelper';
import EmptyRecipient from '../../components/EmptyHelper';
import { matchesScope } from '../../../../../../components/DisplayController';
import { DisplayScope } from '../../../../../../services/AuthenticationService';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 25px;
`;

const InputHeader = styled.div`
  display: flex;
  flex-direction: row;
  color: #707070;
  font-size: 15px;
  font-family: Montserrat Bold;
  margin-bottom: 10px;
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const WayOfContactSelectContainer = styled.div`
  // width: 80%;
  margin-top: 0px;
`;

/** Page Content */
class GeneralSettings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  componentDidMount() {
    const { requestRecipients, device } = this.props;

    if (device.device) {
      requestRecipients(device.device.id);
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.device.device &&
      oldProps.device.device?.id !== this.props.device.device?.id
    ) {
      this.props.requestButtonInfo(this.props.device.device?.id);
      this.props.requestRecipients(this.props.device.device.id);
    }
    this.checkBatteryRecipients();
  }

  checkBatteryRecipients() {
    const { recipients, settings, editAndSave } = this.props;
    let i = 0;
    for (i; i < settings.batteryWarningRecipients.length; i++) {
      const battRecipient = settings.batteryWarningRecipients[i];

      const match = recipients.find((r) => r.userId === battRecipient.value);

      if (match) {
        const label = match.firstName + ' ' + match.lastName;
        if (battRecipient.label !== label) {
          battRecipient.label = label;
          editAndSave(
            'batteryWarningRecipients',
            settings.batteryWarningRecipients
          );
        }
      }
    }
  }

  getOptions() {
    const { recipients, owner } = this.props;
    let options: any[] = [];

    //admin
    if (owner && !options.find((o) => o.value === owner.userId)) {
      options.push({
        label: owner.firstName + ' ' + owner.lastName,
        value: owner.id
      });
    }

    //wearer
    const wearer = recipients.find((r) => r.role === 'wearer');
    if (wearer && !options.find((o) => o.value === wearer.userId)) {
      options.push({
        label: wearer.firstName + ' ' + wearer.lastName,
        value: wearer.userId
      });
    }
    //helper
    const helpers = recipients.filter(
      (recipients) => recipients.role === 'helper'
    );
    let i = 0;
    for (i; i < helpers.length; i++) {
      const helper = helpers[i];
      if (
        !options.find((o) => o.value === helper.userId) &&
        helper.approved === true
      ) {
        options.push({
          label: helper.firstName + ' ' + helper.lastName,
          value: helper.userId
        });
      }
    }

    return options;
  }

  checkSelection(batteryWarningRecipients) {
    let formattedOptions: any[] = [];
    formattedOptions = batteryWarningRecipients;

    return formattedOptions;
  }

  render() {
    const {
      settings,
      edit,
      save,
      editAndSave,
      recipients,
      openHelperModal,
      device
    } = this.props;

    //console.log('filled helper', recipients);

    const buildRecipient = (recipients, openHelperModal) => {
      const i = recipients.findIndex((o) => o.role === 'wearer');
      const recipient = recipients[i];
      return recipient ? (
        <FilledRecipient
          key={recipient.id}
          {...recipient}
          edit={() => {
            if (matchesScope(DisplayScope.OWNER, device.device?.id))
              openHelperModal(recipient);
          }}
        ></FilledRecipient>
      ) : (
        <EmptyRecipient
          role="wearer"
          key={i}
          title={'Träger des Sicherheitsbegleiter'}
          add={() => {
            if (matchesScope(DisplayScope.OWNER, device.device?.id))
              openHelperModal({ role: 'wearer' });
          }}
        ></EmptyRecipient>
      );
    };

    return (
      <Div>
        <SubHeader>
          <p>Träger:</p>
          <InfoPopover title="Träger:" id="wearer">
            <PopoverLine>
              Als Administrator tragen Sie hier die Person ein, die den
              Sicherheitsbegleiter tragen wird. Bitte hier NICHT ihre als
              Administrator verwendete E-Mail-Adresse verwenden. Sie brauchen
              gar keine E-Mail Adresse für den Träger eintragen, es sei denn,
              er/sie will selbst die App benutzen oder E-Mail-Benachrichtigungen
              bekommen. Sie müssen mindestens eine Telefonnummer angeben, bitte
              kontrollieren Sie die Korrektheit der Telefonnummer(n) sorgfältig.
              Mit dem Häkchen bei "Empfangen von Alarmen" stellen Sie ein, ober
              der Träger selbst auch Alarmnachrichten erhält. Wählen Sie die im
              Alarmfall gewünschte Kontaktart(en) aus.
            </PopoverLine>
          </InfoPopover>
        </SubHeader>

        <div>{buildRecipient(recipients, openHelperModal)}</div>

        <br />
        {
          // Admin is displayed for safety contact
          // So they know who to contact to change some settings
        }
        <SubHeader>
          <p>Administrator:</p>
          <InfoPopover
            title="Erhalt von Alarmen als Administrator:"
            id="admin-alerts"
          >
            <PopoverLine>
              Wenn sie als Administrator selbst die Alarme erhalten wollen,
              müssen Sie sich ebenfalls bei den Sicherheitskontakten nochmals
              mit einer E-Mail Adresse und ggf. Telefonnummer eintragen.{' '}
            </PopoverLine>
          </InfoPopover>
        </SubHeader>
        <InputHeader>Vorname des Administrator:</InputHeader>
        <Input
          name="txtSearch"
          type={'text'}
          value={device?.device?.owner?.firstName}
          disabled={true}
          onChange={(e) =>
            edit('userName', limitUnicodeString(e.target.value, 40))
          }
          onBlur={() => save()}
        ></Input>
        <InputHeader>Nachname des Administrator:</InputHeader>
        <Input
          name="txtSearch"
          type={'text'}
          value={device?.device?.owner?.lastName}
          disabled={true}
          onChange={(e) =>
            edit('userName', limitUnicodeString(e.target.value, 40))
          }
          onBlur={() => save()}
        ></Input>
        <InputHeader>Email des Administrator</InputHeader>
        <Input
          name="txtSearch"
          type={'text'}
          value={device?.device?.owner?.email}
          onChange={(e) => edit('ownerEmail', e.target.value)}
          onBlur={() => save()}
          disabled={true}
        ></Input>
        <br />
        <br />
        <SubHeader>
          <p>Empfänger für Benachrichtigungen bei geringem Batteriestand:</p>
          <InfoPopover title="Auswahlmöglichkeiten:" id="battery">
            <PopoverLine>
              Der Administrator ist mit seiner Administrator E-mail immer
              auswählbar. Der Träger ist ebenfalls immer auswählbar und erhält,
              sofern ausgewählt, diese Nachrichten gemäß den eingestellten
              Kontaktmöglichkeiten. Sicherheitskontakte stehen erst zur Auswahl,
              wenn sie ihre E-mail-Adressen bestätigt haben und bekommen, sofern
              ausgewählt, diese Benachrichtigungen nur per email und/oder SMS,
              nicht aber per Anruf. Dasselbe gilt auch für den Administrator,
              wenn er/sie sich mit derselben E-mail auch als Sicherheitskontakt
              eingetragen hat.
            </PopoverLine>
          </InfoPopover>
        </SubHeader>

        <WayOfContactSelectContainer>
          <Select
            isDisabled={!matchesScope(DisplayScope.OWNER, device.device?.id)}
            options={this.getOptions()}
            value={this.checkSelection(settings.batteryWarningRecipients)}
            onChange={(selectedOptions) => {
              editAndSave('batteryWarningRecipients', selectedOptions);
            }}
            placeholder="Wähle einen oder mehrere Empfänger aus"
            isMulti
          ></Select>
        </WayOfContactSelectContainer>
      </Div>
    );
  }
}

export default GeneralSettings;
