import { Component } from 'react';
import styled from 'styled-components';
import sizeMe from 'react-sizeme';
import Navbar from './Navbar';

const FullPageDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fafafa;
`;

const Scene = styled.div<any>`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 30px;

  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
`;

class Layout extends Component<any, any> {
  render() {
    const { children } = this.props;

    return (
      <FullPageDiv>
        <Navbar {...this.props}></Navbar>
        <Scene>{children}</Scene>
      </FullPageDiv>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(Layout);
