import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { editBluetoothSettings, saveBluetoothSettings } from '../../../actions';

import BluetoothSettings from '..';

const mapStateToProps = (state, ownProps) => ({
  bluetoothSettings: state.bluetoothSettings
});

const mapDispatchToProps = (dispatch) => ({
  edit: (scope, value) => dispatch(editBluetoothSettings({ scope, value })),
  save: () => dispatch(saveBluetoothSettings())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BluetoothSettings)
);
