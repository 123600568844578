import { handleActions } from 'redux-actions';
import { UPLOAD_AVATAR_SUCCEEDED } from '../../AvatarEditor/types';
import types from '../types';

const initialState = {
  blobUrlsByDeviceId: {}
};

const saveBlobUrl = (state, { payload }) => ({
  ...state,
  blobUrlsByDeviceId: {
    ...state.blobUrlsByDeviceId,
    [payload.deviceId]: payload.imageUrl
  }
});
export default handleActions(
  {
    [types.REQUEST_AVATAR_IMAGE_SUCCEEDED]: saveBlobUrl,
    [UPLOAD_AVATAR_SUCCEEDED]: saveBlobUrl
  },
  initialState
);
