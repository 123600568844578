// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  emergencyServiceEnabled: false,
  addedBracelet: false
};

export default handleActions(
  {
    [types.REQUEST_BUTTON_INFO]: (state, { payload }) => initialState,
    [types.REQUEST_ORDER_SUCCEEDED]: (state, { payload }) => payload
  },
  initialState
);
