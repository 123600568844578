import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DeviceTile from '..';
import { requestBeacons, requestRecipients } from '../../../scenes/Device/components/HelperOverview/actions';
import {
  acknowledgeAlarm,
  requestActivity,
  requestAlarm,
  acceptAlarm,
  requestAlarmEvents,
  alarmStartClose
} from '../actions';
import {
  getAlarm,
  getUsage,
  getAlarmEvents,
  getRecipients,
  getBeacons
} from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  emergencyPlans: state.emergencyPlans,
  usage: getUsage(state, ownProps),
  alarm: getAlarm(state, ownProps),
  alarmEvents: getAlarmEvents(state, ownProps),
  recipients: getRecipients(state, ownProps),
  beacons: getBeacons(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
  requestAlarm: (deviceId) => dispatch(requestAlarm({ deviceId })),
  acknowledgeAlarm: (payload) => dispatch(acknowledgeAlarm(payload)),
  requestActivity: (deviceId) => dispatch(requestActivity({ deviceId })),
  requestAlarmEvents: (deviceId) => dispatch(requestAlarmEvents(deviceId)),
  requestBeacons: (deviceId) => dispatch(requestBeacons(deviceId)),
  acceptAlarm: (payload) => dispatch(acceptAlarm(payload)),
  openAlarmCloseModal: (payload) => dispatch(alarmStartClose(payload)),
  requestRecipients: (deviceId) => dispatch(requestRecipients(deviceId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceTile)
);
