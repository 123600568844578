import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Info from '..';

const mapStateToProps = (state, ownProps) => ({
  device: state.device
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Info)
);
