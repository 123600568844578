import { createAction } from 'redux-actions';
import types from '../types';

export const confirmEmail = createAction(types.CONFIRM_EMAIL);
export const confirmEmailSucceeded = createAction(
  types.CONFIRM_EMAIL_SUCCEEDED
);
export const confirmEmailFailed = createAction(types.CONFIRM_EMAIL_FAILED);
export const confirmEmailTokenExpiredEmailResent = createAction(
  types.CONFIRM_EMAIL_TOKEN_EXPIRED_EMAIL_RESENT
);

export const resendEmail = createAction(types.RESEND_EMAIL);
export const resendEmailSucceeded = createAction(types.RESEND_EMAIL_SUCCEEDED);
export const resendEmailFailed = createAction(types.RESEND_EMAIL_FAILED);
