import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Container = styled.div<any>`
  margin-bottom: 20px;
  height: 60px;
  ${({ width }) => width && `width: ${width}`}
`;

const InputField = styled.div`
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #707070;
`;

const DescriptionField = styled.div`
  height: 20px;
  width: 100%;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  color: #707070;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 20px;
  font-family: 'Montserrat';
  outline: none;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -webkit-text-fill-color: #707070;
  -webkit-opacity: 1;
`;

const Description = styled.p<any>`
  color: #707070;
  font-size: 15px;
  font-family: 'Montserrat';

  ${({ login }) =>
    login &&
    `
    margin-left: auto
    color: #007BFF;
    &:hover {
      cursor: pointer;
    }
`};
`;

/** Page Content */
const TextElement = (props) => {
  let history = useHistory();

  const {
    name,
    disabled,
    title,
    value,
    login,
    onChange,
    type,
    pattern,
    required,
    placeholder,
    width,
    autoComplete,
    onFocus,
    id,
    onKeyDown = () => {}
  } = props;
  return (
    <Container width={width}>
      <InputField>
        <Input
          autoComplete={autoComplete}
          id={id}
          disabled={disabled}
          name={name}
          type={type}
          pattern={pattern}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        ></Input>
      </InputField>
      <DescriptionField>
        <Description>{title || '-'}</Description>
        <Description
          login={login}
          style={{ marginLeft: 'auto' }}
          onClick={() => history.push(`/resetPassword`)}
        >
          {login ? 'Passwort vergessen?' : ''}
        </Description>
      </DescriptionField>
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  TextElement
);
