import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// Font Awesome module imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { history, configureStore } from './store';
// import reportWebVitals from './reportWebVitals';

// css
// Don't import css files after scenes!
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './fonts/fonts.css';
import './index.css';

//scene container
import NotFound from './scenes/NotFound';
import FallbackHelper from './scenes/FallbackHelper/container';
import Login from './scenes/Login/container';
import Signup from './scenes/Signup/container';
import Device from './scenes/Device/container';
import Overview from './scenes/Overview/container';
import ResetPassword from './scenes/ResetPassword/container';
import VerifyEmail from './scenes/VerifyEmail/container';
import VerifyButtonOwnership from './scenes/VerifyButtonOwnership/container';
import ApproveRecipient from './scenes/ApproveRecipient/container';
import { RefetchService } from './services/RefetchService';
import { AlarmService } from './services/AlarmService';
import { refetchedAlarms } from './components/DeviceTile/actions';
import { matchesScope } from './components/DisplayController';
import { DisplayScope } from './services/AuthenticationService';
import InfoOverlay from './components/InfoOverlay';
import { VersionService } from './services/VersionService';
import { version } from '../package.json';

library.add(far, fas, fal, fad, fab);
const BelowMenuToaster = styled<any>(ToastContainer)`
  margin-top: 31px;
`;

const Body = styled.div`
  height: 100%;
  min-height: 100%;
`;

const Container = styled.div`
  body {
    font-family: Montserrat;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    height: 100%;
    min-height: 100%;
  }

  .modal {
    z-index: 10050 !important;
  }

  button {
    font-family: Montserrat;
  }
`;

const store = configureStore();

class Routes extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { outdated: false };
  }

  UNSAFE_componentWillMount() {
    const refetchService = RefetchService.instance;
    if (!matchesScope(DisplayScope.SECUFY_ADMIN)) {
      refetchService.addRefetchFunction({
        id: 'refetchAlarms',
        func: this.refetchAlarms
      });
    } else {
      this.refetchAlarms();
    }
  }

  async refetchAlarms() {
    const alarmService = new AlarmService();
    const alarms = await alarmService.getAllAlarms();

    let alarmsBySource = {};

    for (const alarm of alarms) {
      if (typeof alarm.source?.id !== 'string') continue;

      if (!alarmsBySource[alarm.source.id])
        alarmsBySource[alarm.source.id] = alarm;
      else {
        if (alarm.time > alarmsBySource[alarm.source.id].time)
          alarmsBySource[alarm.source.id] = alarm;
      }
    }

    store.dispatch(refetchedAlarms(alarmsBySource));
  }

  async checkVersionOutdated(): Promise<boolean> {
    const versionService = new VersionService();
    let backendVersion = await versionService.getVersion();

    let frontendVersion = version;
    if (!frontendVersion) {
      console.error('Could not determine frontend version', frontendVersion);
      return false;
    }
    frontendVersion = frontendVersion.split('-')[0];
    console.log('frontend version', frontendVersion);
    console.log('backend version', backendVersion);
    try {
      // ignore outdated PATCH version number (last one)
      for (let i = 0; i < 2; i++) {
        if (
          parseInt(frontendVersion.split('.')[i]) <
          parseInt(backendVersion.split('.')[i])
        )
          return true;
        else if (
          parseInt(frontendVersion.split('.')[i]) >
          parseInt(backendVersion.split('.')[i])
        )
          return false;
      }
    } catch (e) {
      console.error(e);
      console.error(`could not determine app version.`);
      return false;
    }
    return false;
  }

  componentDidMount() {
    this.checkVersionOutdated().then((outdated: boolean) => {
      this.setState({ outdated });
    });
  }

  render() {
    return (
      <Container>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Body>
              {this.state.outdated ? (
                <InfoOverlay
                  content1="Es ist eine neue Version von Secufy Connect verfügbar. Bitte aktualisieren Sie Ihre App."
                  content2={
                    'Mit Bestätigen können Sie ihre aktuell installierte Version weiterhin nutzen. Wir weisen allerdings darauf hin, dass es bei der Verwendung zu Fehlfunktionen kommen kann und empfehlen daher zeitnah eine Aktualisierung durchzuführen.'
                  }
                ></InfoOverlay>
              ) : (
                <></>
              )}
              <BelowMenuToaster
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              <Switch>
                <Route exact path={'/'} component={Overview} />
                <Route
                  exact
                  path={'/device/:deviceId/:tabId'}
                  component={Device}
                />
                <Redirect
                  exact
                  from="/device/:deviceId"
                  to="/device/:deviceId/0"
                />
                {matchesScope(DisplayScope.SECUFY_ADMIN) && (
                  <Route
                    exact
                    path={'/fallbackhelper'}
                    component={FallbackHelper}
                  />
                )}
                <Route exact path={'/login'} component={Login} />
                <Route exact path={'/signup'} component={Signup} />
                <Route
                  exact
                  path={'/resetPassword'}
                  component={ResetPassword}
                />
                <Route exact path={'/verifyemail'} component={VerifyEmail} />
                <Route
                  exact
                  path={'/confirmownershipchange'}
                  component={VerifyButtonOwnership}
                />
                <Route
                  exact
                  path={'/approverecipient'}
                  component={ApproveRecipient}
                />
                <Route component={NotFound} />
              </Switch>
            </Body>
          </ConnectedRouter>
        </Provider>
      </Container>
    );
  }
}

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
