import { Component } from 'react';
import sizeMe from 'react-sizeme';
import Layout from '../../components/Layout';
import WithAuthentication from '../../components/WithAuthentication';
import HelperOverviewContainer from './components/HelperOverview/container';

/** Page Content */
class Device extends Component<any, any> {
  render() {
    return (
      <WithAuthentication>
        <Layout>
          <HelperOverviewContainer></HelperOverviewContainer>
        </Layout>
      </WithAuthentication>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(Device);
