export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

export const ADD_BUTTON = 'ADD_BUTTON';
export const ADD_BUTTON_SUCCEEDED = 'ADD_BUTTON_SUCCEEDED';
export const ADD_BUTTON_FAILED = 'ADD_BUTTON_FAILED';
export const TOGGLE_ADD_BUTTON_MODAL = 'TOGGLE_ADD_BUTTON_MODAL';

export const AUTO_ACCEPT_ALARM = 'AUTO_ACCEPT_ALARM';
export const AUTO_ACCEPT_ALARM_SUCCEEDED = 'AUTO_ACCEPT_ALARM_SUCCEEDED';
export const AUTO_ACCEPT_ALARM_FAILED = 'AUTO_ACCEPT_ALARM_FAILED';

const TYPES = {
  TOGGLE_LIST_VIEW,
  TOGGLE_ADD_BUTTON_MODAL,
  ADD_BUTTON,
  ADD_BUTTON_SUCCEEDED,
  ADD_BUTTON_FAILED,
  AUTO_ACCEPT_ALARM,
  AUTO_ACCEPT_ALARM_SUCCEEDED,
  AUTO_ACCEPT_ALARM_FAILED
};

export default TYPES;
