// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  longpressMode: false,
  gpsTracking: true,
  bleTracking: true,
  trackingMode: true,
  trackingIntervalTiming: 900,
  gpsTimeout: 180,
  gpsCaptures: 10,
  gpsFollows: 3,
  gpsDeviation: 50,
  autoTrackingLeavingHome: false,
  autoTrackingDelay: 60,
  remoteTracking: false,
  numberOfTrackingPositions: 0
};

export default handleActions(
  {
    [types.GET_DEVICE_SUCCEEDED]: (state, { payload }) => {
      return payload.secufy_TrackingSettings
        ? payload.secufy_TrackingSettings
        : initialState;
    },

    [types.EDIT_TRACKING_SETTINGS]: (state, { payload }) => {
      const { scope, value } = payload;

      return {
        ...state,
        [scope]: value
      };
    }
  },
  initialState
);
