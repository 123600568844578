import { RestService } from '../RestService';

export type AddButtonResponse = 'success' | 'failure' | 'emailSent';
export class ButtonService extends RestService {
  async addButton(
    buttonId: string,
    buyerEmail: string
  ): Promise<AddButtonResponse> {
    const url = `${this.baseUrl}/buttons/add`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        buttonId,
        buyerEmail
      })
    });

    if (response.status === 202) {
      // Refresh tokens to update accessible buttons
      //const emailSent = await this.authService.testRefreshToken();
      return 'emailSent';
    }

    if (response.status >= 200 && response.status <= 299) {
      // Refresh tokens to update accessible buttons
      await this.authService.testRefreshToken();
      return 'success';
    }

    return 'failure';
  }

  public async confirmOwnership(token: string): Promise<boolean> {
    const url = `${this.baseUrl}/buttons/confirmownershipchange`;
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status <= 299) {
      return true;
    } else {
      return false;
    }
  }

  public async getButtons(deviceC8yIds: Array<string>): Promise<any> {
    const params = new URLSearchParams();
    params.set('deviceC8yIds', deviceC8yIds.join(','));
    const url = `${this.baseUrl}/buttons/deviceDataHasura?${params.toString()}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        'Content-Type': 'application/json',
        Authorization
      }
    });

    if (response.status <= 299) {
      return await response.json();
    } else {
      console.error('Could not load device data from hasura');
      return [];
    }
  }
}
