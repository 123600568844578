import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Overview from '..';
import {
  confirmEmail,
  resendEmail,
  confirmEmailTokenExpiredEmailResent
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
  verifying: state.verifyEmail.verifying,
  verified: state.verifyEmail.verified,
  tokenExpiredEmailResent: state.verifyEmail.tokenExpiredEmailResent,
  verifyFailed: state.verifyEmail.verifyFailed
});

const mapDispatchToProps = (dispatch) => ({
  confirmEmail: (token) => dispatch(confirmEmail(token)),
  resendEmail: () => dispatch(resendEmail()),
  confirmEmailTokenExpiredEmailResent: () =>
    dispatch(confirmEmailTokenExpiredEmailResent())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
