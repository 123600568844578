import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  recipientStartEditing,
  selectDevice,
  requestConfig,
  selectDevicePage,
  sortDevices,
  selectDevicePageSize
} from '../../../../Device/components/HelperOverview/actions';

import Table from '..';

const mapStateToProps = (state, ownProps) => {
  return {
    recipients: state.recipients,
    deviceList: state.deviceList,
    selectedDeviceId: state.selectedDevice
  };
};

const mapDispatchToProps = (dispatch) => ({
  openHelperModal: (recipient) => dispatch(recipientStartEditing(recipient)),
  requestRecipients: (id) => dispatch(requestConfig(id)),
  selectDevice: (id) => dispatch(selectDevice(id)),
  selectPage: (page) => dispatch(selectDevicePage(page)),
  sortDevices: (metric) => dispatch(sortDevices(metric)),
  selectDevicePageSize: (page) => dispatch(selectDevicePageSize(page))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
