import { put, takeEvery, call } from 'redux-saga/effects';
// import { toast } from 'react-toastify';

import types from '../types';
import { toast } from 'react-toastify';
import {
  confirmOwnershipSucceeded,
  confirmOwnershipFailed,
} from '../actions';
import { ButtonService } from '../../../services/ButtonService';


function* confirmOwnership(action) {
  try {
    const buttonService = new ButtonService();
    
    const success = yield call(buttonService.confirmOwnership.bind(buttonService), action.payload);

    if (success){
      toast.success('Die Verwaltungsrechte des Sicherheitsbegleiter wurden erfolgreich übertragen.', {
        position: 'top-right'
      });
    yield put(confirmOwnershipSucceeded());
    }
      
    else{
      toast.error('Die Verwaltungsrechte des Sicherheitsbegleiter konnten nicht übertragen werden.', {
        position: 'top-right'
      });
    yield put(confirmOwnershipFailed());
    }

  } catch (error) {
    toast.error('Die Verwaltungsrechte des Sicherheitsbegleiter konnten nicht übertragen werden.', {
      position: 'top-right'
    });
    yield put(confirmOwnershipFailed());
  }
}


const sagas = [
  takeEvery(types.CONFIRM_OWNERSHIP, confirmOwnership)
];

export default sagas;
