import { handleActions } from 'redux-actions';
import types from '../types';

interface IAlarmState {
  [deviceId: string]: {
    loading: boolean;
    alarm: [{ id: string; type: string }];
  };
}

const initialState: IAlarmState = {};

export default handleActions(
  {
    [types.REQUEST_ALARM]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: true,
        alarm: null
      }
    }),
    [types.REQUEST_ALARM_SUCCEEDED]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: false,
        alarm: action.payload.alarm
      }
    }),
    [types.REQUEST_ALARM_FAILED]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: false
      }
    }),
    [types.ACKNOWLEDGE_ALARM_SUCCEEDED]: (state, action: any) => {
      return {
        ...state,
        [action.payload.deviceId]: {
          ...state[action.payload.deviceId],
          loading: false,
          alarm: null
        }
      };
    },
    [types.ACCEPT_ALARM]: (state, action: any) => ({
      ...state
    }),
    [types.ACCEPT_ALARM_SUCCEEDED]: (state, action: any) => ({
      ...state
    }),
    [types.ACCEPT_ALARM_FAILED]: (state, action: any) => ({
      ...state
    }),
    [types.REFETCHED_ALARMS]: (state: any, action: any) => {
      let newState = {};

      Object.keys(action.payload).forEach((deviceId) => {
        const alarm = action.payload[deviceId];
        newState = {
          ...newState,
          [deviceId]: {
            loading: false,
            alarm
          }
        };
      });
      return newState;
    },
    [types.ALARM_CLOSE_SUCCEEDED]: (state: any, action: any) => {
      return {
        ...state,
        [action.payload.deviceId]: { loading: false, alarm: [] }
      };
    }
  },

  initialState
);
