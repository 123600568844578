import { useState, useEffect } from 'react';
import styled from 'styled-components';
import MapGL, {
  Marker,
  NavigationControl,
  FullscreenControl
} from 'react-map-gl';
import { toast } from 'react-toastify';
import Pin from './components/Pin';
import { getMarkerColor } from '../../../../services/DeviceUtils';
import WebMercatorViewport from 'viewport-mercator-project';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  border-style: solid;
  border-width: 1px;
  border-color: #bdc3c7;
  border-radius: 5px;
`;

const MapController = styled.div`
  width: 30px;
`;

const getDeviceDisplayName = (device) => {
  try {
    const config = JSON.parse(device.c8y_Configuration.config);
    return config.userName;
  } catch {
    return null;
  }
};

const getDevicesWithPosition = (devices: any[] = []) => {
  return devices.filter(
    (device) =>
      typeof device !== 'undefined' &&
      typeof device.c8y_Position !== 'undefined' &&
      typeof device.c8y_Position.lat === 'number' &&
      typeof device.c8y_Position.lng === 'number'
  );
};

const limitNumberWithinRange = (num: number, min: number, max: number) => {
  return Math.min(Math.max(num, min), max);
};

const limitLat = (lat) => limitNumberWithinRange(lat, -90, 90);
const limitLng = (lng) => limitNumberWithinRange(lng, -180, 180);

const validateBounds = (
  bounds: number[][]
): [[number, number], [number, number]] => {
  let lats = bounds.map((entry) => entry[0]);
  let lngs = bounds.map((entry) => entry[1]);

  const minLat = limitLat(Math.min(...lats));
  const maxLat = limitLat(Math.max(...lats));
  const minLng = limitLng(Math.min(...lngs));
  const maxLng = limitLng(Math.max(...lngs));

  return [
    [maxLng, maxLat],
    [minLng, minLat]
  ];
};

const setBounds = (
  viewPort: IViewPort,
  setViewPort,
  devices,
  selectedDeviceId
) => {
  const devicesWithPosition = getDevicesWithPosition(devices);
  if (devicesWithPosition.some((device) => selectedDeviceId === device.id)) {
    // Highlight one selected marker
    const device = devicesWithPosition.find(
      (device) => selectedDeviceId === device.id
    );

    try {
      setViewPort({
        ...viewPort,
        latitude: limitLat(device.c8y_Position.lat),
        longitude: limitLng(device.c8y_Position.lng),
        zoom: 15
      });
    } catch (e) {
      console.log(e);
    }
  } else if (
    selectedDeviceId &&
    !devicesWithPosition.some((device) => selectedDeviceId === device.id)
  ) {
    // Show warning if selected device has no position
    toast.warn(
      'The position of the selected device cannot be determined at the moment',
      {
        position: 'top-right',
        toastId: 300
      }
    );
  } else {
    // Set bounds to include all markers
    const bounds = devicesWithPosition.map(({ c8y_Position }) => [
      c8y_Position.lng,
      c8y_Position.lat
    ]);

    if (bounds.length > 1) {
      const { height, width } = viewPort;

      if (height === 0 || width === 0) return;

      const newViewport = new WebMercatorViewport({
        width,
        height
      });

      const bound = newViewport.fitBounds(validateBounds(bounds), {
        padding: 50,
        offset: [0, 0]
      });

      setViewPort({
        ...viewPort,
        latitude: bound.latitude,
        longitude: bound.longitude,
        zoom: bound.zoom,
        height: bound.height,
        width: bound.width
      });
    } else {
      try {
        setViewPort({
          ...viewPort,
          latitude: devicesWithPosition[0].c8y_Position.lat,
          longitude: devicesWithPosition[0].c8y_Position.lng,
          zoom: 12
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};

const renderMarker = (device, selectDevice) => {
  const name = getDeviceDisplayName(device);
  const color = getMarkerColor(device);

  return (
    <Marker
      key={`marker-${device.id}`}
      longitude={limitLng(device.c8y_Position.lng)}
      latitude={limitLat(device.c8y_Position.lat)}
    >
      <Pin
        name={name}
        color={color}
        onClick={() => {
          selectDevice(device.id);
        }}
      ></Pin>
    </Marker>
  );
};

const renderMarkers = (devices, selectDevice) => {
  return getDevicesWithPosition(devices).map((device) =>
    renderMarker(device, selectDevice)
  );
};

interface IViewPort {
  latitude: number;
  longitude: number;
  zoom: number;
  height: number;
  width: number;
}

const Map = ({ devices, selectDevice, selectedDeviceId }) => {
  const [viewPort, setViewPort] = useState<IViewPort>({
    latitude: 50,
    longitude: 12.5,
    zoom: 1.25,
    height: 0,
    width: 0
  });

  useEffect(() => {
    setBounds(viewPort, setViewPort, devices, selectedDeviceId);
  }, [selectedDeviceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <MapGL
        {...viewPort}
        mapStyle="mapbox://styles/mapbox/streets-v10"
        width="100%"
        height="100%"
        onViewportChange={(newViewPort) => {
          if (viewPort.width === 0 && viewPort.height === 0) {
            setBounds(newViewPort, setViewPort, devices, selectedDeviceId);
          } else {
            setViewPort(newViewPort);
          }
        }}
      >
        {renderMarkers(devices, selectDevice)}
        <MapController>
          <FullscreenControl />
        </MapController>
        <MapController>
          <NavigationControl />
        </MapController>
      </MapGL>
    </Wrapper>
  );
};

export default Map;
