export const OPEN_AVATAR_EDITOR = 'OPEN_AVATAR_EDITOR';
export const CLOSE_AVATAR_EDITOR = 'CLOSE_AVATAR_EDITOR';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCEEDED = 'UPLOAD_AVATAR_SUCCEEDED';
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';
export const DELETE_AVATAR = 'DELETE_AVATAR';
export const DELETE_AVATAR_SUCCEEDED = 'DELETE_AVATAR_SUCCEEDED';
export const DELETE_AVATAR_FAILED = 'DELETE_AVATAR_FAILED';

const TYPES = {
  OPEN_AVATAR_EDITOR,
  CLOSE_AVATAR_EDITOR,
  UPLOAD_AVATAR,
  UPLOAD_AVATAR_SUCCEEDED,
  UPLOAD_AVATAR_FAILED,
  DELETE_AVATAR,
  DELETE_AVATAR_SUCCEEDED,
  DELETE_AVATAR_FAILED
};

export default TYPES;
