export const REQUEST_AVATAR_IMAGE = 'REQUEST_AVATAR_IMAGE';
export const REQUEST_AVATAR_IMAGE_SUCCEEDED = 'REQUEST_AVATAR_IMAGE_SUCCEEDED';
export const REQUEST_AVATAR_IMAGE_FAILED = 'REQUEST_AVATAR_IMAGE_FAILED';

const TYPES = {
  REQUEST_AVATAR_IMAGE,
  REQUEST_AVATAR_IMAGE_SUCCEEDED,
  REQUEST_AVATAR_IMAGE_FAILED
};

export default TYPES;
