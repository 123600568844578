import { all, call, takeEvery } from 'redux-saga/effects';
import helperOverviewSagas from '../scenes/Device/components/HelperOverview/sagas';
import avatarUploadSagas from '../components/AvatarEditor/sagas';
import avatarImageSagas from '../components/Avatar/sagas';
import deviceTile from '../components/DeviceTile/sagas';
import resetPwSagas from '../scenes/ResetPassword/components/ResetPanel/sagas';
import overviewSagas from '../scenes/Overview/sagas';
import verifyEmailSagas from '../scenes/VerifyEmail/sagas';
import verifyButtonOwnershipSagas from '../scenes/VerifyButtonOwnership/sagas';
import approveRecipient from '../scenes/ApproveRecipient/sagas';
import { AuthenticationService } from '../services/AuthenticationService';
import PushNotificationService from '../services/PushNotificationService';
import types from '../types';
import getEnvVar from '../services/getEnvVar';

function* initApp() {
  try {
    const authService = new AuthenticationService();

    if (getEnvVar('REACT_APP_PLATFORM') === 'web') return;

    const pushNotificationService = new PushNotificationService();
    const deviceToken = yield pushNotificationService.init();

    console.log('App Token:', deviceToken);
    yield call(
      pushNotificationService.storeToken.bind(pushNotificationService),
      authService.userId!,
      deviceToken
    );
  } catch (error: any) {
    console.log(error);
  }
}

const sagas = [takeEvery(types.INITIALIZE_APP, initApp)];

function* mySaga() {
  yield all([
    ...sagas,
    ...helperOverviewSagas,
    ...resetPwSagas,
    ...avatarUploadSagas,
    ...avatarImageSagas,
    ...deviceTile,
    ...overviewSagas,
    ...verifyEmailSagas,
    ...verifyButtonOwnershipSagas,
    ...approveRecipient
  ]);
}

export default mySaga;
