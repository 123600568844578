import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '../Avatar/container';
import styled from 'styled-components';
import ActivityChart from './components/ActivityChart/container';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery20Icon from '@material-ui/icons/Battery20';
import SettingsInputAntennaOutlinedIcon from '@material-ui/icons/SettingsInputAntennaOutlined';
import Map from '../../scenes/Overview/components/Map/index';
import { useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import InfoPopover from '../InfoPopover';
import { DisplayScope } from '../../services/AuthenticationService';
import { matchesScope } from '../DisplayController';

const ButtonTile = styled(Card)`
  width: 300px;
  height: auto;
  margin: 20px;

  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
`;

const AntennaGreen = styled(SettingsInputAntennaOutlinedIcon)`
  color: #0fe838;
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const AntennaRed = styled(SettingsInputAntennaOutlinedIcon)`
  color: red;
`;

const BatteryGreen = styled(BatteryFullIcon)`
  transform: rotate(90deg);
  color: #0fe838;
  stroke: black;
  stroke-width: 0.5;
`;

const BatteryYellow = styled(Battery50Icon)`
  transform: rotate(90deg);
  color: #e1d81d;
  stroke: black;
  stroke-width: 0.5;
`;

const BatteryRed = styled(Battery20Icon)`
  transform: rotate(90deg);
  color: red;
  stroke: black;
  stroke-width: 0.5;
`;

const UserNameField = styled(Typography)<any>`
  font-family: Montserrat Bold !important;
  font-size: 18px !important;
  padding-bottom: 10px;

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}
`;

const StatusSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PopOver = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const Spacer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const HeaderFieldLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
`;

const HeaderFieldRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
`;

const StatusField = styled.div`
  position: relative;
  font-family: Montserrat Bold !important;
  font-size: 18px !important;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 120px;
`;

const StatusTitleField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StatusTitleLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  padding-bottom: 10px;
  gap: 10px;
`;

const StatusTitleLeftInfo = styled.div`
  padding-top: 10px;
  width: 25%;
`;

const StatusTitleRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding-bottom: 10px;
`;

/*
const WearingTimeDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
`;
*/

const FooterSection = styled.div`
  border-top: 1px solid grey;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -10px;
`;

const FooterStatusFieldBattery = styled.div`
  display: flex;
  flex-direction: row;

  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;

  width: 30%;
  height: 30px;
  align-items: flex-start;
`;

const FooterStatusFieldContact = styled.div`
  font-family: Montserrat Bold !important;
  color: #707070;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 70%;
  height: 30px;
  align-items: flex-end;
`;

const ContactLeft = styled.div`
  flex: 1 0;
  text-align: right;
  width: 80%;
`;

const ContactRight = styled.div`
  flex: 1 0;
  text-align: center;
  width: 20%;
`;

const MapWrapper = styled.div`
  height: 300px;
  width: 300px;
  @media (max-width: 300px) {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0px;
  }
`;

const ChecklistWrapper = styled.div`
  font-family: Montserrat Bold !important;
  white-space: pre-line;
  color: #707070;
  height: auto;
  width: 300px;
  background-color: transparent;
  padding-top: 5px;
  padding-left: 15px;
  margin-bottom: 25px;
`;

const ClearAlarmButton = styled<any>(Button)`
  border: 0px;
  border-radius: 0px;
  background-color: #fcefe6;
  color: #ea6000;

  margin-top: 20px;
  margin-left: auto;
  width: 150px;

  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
    color: #ffffff;
  }
`;

const DetailViewButton = styled<any>(Button)`
  border: 0px;
  border-radius: 0px;
  border-bottom-left-radius: 20px;
  background-color: #ea6000;
  border-color: #ea6000;
  width: 135px;
  float: right;
`;

const InfoHeading = styled.div`
  font-size: 18px !important;
  color: #707070;
`;

const InfoText = styled.div`
  margin-top: 5px;
  font-size: 14px !important;
  color: #707070;
`;

const DeviceTile = (props) => {
  const {
    device,
    showSettings = true,
    showId = true,
    showPopover = true,
    alarm,
    // requestAlarm,
    openAlarmCloseModal,
    requestAlarmEvents,
    requestBeacons,
    acceptAlarm,
    //usage,
    alarmEvents,
    requestRecipients,
    recipients,
    beacons
  } = props;

  // useEffect(() => {
  //   requestAlarm(device.id);
  // }, [device, requestAlarm]);

  useEffect(() => {
    requestAlarmEvents(device.id);
    if (!beacons) {
      requestBeacons(device.id);
    }
  }, [device, requestAlarmEvents, requestBeacons, beacons]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const previousDevice = usePrevious(device) as any;

  useEffect(() => {
    if (previousDevice?.id !== device?.id) {
      requestRecipients(device.id);
    }
  }, [device, requestRecipients, previousDevice]);

  const getLastSeen = (timestamp) => {
    const now = moment();
    const lastSeen = moment(timestamp);

    if (now.format('YYYY-MM-DD') === lastSeen.format('YYYY-MM-DD')) {
      return lastSeen.format('HH:mm');
    } else {
      return lastSeen.format('DD.MM. HH:mm');
    }
  };

  const hasWearer = (recipients) => {
    return recipients.some((recipient: any) => recipient.role === 'wearer');
  };

  const getDeviceDisplayName = (recipients) => {
    let wearer = recipients.find(
      (recipient: any) => recipient.role === 'wearer'
    );

    if (wearer) {
      return (
        (wearer.firstName ? wearer.firstName : ' ') +
        ' ' +
        (wearer.lastName ? wearer.lastName : ' ')
      );
    }

    return 'Bitte Träger benennen';
  };

  function getBatteryState(value) {
    let battery = value;

    if (parseInt(value, 10) >= 50) {
      battery = <BatteryGreen />;
    }

    if (parseInt(value, 10) < 50) {
      battery = <BatteryYellow />;
    }

    if (parseInt(value, 10) < 10) {
      battery = <BatteryRed />;
    }

    return battery;
  }

  function getAntennaState(timestamp) {
    let antenna: JSX.Element | null = null;
    const now = moment();
    const lastSeen = moment(timestamp);
    const diff = moment.duration(now.diff(lastSeen));

    let days = Math.floor(diff.asDays());

    if (days > 1) {
      antenna = <AntennaRed />;
    } else {
      antenna = <AntennaGreen />;
    }
    return antenna;
  }

  function getStatus() {
    if (!alarm) return;
    if (
      alarm.type === 'secufy_SosHomeAlarm' ||
      alarm.type === 'secufy_SosOutsideAlarm'
    ) {
      if (isAlarmAccepted()) {
        return {
          background:
            'linear-gradient(0deg, #ffffff, #ffffff, #ffffff, #ffffff)',
          backgroundSize: '300% 300%',
          animation: 'gradient 6s ease infinite'
        };
      } else {
        return {
          background:
            'linear-gradient(0deg, #ffffff, #FFCDD2, #EF9A9A, #EF5350)',
          backgroundSize: '300% 300%',
          animation: 'gradient 6s ease infinite'
        };
      }
    }
    if (
      alarm.type === 'secufy_SosHomeWarning' ||
      alarm.type === 'secufy_SosOutsideWarning'
    ) {
      if (isAlarmAccepted()) {
        return {
          background:
            'linear-gradient(0deg, #ffffff, #ffffff, #ffffff, #ffffff)',
          backgroundSize: '300% 300%',
          animation: 'gradient 6s ease infinite'
        };
      } else {
        return {
          background:
            'linear-gradient(0deg, #ffffff, #FFF9C4, #FFF59D, #FDD835)',
          backgroundSize: '300% 300%',
          animation: 'gradient 6s ease infinite'
        };
      }
    }

    return;
  }

  function validPosition() {
    return (
      typeof device.c8y_Position?.lat === 'number' &&
      typeof device.c8y_Position?.lng === 'number'
    );
  }

  function getMap() {
    if (!alarm) return;
    if (
      (alarm.type === 'secufy_SosOutsideAlarm' ||
        alarm.type === 'secufy_SosOutsideWarning') &&
      validPosition()
    ) {
      return (
        <MapWrapper>
          <Map
            devices={[device]}
            selectDevice={() => void 0}
            selectedDeviceId={device.id}
          />
        </MapWrapper>
      );
    } else {
      return;
    }
  }

  function getHomezoneSender() {
    if (device.c8y_Position?.beacon) {
      if (
        (device.c8y_Position.beacon?.location === null ||
          device.c8y_Position.beacon?.location?.length === 0) &&
        (device.c8y_Position.beacon?.address === null ||
          device.c8y_Position.beacon?.address?.length === 0) &&
        (device.c8y_Position.beacon?.postalCode === null ||
          device.c8y_Position.beacon?.postalCode?.length === 0) &&
        (device.c8y_Position.beacon?.town === null ||
          device.c8y_Position.beacon?.town?.length === 0)
      ) {
        return (
          <ChecklistWrapper>
            <InfoHeading>Homezone-Sender</InfoHeading>
            <InfoText>
              Major: {device.c8y_Position.beacon?.major} <br />
              Minor: {device.c8y_Position.beacon?.minor} <br />
            </InfoText>
          </ChecklistWrapper>
        );
      }

      return (
        <ChecklistWrapper>
          <InfoHeading>Homezone-Sender</InfoHeading>
          <InfoText>
            {device.c8y_Position.beacon?.location} <br />
            {device.c8y_Position.beacon?.address} <br />
            {device.c8y_Position.beacon?.postalCode} <br />
            {device.c8y_Position.beacon?.town}
          </InfoText>
        </ChecklistWrapper>
      );
    }
    return ' -';
  }

  function isAlarmAccepted() {
    if (
      alarmEvents.some(
        (e) =>
          e.secufy_AlarmAccepted?.alarmId === alarm.id &&
          e.secufy_AlarmAccepted?.userId
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getStatusText() {
    if (!alarm) return <text style={{ color: '#0fe838' }}>OK</text>;

    if (
      alarm.type === 'secufy_SosHomeWarning' ||
      alarm.type === 'secufy_SosOutsideWarning'
    ) {
      if (isAlarmAccepted()) {
        return (
          <text style={{ color: 'orange', textAlign: 'center' }}>
            Warnung angenommen
          </text>
        );
      } else {
        return <text style={{ color: 'orange' }}>WARNUNG</text>;
      }
    } else if (
      alarm.type === 'secufy_SosHomeAlarm' ||
      alarm.type === 'secufy_SosOutsideAlarm'
    ) {
      if (isAlarmAccepted()) {
        return (
          <text style={{ color: 'red', textAlign: 'center' }}>
            Alarm angenommen
          </text>
        );
      } else {
        return <text style={{ color: 'red' }}>ALARM</text>;
      }
    } else return <text style={{ color: '#0fe838' }}>OK</text>;
  }

  function getBeaconInfo() {
    if (!alarm) return;

    if (alarm.type === 'secufy_SosHomeWarning') {
      return getHomezoneSender();
    }

    if (alarm.type === 'secufy_SosHomeAlarm') {
      return getHomezoneSender();
    }

    return;
  }

  function getStatusTitleLeftInfo() {
    if (
      (alarm.type === 'secufy_SosHomeAlarm' ||
        alarm.type === 'secufy_SosOutsideAlarm') &&
      isAlarmAccepted() === true
    ) {
      return (
        showPopover && (
          <InfoPopover id={'alarmAccepted'} title={'Alarm angenommen'}>
            {getStatusTitleInfoText('alarm')}
          </InfoPopover>
        )
      );
    }

    if (
      (alarm.type === 'secufy_SosHomeWarning' ||
        alarm.type === 'secufy_SosOutsideWarning') &&
      isAlarmAccepted() === true
    ) {
      return (
        showPopover && (
          <InfoPopover id={'warningAccepted'} title={'Warnung angenommen'}>
            {getStatusTitleInfoText('warning')}
          </InfoPopover>
        )
      );
    }
  }

  function getStatusTitleInfoText(type: string) {
    const role = alarmEvents[0].secufy_AlarmAccepted?.role || '';
    var pre = 'von';
    var firstName = alarmEvents[0].secufy_AlarmAccepted?.firstName || '-';
    var lastName = alarmEvents[0].secufy_AlarmAccepted.lastName || '-';
    const phoneNumber = alarmEvents[0].secufy_AlarmAccepted?.phoneNumber || '-';
    const phoneNumber2 =
      alarmEvents[0].secufy_AlarmAccepted?.phoneNumber2 || '-';
    var email = alarmEvents[0].secufy_AlarmAccepted?.email || '-';

    if (role !== 'helper' && role !== 'wearer') {
      firstName = 'Secufy - Emergency';
      lastName = 'Service';
      email = 'info@secufy-sos.com';
      pre = 'vom';
    }

    if (type === 'alarm') {
      return (
        <PopoverLine>
          Der Alarm wurde {pre} {firstName} {lastName} angenommen.
          <br />
          <br />
          Als Kontaktmöglichkeiten sind hinterlegt:
          <br />
          Tel1: {phoneNumber}
          <br />
          Tel2: {phoneNumber2}
          <br />
          Email: {email}
        </PopoverLine>
      );
    }

    if (type === 'warning') {
      return (
        <PopoverLine>
          Die Warnung wurde von {firstName} {lastName} angenommen.
          <br />
          <br />
          Als Kontaktmöglichkeiten sind hinterlegt:
          <br />
          Tel1: {phoneNumber}
          <br />
          Tel2: {phoneNumber2}
          <br />
          Email: {email}
        </PopoverLine>
      );
    }
  }

  function getEmergencyPlan() {
    if (!alarm) return;
    if (!device.secufy_EmergencyPlans) return;

    if (
      alarm.type === 'secufy_SosHomeWarning' &&
      device.secufy_EmergencyPlans.warningPlan != null &&
      device.secufy_EmergencyPlans.warningPlan !== ''
    ) {
      return (
        <ChecklistWrapper>
          <InfoHeading>Warnungs Checkliste</InfoHeading>
          <InfoText>{device.secufy_EmergencyPlans.warningPlan}</InfoText>
        </ChecklistWrapper>
      );
    }

    if (
      alarm.type === 'secufy_SosOutsideWarning' &&
      device.secufy_EmergencyPlans.warningPlan != null &&
      device.secufy_EmergencyPlans.warningPlan !== ''
    ) {
      return (
        <ChecklistWrapper>
          <InfoHeading>Warnungs Checkliste</InfoHeading>
          <InfoText>{device.secufy_EmergencyPlans.warningPlan}</InfoText>
        </ChecklistWrapper>
      );
    }

    if (
      alarm.type === 'secufy_SosHomeAlarm' &&
      device.secufy_EmergencyPlans.alertPlanHome != null &&
      device.secufy_EmergencyPlans.alertPlanHome !== ''
    ) {
      return (
        <ChecklistWrapper>
          <InfoHeading>Alarm Checkliste - Zuhause</InfoHeading>
          <InfoText>{device.secufy_EmergencyPlans.alertPlanHome}</InfoText>
        </ChecklistWrapper>
      );
    }
    if (
      alarm.type === 'secufy_SosOutsideAlarm' &&
      device.secufy_EmergencyPlans.alertPlanOutside != null &&
      device.secufy_EmergencyPlans.alertPlanOutside !== ''
    ) {
      return (
        <ChecklistWrapper>
          <InfoHeading>Alarm Checkliste - außer Haus</InfoHeading>
          <InfoText>{device.secufy_EmergencyPlans.alertPlanOutside}</InfoText>
        </ChecklistWrapper>
      );
    }
    return;
  }

  function acceptAlarmFunction() {
    acceptAlarm({ deviceId: device.id, alarmId: alarm.id });
  }

  function closeAlarmFunction() {
    openAlarmCloseModal({
      alarmId: alarm.id,
      severity: alarm.severity,
      deviceId: device.id,
      trigger: alarmEvents[0].secufy_AlarmAccepted.trigger
    });
  }

  function getAcceptButtonText() {
    if (alarm.severity === 'WARNING') {
      return 'Warnung annehmen';
    }
    return 'Alarm annehmen';
  }

  function getCloseButtonText() {
    if (alarm.severity === 'WARNING') {
      return 'Warnung beenden';
    }
    return 'Alarm beenden';
  }

  function getCalibFactor() {
    if (!device.secufy_ButtonSettings) return 1;
    if (
      !device.secufy_ButtonSettings.wearingLocation &&
      device.secufy_ButtonSettings.stepsCaliFactorHip
    )
      return device.secufy_ButtonSettings.stepsCaliFactorHip / 100;
    if (
      device.secufy_ButtonSettings.wearingLocation &&
      device.secufy_ButtonSettings.stepsCaliFactorArm
    )
      return device.secufy_ButtonSettings.stepsCaliFactorArm / 100;
    return 1;
  }

  function getButton() {
    if (
      !alarmEvents.some(
        (e) =>
          e.secufy_AlarmAccepted?.alarmId === alarm.id &&
          e.secufy_AlarmAccepted?.userId
      )
    ) {
      var ct = moment(alarm.creationTime);
      var diff = moment.now() - ct.valueOf();
      var diffInHours = (diff / (1000 * 60 * 60)) % 24;

      if (diffInHours > 12) {
        return (
          <ClearAlarmButton onClick={closeAlarmFunction}>
            {getCloseButtonText()}
          </ClearAlarmButton>
        );
      }

      return (
        <ClearAlarmButton onClick={acceptAlarmFunction}>
          {getAcceptButtonText()}
        </ClearAlarmButton>
      );
    }

    return null;
  }

  function getClearAlarm() {
    if (!alarm) return;
    if (
      alarm.type === 'secufy_SosHomeWarning' ||
      alarm.type === 'secufy_SosOutsideWarning' ||
      alarm.type === 'secufy_SosHomeAlarm' ||
      alarm.type === 'secufy_SosOutsideAlarm'
    ) {
      return getButton();
    }

    return null;
  }

  /*
  function leadingZero(n: number) {
    return n < 10 ? '0' + n : n;
  }
*/
  /*
  function getUsage() {
    return (
      Math.floor(usage / 3600) +
      ':' +
      leadingZero(Math.floor((usage % 3600) / 60))
    );
  }
*/

  return (
    <ButtonTile style={getStatus()}>
      {showSettings && (
        <DetailViewButton
          onClick={() => {
            props.history.push(`/device/${device.id}/0`);
          }}
        >
          Detailansicht
        </DetailViewButton>
      )}
      <CardContent>
        <HeaderSection>
          <HeaderFieldLeft>
            <Avatar deviceId={device.id} />
          </HeaderFieldLeft>

          <HeaderFieldRight>{getClearAlarm()}</HeaderFieldRight>
        </HeaderSection>

        <UserNameField
          clickable={!hasWearer(recipients)}
          onClick={() => {
            if (hasWearer(recipients)) return;
            props.history.push(`/device/${device.id}/1`);
          }}
        >
          {matchesScope(DisplayScope.SECUFY_ADMIN, device.id) && showId
            ? device.name.slice(device.name.length - 6)
            : getDeviceDisplayName(recipients)}
        </UserNameField>
        <br />
        <StatusTitleField>
          <StatusTitleLeft>
            <StatusTitleLeftInfo>
              {getStatusTitleLeftInfo()}
            </StatusTitleLeftInfo>
            Status <br />
            aktuell
          </StatusTitleLeft>
          <StatusTitleRight>
            Schritte <br />
            Vortag
          </StatusTitleRight>
        </StatusTitleField>

        <StatusSection>
          <StatusField>{getStatusText()}</StatusField>
          <StatusField>
            {showPopover && (
              <PopOver>
                <InfoPopover id={'steps'} title={'Anzahl Schritte'}>
                  {'Schritte des gestrigen Tages.'}
                  <br />
                  <br />
                  {
                    'Senioren über 65 Jahren sollten täglich ungefähr 7000 Schritte machen.'
                  }
                  <a
                    href="https://www.alzheimer-forschung.de/aktuelles/meldung/alzheimer-praevention-experten-empfehlen-10000-schritte-pro-tag/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <br />
                    <br />
                    Link zur Quelle
                  </a>
                  <br />
                  <br />
                  {
                    'Bei Benutzung von Gehhilfen kann es zu einer geringeren Schritterkennung kommen.'
                  }
                </InfoPopover>
              </PopOver>
            )}

            <ActivityChart deviceId={device.id} factor={getCalibFactor()} />
          </StatusField>
        </StatusSection>
        {/*
        <StatusTitleField>
          <WearingTimeDiv>Tragedauer {getUsage()}</WearingTimeDiv>
        </StatusTitleField>
        */}
        <Spacer></Spacer>

        <FooterSection>
          <FooterStatusFieldBattery>
            {getBatteryState(
              device.c8y_Battery
                ? `${device.c8y_Battery.level.value}${device.c8y_Battery.level.unit}`
                : '-'
            )}

            {device.c8y_Battery
              ? `${device.c8y_Battery.level.value}${device.c8y_Battery.level.unit}`
              : '-'}
          </FooterStatusFieldBattery>

          <FooterStatusFieldContact>
            <ContactLeft>
              {device.c8y_lastSeen
                ? getAntennaState(device.c8y_lastSeen.lastMessage)
                : '-'}
              {'  '}
              {device.c8y_lastSeen
                ? getLastSeen(device.c8y_lastSeen.lastMessage)
                : '-'}
            </ContactLeft>
            <ContactRight>
              {showPopover && (
                <InfoPopover id={'lastContact'} title={'Letzte Verbindung'}>
                  {
                    'Der Sicherheitsbegleiter ist durchgehend aktiv. Solange allerdings kein Notfall vorliegt, erfolgt einmal täglich ein Verbindungsaufbau.'
                  }
                  <br />
                  <br />
                  {
                    'Wenn die letzte Verbindung nicht am heutigen Tag war, wird zusätzlich das Datum angezeigt.'
                  }
                </InfoPopover>
              )}
            </ContactRight>
          </FooterStatusFieldContact>
        </FooterSection>
      </CardContent>

      {getMap()}
      {getBeaconInfo()}
      {getEmergencyPlan()}
    </ButtonTile>
  );
};

export default DeviceTile;
