// import { createSelector } from 'reselect';

export const getUsage = (state, ownProps) => {
  try {
    return state.activity[ownProps.device.id].usage;
  } catch {
    return 0;
  }
};

export const getSteps = (state, ownProps) => {
  try {
    return state.activity[ownProps.deviceId].steps;
  } catch {
    return 0;
  }
};

export const getAlarm = (state, ownProps) => {
  try {
    return state.alarm[ownProps.device.id].alarm;
  } catch {
    return 0;
  }
};

export const getBeacons = (state, ownProps) => {
  try {
    return state.beacons[ownProps.device.id];
  } catch {
    return 0;
  }
};

export const getAlarmEvents = (state, ownProps) => {
  try {
    return state.alarmEvents[ownProps.device.id].events;
  } catch {
    return [];
  }
};

export const getRecipients = (state, ownProps) => {
  try {
    if (!ownProps?.device) return [];

    const recipients = state.recipients?.[ownProps.device.id];
    return recipients || [];
  } catch (e) {
    return [];
  }
};
