import { Component } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import TextElement from '../../../../../../components/TextElement';
import DropdownElement from '../../../../../../components/DropdownElement';
import Select from '../Select';
import { limitUnicodeString } from '../../../../../../utils';
import Checkbox from '../Checkbox';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import de from 'react-phone-number-input/locale/de.json';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';

const StyledModal = styled(Modal)`
  // width: 50%;
  // min-width: 600px;
  // max-width: 800px;
  margin-top: 50px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 20px;
`;

const WayOfContactSelectContainer = styled.div`
  // width: 80%;
  margin-top: 30px;
`;

const Title = styled.p`
  color: #707070;
  font-size: 20px;
  font-family: 'Montserrat';
  margin-bottom: 0px;
`;

const Text = styled.p<any>`
  color: #707070;
  font-size: 15px;
  font-family: 'Montserrat';
`;

const ErrorText = styled.p`
  color: red;
  font-size: 15px;
  font-family: 'Montserrat';
`;

const SaveButton = styled(Button)`
  background-color: #ea6000;
  border-color: #ea6000;
  width: 100px;

  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

const PhoneInputWrapper = styled.div`
  margin-top: 40px;
`;

const DeleteButton = styled(Button)``;

const CancelButton = styled(Button)``;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 25px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
`;

const DeleteConfirmationButtonNo = styled(Button)`
  background-color: #ea6000;
  border-color: #ea6000;
  width: 100px;

  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const DeleteConfirmationButtonYes = styled(Button)`
  width: 100px;
`;

/** Page Content */
class EditHelperModal extends Component<any, any> {
  prevEmail = '';
  prevPhone = '';
  prevPhone2 = '';
  constructor(props) {
    super(props);
    this.state = {
      error: ' '
    };
  }

  componentDidMount() {
    this.getInitValues();
  }

  componentDidUpdate() {
    this.checkPhoneNumber();
    this.checkPhoneNumber2();
    this.checkEmail();
  }

  getOptions() {
    return [
      { label: 'SMS Tel 1', value: 'sms' },
      { label: 'Anruf Tel 1', value: 'phoneCall' },
      { label: 'SMS Tel 2', value: 'sms2' },
      { label: 'Anruf Tel 2', value: 'phoneCall2' },
      { label: 'E-Mail', value: 'email' }
    ];
  }

  toSelectionFormat(
    smsEnabled,
    emailEnabled,
    voicemailEnabled,
    smsEnabled2,
    voicemailEnabled2
  ) {
    const optionTemplate = {
      sms: { label: 'SMS tel1', value: 'sms' },
      sms2: { label: 'SMS tel2', value: 'sms2' },
      phoneCall: { label: 'Anruf tel1', value: 'phoneCall' },
      phoneCall2: { label: 'Anruf tel2', value: 'phoneCall2' },
      email: { label: 'E-Mail', value: 'email' }
    };
    let formattedOptions: any[] = [];

    if (smsEnabled) {
      formattedOptions.push(optionTemplate['sms']);
    }
    if (smsEnabled2) {
      formattedOptions.push(optionTemplate['sms2']);
    }
    if (emailEnabled) {
      formattedOptions.push(optionTemplate['email']);
    }
    if (voicemailEnabled) {
      formattedOptions.push(optionTemplate['phoneCall']);
    }
    if (voicemailEnabled2) {
      formattedOptions.push(optionTemplate['phoneCall2']);
    }

    return formattedOptions;
  }

  validateInput(input) {
    const {
      phoneNumber,
      phoneNumber2,
      user,
      smsEnabled,
      smsEnabled2,
      emailEnabled,
      voicemailEnabled,
      voicemailEnabled2,
      role,
      lastName,
      firstName
    } = input;

    // Empty Name
    if (firstName === '' || lastName === '') {
      this.setState({ error: 'Bitte gib einen Namen ein' });
      return;
    }

    // No email or phoneNumber
    if (role === 'wearer') {
      // if (user.email === '') {
      //   this.setState({
      //     error: 'Bitte gib eine E-Mail Adresse ein'
      //   });
      //   return;
      // }
      console.log(phoneNumber);

      if (phoneNumber === '' || !phoneNumber) {
        this.setState({
          error: 'Bitte gib eine Telefonnummer 1 ein'
        });
        return;
      }
    }

    if (role === 'helper' || role === 'fallbackHelper') {
      if (user.email === '') {
        this.setState({
          error: 'Bitte gib eine E-Mail Adresse ein'
        });
        return;
      }
    }

    // invalid email
    if (user.email !== '' && !user.email.includes('@')) {
      this.setState({
        error: 'Bitte gib eine gültige E-Mail Adresse ein (max@mustermann.de)'
      });
      return;
    }

    // invalid phone number
    if (phoneNumber == null && phoneNumber2 == null) {
      this.setState({
        error:
          'Bitte gib eine gültige internationale Telefonnummer ein (+499999999999)'
      });
      return;
    }

    // Phone number missing in way of contact
    if (
      (phoneNumber == null && (voicemailEnabled || smsEnabled)) ||
      (phoneNumber2 == null && (voicemailEnabled2 || smsEnabled2))
    ) {
      this.setState({
        error: 'Bitte gib eine Telefonnummer ein'
      });
      return;
    }

    // Email missing in way of contact
    if (user.email === '' && emailEnabled) {
      this.setState({
        error: 'Bitte gib eine E-Mail Adresse ein'
      });
      return;
    }

    // No way of contact
    if (
      !smsEnabled &&
      !smsEnabled2 &&
      !emailEnabled &&
      !voicemailEnabled &&
      !voicemailEnabled2
    ) {
      this.setState({
        error: 'Bitte wähle mindestens eine Kontaktmöglichkeit aus'
      });
      return;
    }

    this.props.save({
      ...input,
      phoneNumber
    });
    this.setState({
      error: ''
    });
  }

  getInitValues() {
    const { user, phoneNumber, phoneNumber2 } = this.props;
    this.prevEmail = user.email;
    this.prevPhone = phoneNumber;
    this.prevPhone2 = phoneNumber2;
  }

  checkPhoneNumber() {
    const { edit, phoneNumber } = this.props;
    if (!phoneNumber) {
      edit(false, 'smsEnabled');
      edit(false, 'voicemailEnabled');
      return;
    }
    if (phoneNumber.length > 0 && phoneNumber !== this.prevPhone) {
      this.prevPhone = phoneNumber;
      edit(true, 'smsEnabled');
    }
    if (phoneNumber.length <= 0) {
      edit(false, 'smsEnabled');
      edit(false, 'voicemailEnabled');
    }
  }

  checkPhoneNumber2() {
    const { edit, phoneNumber2 } = this.props;
    if (!phoneNumber2) {
      edit(false, 'voicemailEnabled2');
      edit(false, 'smsEnabled2');
      return;
    }
    if (phoneNumber2.length > 0 && phoneNumber2 !== this.prevPhone2) {
      this.prevPhone2 = phoneNumber2;
      edit(true, 'voicemailEnabled2');
    }
    if (phoneNumber2.length <= 0) {
      edit(false, 'voicemailEnabled2');
      edit(false, 'smsEnabled2');
    }
  }

  checkEmail() {
    const { edit, user } = this.props;

    if (user.email.length > 0 && user.email !== this.prevEmail) {
      this.prevEmail = user.email;
      edit(true, 'emailEnabled');
    }
    if (user.email.length <= 0) {
      edit(false, 'emailEnabled');
    }
  }

  getTitle(role) {
    if (role === 'helper') {
      return 'Sicherheitskontakt';
    }
    if (role === 'wearer') {
      return 'Träger des Sicherheitsbegleiter';
    }
    if (role === 'emergencyRecipient') {
      return 'Notfallkontakt';
    }
  }

  render() {
    let {
      open,
      phoneNumber,
      phoneNumber2,
      countryCode,
      countryCode2,
      user,
      device,
      edit,
      approved,
      role,
      close,
      deleteRecipient,
      deleteAvatar,
      smsEnabled,
      smsEnabled2,
      emailEnabled,
      voicemailEnabled,
      voicemailEnabled2,
      receiveAlarms,
      firstName,
      lastName,
      gender,
      yearOfBirth,
      toggleDeleteConfirmationModal,
      deleteConfirmationModalOpen
    } = this.props;

    let year = new Date().getFullYear();
    let years = Array.from(new Array(100), (val, index) => {
      const value = -index + year;
      return { value, label: value };
    });

    return (
      <StyledModal isOpen={open} modalTransition={{ timeout: 200 }}>
        <ModalHeader toggle={close}>
          <Title>{this.getTitle(role)}</Title>
        </ModalHeader>
        <StyledModalBody>
          <TextElement
            title={'Vorname'}
            required
            name="firstName"
            value={firstName}
            onChange={(e) =>
              edit(limitUnicodeString(e.target.value, 40), 'firstName')
            }
          ></TextElement>
          <TextElement
            title={'Nachname'}
            required
            name="lastName"
            value={lastName}
            onChange={(e) =>
              edit(limitUnicodeString(e.target.value, 40), 'lastName')
            }
          ></TextElement>

          {role === 'wearer' && (
            <DropdownElement
              title={'Geburtsjahr'}
              type={'yearOfBirth'}
              name="yearOfBirth"
              value={yearOfBirth}
              options={years}
              onChange={(e) => edit(e.target.value, 'yearOfBirth')}
            ></DropdownElement>
          )}

          {role === 'wearer' && (
            <DropdownElement
              title={'Geschlecht'}
              type={'gender'}
              name="gender"
              value={gender}
              options={[
                { value: 'male', label: 'männlich' },
                { value: 'female', label: 'weiblich' },
                { value: 'diverse', label: 'divers' }
              ]}
              onChange={(e) =>
                edit(limitUnicodeString(e.target.value, 40), 'gender')
              }
            ></DropdownElement>
          )}

          <TextElement
            disabled={approved}
            title={'Email'}
            type={'email'}
            name="email"
            value={user.email}
            onChange={(e) =>
              edit(limitUnicodeString(e.target.value, 254), 'user.email')
            }
          ></TextElement>

          <PhoneInputWrapper>
            <PhoneInput
              labels={de}
              defaultCountry={countryCode ? countryCode : 'DE'}
              placeholder="Telefonnummer 1"
              value={phoneNumber}
              onCountryChange={(value: any) => {
                edit(value, 'countryCode');
              }}
              onChange={(value: any) => {
                edit(value, 'phoneNumber');
              }}
            />
            <Text>Land &nbsp;&nbsp; Telefonnummer 1</Text>

            <PhoneInput
              labels={de}
              defaultCountry={countryCode2 ? countryCode2 : 'DE'}
              placeholder="Telefonnummer 2"
              value={phoneNumber2}
              onCountryChange={(value: any) => {
                edit(value, 'countryCode2');
              }}
              onChange={(value: any) => {
                edit(value, 'phoneNumber2');
              }}
            />
            <Text>Land &nbsp;&nbsp; Telefonnummer 2</Text>
          </PhoneInputWrapper>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckboxWrapper>
              <Text>Empfang von Alarmen</Text>
              <Checkbox
                checked={receiveAlarms}
                onChange={() => {
                  edit(receiveAlarms ? false : true, 'receiveAlarms');
                }}
              ></Checkbox>
            </CheckboxWrapper>
          </div>

          <WayOfContactSelectContainer>
            <Text>Kontaktmöglichkeit</Text>
            <Select
              options={this.getOptions()}
              value={this.toSelectionFormat(
                smsEnabled,
                emailEnabled,
                voicemailEnabled,
                smsEnabled2,
                voicemailEnabled2
              )}
              onChange={(selectedOptions) => {
                edit(
                  selectedOptions.some((option) => option.value === 'sms'),
                  'smsEnabled'
                );
                edit(
                  selectedOptions.some((option) => option.value === 'sms2'),
                  'smsEnabled2'
                );
                edit(
                  selectedOptions.some((option) => option.value === 'email'),
                  'emailEnabled'
                );
                edit(
                  selectedOptions.some(
                    (option) => option.value === 'phoneCall'
                  ),
                  'voicemailEnabled'
                );
                edit(
                  selectedOptions.some(
                    (option) => option.value === 'phoneCall2'
                  ),
                  'voicemailEnabled2'
                );
              }}
              placeholder="Wähle eine oder mehrere Kontaktmöglichkeiten aus"
              isMulti
            ></Select>
          </WayOfContactSelectContainer>

          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </StyledModalBody>
        <ModalFooter>
          {user.id ? (
            <DeleteButton
              color="secondary"
              onClick={() => {
                toggleDeleteConfirmationModal();
              }}
            >
              Löschen
            </DeleteButton>
          ) : null}
          <CancelButton
            onClick={() => {
              close();
            }}
          >
            Abbrechen
          </CancelButton>
          <SaveButton
            color="primary"
            type={'submit'}
            onClick={() =>
              this.validateInput({
                phoneNumber,
                phoneNumber2,
                countryCode,
                countryCode2,
                user,
                device,
                smsEnabled,
                smsEnabled2,
                emailEnabled,
                voicemailEnabled,
                voicemailEnabled2,
                receiveAlarms,
                approved,
                role,
                firstName,
                lastName,
                gender,
                yearOfBirth
              })
            }
          >
            Speichern
          </SaveButton>{' '}
          <Dialog
            onClose={() => toggleDeleteConfirmationModal()}
            aria-labelledby="simple-dialog-title"
            open={deleteConfirmationModalOpen}
          >
            <DialogTitle id="simple-dialog-title">
              Den {this.getTitle(role)} wirklich löschen?
            </DialogTitle>
            <DialogContentText>
              Dieser Vorgang kann nicht rückgängig gemacht werden.
            </DialogContentText>
            <ModalContent>
              <ConfirmationWrapper>
                <DeleteConfirmationButtonYes
                  onClick={() => {
                    if (user.id) {
                      if (role === 'wearer') {
                        deleteAvatar(device.c8yId);
                      }
                      deleteRecipient({
                        user,
                        device
                      });
                    } else {
                      close();
                    }
                  }}
                >
                  Ja
                </DeleteConfirmationButtonYes>
                <DeleteConfirmationButtonNo
                  onClick={() => {
                    toggleDeleteConfirmationModal();
                  }}
                >
                  Nein
                </DeleteConfirmationButtonNo>
              </ConfirmationWrapper>
            </ModalContent>
          </Dialog>
        </ModalFooter>
      </StyledModal>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  EditHelperModal
);
