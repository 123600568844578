import { handleActions } from 'redux-actions';
import types from '../types';

interface IAvatarEditorState {
  isOpen: boolean;
  isUploading: boolean;
  deviceId: string | null;
}

const initialState: IAvatarEditorState = {
  isOpen: false,
  isUploading: false,
  deviceId: null
};

export default handleActions(
  {
    // TODO Define type for action
    [types.OPEN_AVATAR_EDITOR]: (
      state: IAvatarEditorState,
      action: any
    ): IAvatarEditorState => ({
      ...state,
      isOpen: true,
      deviceId: action.payload
    }),
    [types.CLOSE_AVATAR_EDITOR]: (state, action) => ({
      ...state,
      isOpen: false,
      deviceId: null
    }),
    [types.UPLOAD_AVATAR]: (state, action) => ({
      ...state,
      isUploading: true
    }),
    [types.UPLOAD_AVATAR_SUCCEEDED]: (state, action) => initialState,
    [types.UPLOAD_AVATAR_FAILED]: (state, action) => initialState
  },
  initialState
);
