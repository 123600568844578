import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  editRecipient,
  recipientCancelEditing,
  createOrUpdateRecipient,
  deleteRecipient,
  toggleDeleteConfirmationModal
} from '../../../actions';

import EditModal from '..';
import { deleteAvatar } from '../../../../../../../components/AvatarEditor/actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.helperModal,
  deleteConfirmationModalOpen: state.helperModal.deleteConfirmationModalOpen
});

const mapDispatchToProps = (dispatch) => ({
  edit: (value, scope) => dispatch(editRecipient({ scope, value })),
  save: (recipient) => dispatch(createOrUpdateRecipient(recipient)),
  close: () => dispatch(recipientCancelEditing()),
  deleteRecipient: (recipient) => dispatch(deleteRecipient(recipient)),
  deleteAvatar: (deviceId) => dispatch(deleteAvatar(deviceId)),
  toggleDeleteConfirmationModal: () => dispatch(toggleDeleteConfirmationModal())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditModal)
);
