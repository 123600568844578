import { put, takeEvery, call } from 'redux-saga/effects';
// import { toast } from 'react-toastify';

import types from '../types';
import {
  confirmEmailSucceeded,
  confirmEmailFailed,
  confirmEmailTokenExpiredEmailResent,
  resendEmailSucceeded,
  resendEmailFailed
} from '../actions';
import {
  AuthenticationService,
  ConfirmMailState
} from '../../../services/AuthenticationService';

function* confirmEmail(action) {
  try {
    const authService = AuthenticationService.instance;
    const confirmMailState = yield call(
      authService.confirmEmail.bind(authService),
      action.payload
    );
    if (confirmMailState === ConfirmMailState.SUCCESS)
      yield put(confirmEmailSucceeded());
    else if (confirmMailState === ConfirmMailState.TOKEN_EXPIRED_AND_MAIL_SENT)
      yield put(confirmEmailTokenExpiredEmailResent());
    else yield put(confirmEmailFailed());
  } catch (error) {
    yield put(confirmEmailFailed());
  }
}

function* resendEmail() {
  try {
    const authService = AuthenticationService.instance;
    yield call(authService.resendEmail.bind(authService));
    yield put(resendEmailSucceeded());
  } catch (error) {
    yield put(resendEmailFailed());
  }
}

const sagas = [
  takeEvery(types.CONFIRM_EMAIL, confirmEmail),
  takeEvery(types.RESEND_EMAIL, resendEmail)
];

export default sagas;
