import { put, takeEvery, call } from 'redux-saga/effects';
import types from '../types';
import {
  requestAvatarImageSucceeded,
  requestAvatarImageFailed
} from '../actions';
import { ImageService } from '../../../services/ImageService';
import { getAvatarImageName } from '../utils';

function* fetchAvatarImage(action) {
  try {
    const deviceId = action.payload;
    const imageService = new ImageService();
    const imageName = getAvatarImageName(deviceId);

    const imageUrl = yield call(
      imageService.downloadImage.bind(imageService),
      imageName
    );

    yield put(requestAvatarImageSucceeded({ deviceId, imageUrl }));
  } catch (error) {
    yield put(requestAvatarImageFailed());
  }
}

const sagas = [takeEvery(types.REQUEST_AVATAR_IMAGE, fetchAvatarImage)];

export default sagas;
