import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoPopoverDiv = styled.div`
  width: 35px;
  height: 35px;
  cursor: pointer;
  padding-left: 12px;
`;

const StyledPopover = styled(Popover)`
  .popover {
    font-family: Montserrat;
  }
`;

const InfoPopover = ({ title, children, id }) => {
  const wrapperRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  //   useOutsideAlerter(wrapperRef, popoverOpen, () => useState(false));

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, popoverOpen]);
  return (
    <InfoPopoverDiv
      id={id}
      onClick={() => setPopoverOpen(true)}
      ref={wrapperRef}
    >
      <FontAwesomeIcon color="#707070" icon={'info-circle'} />
      <StyledPopover
        placement={'right'}
        isOpen={popoverOpen}
        target={id}
        toggle={() => setPopoverOpen(false)}
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{children}</PopoverBody>
      </StyledPopover>
    </InfoPopoverDiv>
  );
};

export default InfoPopover;
