import { RestService } from '../RestService';

type Owner = {
  firstName: string;
  lastName: string;
  email: string;
};

type Order = {
  emergencyServiceEnabled: boolean;
  addedBracelet: boolean;
};

type ButtonInfo = {
  owner: Owner;
  order: Order;
};

export class ButtonInfoService extends RestService {
  async getDeviceInfo(deviceId: string): Promise<ButtonInfo> {
    const url = `${this.baseUrl}/buttons/${deviceId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      const buttonInfo = await response.json();

      return buttonInfo[0];
    }

    throw new Error(`Failed to get owner: ${response.status}`);
  }
}
