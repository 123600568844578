import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  verifying: false,
  verified: false,
  verifyFailed: false,
  tokenExpiredEmailResent: false
};

export default handleActions(
  {
    [types.CONFIRM_EMAIL]: (state, action) => ({
      ...state,
      verifying: true,
      verified: false,
      tokenExpiredEmailResent: false,
      verifyFailed: false
    }),
    [types.CONFIRM_EMAIL_SUCCEEDED]: (state, action) => ({
      ...state,
      verifying: false,
      verified: true
    }),
    [types.CONFIRM_EMAIL_FAILED]: (state, action) => ({
      ...state,
      verifying: false,
      verifyFailed: true
    }),
    [types.CONFIRM_EMAIL_TOKEN_EXPIRED_EMAIL_RESENT]: (state, action) => ({
      ...state,
      verifying: false,
      tokenExpiredEmailResent: true
    })
  },
  initialState
);
