import { ElementType } from 'react';
import {
  AuthenticationService,
  DisplayScope
} from '../../services/AuthenticationService';

export const matchesScope = (
  requiredScope: DisplayScope,
  deviceId: string | null = null
): boolean => {
  const authService = AuthenticationService.instance;

  if (deviceId !== null) {
    if (authService.deviceScope(deviceId) >= requiredScope) return true;
  } else {
    if (authService.globalScope() >= requiredScope) return true;
  }

  return false;
};

export const requiresScope =
  (requiredScope: DisplayScope, deviceId: string | null = null) =>
  (Component: ElementType) => {
    if (matchesScope(requiredScope, deviceId)) return Component;

    return null;
  };
