const getEnvVar = (key) => {
  try {
    const value = process.env[key];
    if (typeof value !== 'undefined') return value;
    // For Windows Support
    else return process.env[key.toLowerCase()];
  } catch {
    return;
  }
};

export default getEnvVar;
