export type Pagination = Array<number | string>;

export const getPagination = (
  totalPages: number,
  number: number
): Pagination => {
  // create array with number [1,2,3,4,5...totalPages]
  let pagination: number[] = [...Array(totalPages).keys()];

  // filter out gabs
  pagination = pagination.filter((x) => {
    if (x === 0) return true;
    if (x === totalPages - 1) return true;
    if (x >= number - 2 && x <= number + 2) return true;

    return false;
  });

  //insert 1 between [1, 3, 4]
  if (pagination[1] - pagination[0] === 2) pagination.splice(1, 0, 1);

  //insert 3 between [1, 2, 4]
  if (
    pagination[pagination.length - 1] - pagination[pagination.length - 2] ===
    2
  )
    pagination.splice(pagination.length - 1, 0, totalPages - 2);

  //insert "..." [0, "...", 3, 4, 5]
  if (pagination[1] - pagination[0] > 2) pagination.splice(1, 0, -1);
  //insert "..." [0, 1, 2, "...", 5]
  if (pagination[pagination.length - 1] - pagination[pagination.length - 2] > 2)
    pagination.splice(pagination.length - 1, 0, -1);

  // replace -1 with "..."
  const stringPagination: Pagination = pagination.map((number) => {
    if (number === -1) {
      return '...';
    }
    return number;
  });

  return ['previous', ...stringPagination, 'next'];
};
