import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import Layout from '../../components/Layout';
import WithAuthentication from '../../components/WithAuthentication';
import SubHeader from '../Device/components/HelperOverview/components/SubHeader';

import EditHelperModal from '../Device/components/HelperOverview/components/EditHelperModal/container';
import FilledRecipient from '../Device/components/HelperOverview/components/FilledHelper';
import EmptyRecipient from '../Device/components/HelperOverview/components/EmptyHelper';

import { matchesScope } from '../../components/DisplayController';
import { DisplayScope } from '../../services/AuthenticationService';

import { Title } from '../Overview';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopButton = styled(Button)`
  border: 1px solid blue;
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const BackButton = styled<any>(TopButton)`
  background-color: #6c757d;
  border-color: #6c757d;
  text-align: center;
  width: 200px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  min-width: 800px;

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const buildRecipients = (recipients, openHelperModal, device) => {
  const recipientElements: JSX.Element[] = [];
  let i = 0;
  for (i; i < recipients.length; i++) {
    const recipient = recipients[i];
    if (recipient.role === 'emergencyRecipient') {
      recipientElements.push(
        <FilledRecipient
          key={recipient.id}
          {...recipient}
          edit={() => openHelperModal(recipient)}
          editable={matchesScope(DisplayScope.OWNER, device.device?.id)}
        ></FilledRecipient>
      );
    }
  }
  if (matchesScope(DisplayScope.OWNER, device.device?.id)) {
    recipientElements.push(
      <EmptyRecipient
        role="emergencyRecipient"
        key={i}
        title={'Notfallkontakt'}
        add={() => openHelperModal({ role: 'emergencyRecipient' })}
      ></EmptyRecipient>
    );
  }
  return recipientElements;
};

/** Page Content */
class FallbackHelper extends Component<any, any> {
  componentDidMount() {
    const { requestRecipients } = this.props;
    requestRecipients('0');
  }

  componentWillUnmount() {}

  render() {
    const { recipients, openHelperModal } = this.props;

    return (
      <WithAuthentication>
        <Layout>
          <Page>
            <Title widthBorder={false}>
              <BackButton onClick={() => this.props.history.push(`/`)}>
                Übersicht
              </BackButton>
            </Title>

            <Container>
              <Content>
                <SubHeader>
                  <p>Notfallkontakte:</p>
                </SubHeader>
                <div>{buildRecipients(recipients, openHelperModal, '0')}</div>
              </Content>
            </Container>
            <EditHelperModal />
          </Page>
        </Layout>
      </WithAuthentication>
    );
  }
}

// @ts-ignore
export default FallbackHelper;
