export const REQUEST_ACTIVITY = 'REQUEST_ACTIVITY';
export const REQUEST_ACTIVITY_SUCCEEDED = 'REQUEST_ACTIVITY_SUCCEEDED';
export const REQUEST_ACTIVITY_FAILED = 'REQUEST_ACTIVITY_FAILED';

export const REQUEST_ALARM = 'REQUEST_ALARM';
export const REQUEST_ALARM_SUCCEEDED = 'REQUEST_ALARM_SUCCEEDED';
export const REQUEST_ALARM_FAILED = 'REQUEST_ALARM_FAILED';

export const ACCEPT_ALARM = 'ACCEPT_ALARM';
export const ACCEPT_ALARM_SUCCEEDED = 'ACCEPT_ALARM_SUCCEEDED';
export const ACCEPT_ALARM_FAILED = 'ACCEPT_ALARM_FAILED';

export const ACKNOWLEDGE_ALARM = 'ACKNOWLEDGE_ALARM';
export const ACKNOWLEDGE_ALARM_SUCCEEDED = 'ACKNOWLEDGE_ALARM_SUCCEEDED';
export const ACKNOWLEDGE_ALARM_FAILED = 'ACKNOWLEDGE_ALARM_FAILED';

export const ALARM_CLOSE = 'ALARM_CLOSE';
export const ALARM_CLOSE_SUCCEEDED = 'ALARM_CLOSE_SUCCEEDED';
export const ALARM_CLOSE_FAILED = 'ALARM_CLOSE_FAILED';

export const ALARM_START_CLOSE = 'ALARM_START_CLOSE';
export const ALARM_CANCEL_CLOSE = 'ALARM_CANCEL_CLOSE';
export const ALARM_CLOSE_EDIT = 'ALARM_CLOSE_EDIT';

export const REQUEST_ALARM_EVENTS = 'REQUEST_ALARM_EVENTS';
export const REQUEST_ALARM_EVENTS_SUCCEEDED = 'REQUEST_ALARM_EVENTS_SUCCEEDED';
export const REQUEST_ALARM_EVENTS_FAILED = 'REQUEST_ALARM_EVENTS_FAILED';

export const REFETCHED_ALARMS = 'REFETCHED_ALARMS';

const TYPES = {
  REQUEST_ACTIVITY,
  REQUEST_ACTIVITY_SUCCEEDED,
  REQUEST_ACTIVITY_FAILED,
  REQUEST_ALARM,
  REQUEST_ALARM_SUCCEEDED,
  REQUEST_ALARM_FAILED,
  ACCEPT_ALARM,
  ACCEPT_ALARM_SUCCEEDED,
  ACCEPT_ALARM_FAILED,
  ACKNOWLEDGE_ALARM,
  ACKNOWLEDGE_ALARM_SUCCEEDED,
  ACKNOWLEDGE_ALARM_FAILED,
  ALARM_CLOSE,
  ALARM_CLOSE_SUCCEEDED,
  ALARM_CLOSE_FAILED,
  ALARM_START_CLOSE,
  ALARM_CANCEL_CLOSE,
  ALARM_CLOSE_EDIT,
  REQUEST_ALARM_EVENTS,
  REQUEST_ALARM_EVENTS_SUCCEEDED,
  REQUEST_ALARM_EVENTS_FAILED,
  REFETCHED_ALARMS
};

export default TYPES;
