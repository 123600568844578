import { Component } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoPopover from '../../../../../../components/InfoPopover';
import { Button } from 'reactstrap';
import { NumberInput } from '../Input';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  @media (min-width: 760px) {
    flex-direction: row;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
  color: #707070;
  font-family: Montserrat;
  padding-top: 35px;
  width: auto;
  max-width: 100%;
  margin-bottom: 25px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  color: #707070;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  padding-right: 250px;
`;

const InputWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  display: flex;
  max-width: 100px;
  @media (min-width: 760px) {
    margin-left: auto;
  }
  @media (max-width: 760px) {
    margin: auto;
  }
  margin-top: -7.25px;
`;

const Unit = styled.p`
  display: flex;
  margin-left: 5px;
  margin-top: 7.25px;
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const ClickableText = styled.p<any>`
  color: ${({ active }) => (active ? '#ea6000' : '#707070')};
  font-size: ${({ active }) => (active ? '16pt' : '12pt')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-family: Montserrat;
  width: auto;
  &:hover {
    //color: #ea6000;
    cursor: pointer;
  }
`;

const TopButton = styled(Button)`
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const SaveChangesButton = styled<any>(TopButton)`
  background-color: #ea6000;
  border-color: #ea6000;

  margin-top: 35px;
  margin-left: auto;
  width: 150px;
`;

interface IConfigurationRow {
  id: string;
  heading: string;
  value: string | number;
  onChange: (value: number | string) => void;
  onBlur: (value: number | string) => void;
  isNumber?: boolean;
  unit?: string;
  info?: any;
}

const ConfigurationRow = ({
  id,
  heading,
  value,
  onChange,
  onBlur,
  unit = '',
  isNumber = false,
  info = null
}: IConfigurationRow) => (
  <Row>
    <Description>
      {heading}
      {info && (
        <InfoPopover id={id} title={heading}>
          {info}
        </InfoPopover>
      )}
    </Description>
    <InputWrapper>
      {isNumber ? (
        <NumberInput
          name={heading}
          value={value}
          onChange={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onChange(parseInt(e.target.value, 10));
            }
          }}
          onBlur={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onBlur(parseInt(e.target.value, 10));
            }
          }}
        />
      ) : /*
        <ToggleSwitch
          color="default"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          name={heading}
          value={value}
        />
        */
      null}
      <Unit>{unit}</Unit>
    </InputWrapper>
  </Row>
);

const limitValue = (
  value: number | string,
  max: number,
  std: number,
  min: number
): number => {
  const v = typeof value === 'string' ? parseInt(value, 10) : value;
  if (v > max) return max;
  if (v <= min) return min;
  if (!v) return std;
  return v;
};

/** Page Content */
class TrackingSettings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  render() {
    const { trackingSettings, edit, save } = this.props;

    return (
      <Div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Tracking Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ConfigurationRow
                id="longpressMode"
                heading={'Langes Drücken'}
                value={trackingSettings.longpressMode}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Standardgemäß wird auch bei langem Drücken ein Alarm
                      ausgelöst. Alternativ kann langes Drücken des Gerätes zum
                      Ein- und Ausschalten des Trackingmodus verwendet werden.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={trackingSettings.longpressMode}
                  onClick={() => edit('longpressMode', true)}
                >
                  Tracking Modus
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.longpressMode}
                  onClick={() => edit('longpressMode', false)}
                >
                  Alarm Modus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="gpsTracking"
                heading={'GPS Tracking'}
                value={trackingSettings.gpsTracking}
                onBlur={() => {}}
                onChange={() => {}}
              />

              <Container>
                <ClickableText
                  active={trackingSettings.gpsTracking}
                  onClick={() => edit('gpsTracking', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.gpsTracking}
                  onClick={() => edit('gpsTracking', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="bleTracking"
                heading={'BLE Tracking'}
                value={trackingSettings.bleTracking}
                onBlur={() => {}}
                onChange={() => {}}
              />

              <Container>
                <ClickableText
                  active={trackingSettings.bleTracking}
                  onClick={() => edit('bleTracking', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.bleTracking}
                  onClick={() => edit('bleTracking', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="trackingMode"
                heading={'Tracking Modus'}
                value={trackingSettings.trackingMode}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Bei Batteriesparendem tracking wird nur dann eine neue
                      Position ermittelt, wenn das Gerät auch bewegt worden ist.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={trackingSettings.trackingMode}
                  onClick={() => edit('trackingMode', true)}
                >
                  Batteriesparendes Tracking
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.trackingMode}
                  onClick={() => edit('trackingMode', false)}
                >
                  Immer Tracking
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="trackingIntervalTiming"
                heading={'Timing der Tracking Intervalle'}
                value={trackingSettings.trackingIntervalTiming}
                onBlur={(value) =>
                  edit(
                    'trackingIntervalTiming',
                    limitValue(value, 86400, 900, 300)
                  )
                }
                onChange={(value) => edit('trackingIntervalTiming', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>Trackingintervalle.</PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 300s (5min) </PopoverLine>
                    <PopoverLine>Standard: 900s (15min) </PopoverLine>
                    <PopoverLine>Maximum: 86.400s (24h) </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="gpsTimeout"
                heading={'GPS Timeout'}
                value={trackingSettings.gpsTimeout}
                onBlur={(value) =>
                  edit('gpsTimeout', limitValue(value, 600, 180, 60))
                }
                onChange={(value) => edit('gpsTimeout', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeitspanne, bis die Suche nach GPS Empfang aufgegeben
                      wird.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 60s (1min) </PopoverLine>
                    <PopoverLine>Standard: 180s (3min) </PopoverLine>
                    <PopoverLine>Maximum: 600s (10min) </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="gpsCaptures"
                heading={'GPS Distanz Variabilität'}
                value={trackingSettings.gpsCaptures}
                onBlur={(value) =>
                  edit('gpsCaptures', limitValue(value, 255, 10, 1))
                }
                onChange={(value) => edit('gpsCaptures', value)}
                unit={'m'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Genauigkeit der GPS Bestimmung. Die GPS-Position wird
                      übermittelt, sobald die Genauigkeit erreicht wird oder
                      aber der GPS timeout erreicht wird.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 1 meter (wird in der Praxis aber nicht erreicht){' '}
                    </PopoverLine>
                    <PopoverLine>Standard: 10 meter </PopoverLine>
                    <PopoverLine>Maximum: 255 meter </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="gpsFollows"
                heading={'GPS Distanz Folge'}
                value={trackingSettings.gpsFollows}
                onBlur={(value) =>
                  edit('gpsFollows', limitValue(value, 25, 3, 1))
                }
                onChange={(value) => edit('gpsFollows', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Genauigkeit der GPS Bestimmung. Die GPS-Position wird
                      übermittelt, sobald die Distanz Variabilität x mal in
                      Folge unterschritten wird.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 1 </PopoverLine>
                    <PopoverLine>Standard: 3 </PopoverLine>
                    <PopoverLine>Maximum: 25 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="gpsDeviation"
                heading={'GPS Abweichung'}
                value={trackingSettings.gpsDeviation}
                onBlur={(value) =>
                  edit('gpsDeviation', limitValue(value, 255, 50, 10))
                }
                onChange={(value) => edit('gpsDeviation', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Genauigkeit der GPS Ortung. Nur GPS-Positionen werden
                      berücksichtigt, welche unter dem Grenzwert der von GPS
                      geschätzten Ungenauigkeit liegen. Etwa 60cm pro Einheit
                      (0.1HDOP)
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10 </PopoverLine>
                    <PopoverLine>Standard: 50 </PopoverLine>
                    <PopoverLine>Maximum: 255 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="autoTrackingLeavingHome"
                heading={'Automatisches Tracking bei verlassen des Zuhause'}
                value={trackingSettings.autoTrackingLeavingHome}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Wenn das Gerät nach der unten angegebenen Zeit keinen
                      zuhause fest installierten BLE beacon mehr erkennt, wird
                      automatisch das Tracking nach oben eingestellten tracking
                      Intervallen ausgelöst. Der Nutzer muss diesem
                      automatischen Tracking extra zugestimmt haben.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 15s </PopoverLine>
                    <PopoverLine>Standard: 180s (3min) </PopoverLine>
                    <PopoverLine>Maximum: 65.535s </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={trackingSettings.autoTrackingLeavingHome}
                  onClick={() => edit('autoTrackingLeavingHome', true)}
                >
                  AN
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.autoTrackingLeavingHome}
                  onClick={() => edit('autoTrackingLeavingHome', false)}
                >
                  AUS
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="autoTrackingDelay"
                heading={'Beginn des Automatischen Tracking'}
                value={trackingSettings.autoTrackingDelay}
                onBlur={(value) =>
                  edit('autoTrackingDelay', limitValue(value, 65535, 180, 15))
                }
                onChange={(value) => edit('autoTrackingDelay', value)}
                unit={'s'}
                isNumber
              />

              <ConfigurationRow
                id="remoteTracking"
                heading={'Verlorenen Sicherheitsbegleiter finden'}
                value={trackingSettings.remoteTracking}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Der Sicherheitsbetleiter kann erst dann seine Position
                      melden, wenn er sich das nächste Mal verbindet.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={trackingSettings.remoteTracking}
                  onClick={() => edit('remoteTracking', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!trackingSettings.remoteTracking}
                  onClick={() => edit('remoteTracking', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="numberOfTrackingPositions"
                heading={'Anzahl der Positionen'}
                value={trackingSettings.numberOfTrackingPositions}
                onBlur={(value) =>
                  edit(
                    'numberOfTrackingPositions',
                    limitValue(value, 65535, 0, 0)
                  )
                }
                onChange={(value) => edit('numberOfTrackingPositions', value)}
                isNumber
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <SaveChangesButton onClick={() => save()}>Speichern</SaveChangesButton>
      </Div>
    );
  }
}

export default TrackingSettings;
