import { Component } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import InfoPopover from '../../../../../../components/InfoPopover';
import { Button } from 'reactstrap';
import { NumberInput } from '../Input';
import TextField from '@material-ui/core/TextField';
import Checkbox from '../Checkbox';
import moment from 'moment';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Digit = styled.div`
  color: #707070;
  font-family: Montserrat;
  font-size: 40pt;
`;

const Row = styled.div`
  display: flex;
  @media (min-width: 760px) {
    flex-direction: row;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
  color: #707070;
  font-family: Montserrat;
  padding-top: 35px;
  width: auto;
  max-width: 100%;
  margin-bottom: 25px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  color: #707070;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  padding-right: 250px;
`;

const InputWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  display: flex;
  max-width: 100px;
  @media (min-width: 760px) {
    margin-left: auto;
  }
  @media (max-width: 760px) {
    margin: auto;
  }
  margin-top: -7.25px;
`;

const Unit = styled.p`
  display: flex;
  margin-left: 5px;
  margin-top: 7.25px;
`;

const TopButton = styled(Button)`
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const SaveChangesButton = styled<any>(TopButton)`
  background-color: #ea6000;
  border-color: #ea6000;

  margin-top: 35px;
  margin-left: auto;
  width: 150px;
`;

const ClickableText = styled.p<any>`
  color: ${({ active }) => (active ? '#ea6000' : '#707070')};
  font-size: ${({ active }) => (active ? '16pt' : '12pt')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-family: Montserrat;
  width: auto;
  &:hover {
    //color: #ea6000;
    cursor: pointer;
  }
`;

const ContainerPicker = styled.p`
  display: 'flex';
  flexwrap: 'wrap';
`;

const TextFieldPicker = styled<any>(TextField)`
  width: 150px;
`;

const CheckboxOuterWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 300px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

const Text = styled.p<any>`
  color: #707070;
  font-size: 15px;
  font-family: 'Montserrat';
`;

const StyledTypography = styled(Typography)`
  width: 100%;
`;

interface IConfigurationRow {
  id: string;
  heading: string;
  value: string | number | null;
  onChange: (value: number | string) => void;
  onBlur?: (value: number | string) => void;
  isNumber?: boolean;
  unit?: string;
  info?: any;
}

const ConfigurationRow = ({
  id,
  heading,
  value,
  onChange,
  onBlur,
  unit = '',
  isNumber = false,
  info = null
}: IConfigurationRow) => (
  <Row>
    <Description>
      {heading}
      {info && (
        <InfoPopover id={id} title={heading}>
          {info}
        </InfoPopover>
      )}
    </Description>
    <InputWrapper>
      {isNumber ? (
        <NumberInput
          name={heading}
          value={value}
          onChange={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onChange(parseInt(e.target.value, 10));
            }
          }}
          onBlur={(e) => {
            if (typeof e.target.value !== 'undefined') {
              //onBlur(parseInt(e.target.value, 10));
            }
          }}
        />
      ) : /*
        <ToggleSwitch
          color="default"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          name={heading}
          value={value}
        />
        */
      null}
      <Unit>{unit}</Unit>
    </InputWrapper>
  </Row>
);

/** Page Content */
class Settings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  render() {
    const { buttonSettings, edit, save } = this.props;

    const sendUpdate = () => {
      save({
        //TP: buttonSettings.trackingPeriod,
        //GPSTIMEOUT: getValue(gpsTimeout, GPSTIMEOUT),
        //GPSCAPTURES: getValue(gpsCaptures, GPSCAPTURES)
        // alertFollowUpMessages,
        // followUpMessageInterval
      });
    };

    const limitValue = (
      value: number | string,
      max: number,
      std: number,
      min: number
    ): number => {
      const v = typeof value === 'string' ? parseInt(value, 10) : value;
      if (v > max) return max;
      if (v <= min) return min;
      if (!v) return std;
      return v;
    };

    function leadingZero(n: number) {
      return n < 10 ? '0' + n : n;
    }

    function checkTime_minutesToHours(value: number) {
      return Math.floor(value / 60);
    }

    function checkTime_minutesToMinute(value: number) {
      return Math.floor(value % 60);
    }

    function warningNotWearing_incrementHour() {
      if (buttonSettings.warningNotWearingTime <= 60 * 14) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime + 60)
        );
      }
    }

    function warningNotWearing_decrementHour() {
      if (buttonSettings.warningNotWearingTime >= 60 * 6) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime - 60)
        );
      }
    }

    function warningNotWearing_incrementMinute() {
      if (
        Math.floor(buttonSettings.warningNotWearingTime % 60) < 60 &&
        buttonSettings.warningNotWearingTime < 60 * 15
      ) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime + 1)
        );
      }
    }

    function warningNotWearing_decrementMinute() {
      if (buttonSettings.warningNotWearingTime > 60 * 5) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime - 1)
        );
      }
    }

    function getColor() {
      if (buttonSettings.warningNotWearing === false) {
        return 'lightGrey';
      }
      return '#707070';
    }

    function getPointer() {
      if (buttonSettings.warningNotWearing === false) {
        return 'none';
      }
      return 'auto';
    }

    return (
      <Div>
        <ConfigurationRow
          id="wearingLocation"
          heading={'Trageposition'}
          value={buttonSettings.wearingLocation}
          onChange={() => {}}
          info={
            <div>
              <PopoverLine>
                Definiert, wo am Körper der Secufy Sicherheitsbegleiter getragen
                wird.
              </PopoverLine>
            </div>
          }
        />

        <Container>
          <ClickableText
            active={!buttonSettings.wearingLocation}
            onClick={() => edit('wearingLocation', false)}
          >
            Hüfte
          </ClickableText>
        </Container>
        <Container>
          <ClickableText
            active={buttonSettings.wearingLocation}
            onClick={() => edit('wearingLocation', true)}
          >
            Handgelenk
          </ClickableText>
        </Container>

        <p></p>

        <ConfigurationRow
          id="blinkMode"
          heading={'Blink Modus'}
          value={buttonSettings.blinkMode}
          onChange={() => {}}
          info={
            <div>
              <PopoverLine>
                Reduzierter Blinkcode: beim Laden grün und bei 1x kurz drücken
                Akkustandanzeige 1x bis 4x orange blinken. Standard zusätzlich:
                Voralarm, Alarm, Batterie schwach, Batterie voll.
              </PopoverLine>
            </div>
          }
        />

        <Container>
          <ClickableText
            active={buttonSettings.blinkMode === false}
            onClick={() => edit('blinkMode', false)}
          >
            Standard
          </ClickableText>
        </Container>
        <Container>
          <ClickableText
            active={buttonSettings.blinkMode === true}
            onClick={() => edit('blinkMode', true)}
          >
            Reduziert
          </ClickableText>
        </Container>

        <p></p>

        <Accordion defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Warnung bei Nichttragen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ConfigurationRow
                id="warningNotWearingTime"
                heading={'Warnung bei Nichttragen'}
                value={null}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Es wird eine Warnung ausgelöst, wenn der Secufy
                      Sicherheitsbegleiter bis zu dieser Uhrzeit nicht bewegt
                      wurde.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.warningNotWearing}
                  onClick={() => edit('warningNotWearing', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!buttonSettings.warningNotWearing}
                  onClick={() => edit('warningNotWearing', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="warningNotWearingTime"
                heading={'Zeitpunkt für Warnung bei Nichttragen'}
                value={null}
                onBlur={() => {}}
                onChange={() => {}}
              />

              <Container>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="incrementHour"
                  onClick={warningNotWearing_incrementHour}
                >
                  <ArrowUp />
                </IconButton>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="incrementMinute"
                  onClick={warningNotWearing_incrementMinute}
                >
                  <ArrowUp />
                </IconButton>
              </Container>

              <Container>
                <Digit style={{ color: getColor() }}>
                  {leadingZero(
                    checkTime_minutesToHours(
                      buttonSettings.warningNotWearingTime
                    )
                  )}
                </Digit>
                <Digit style={{ color: getColor() }}> : </Digit>
                <Digit style={{ color: getColor() }}>
                  {leadingZero(
                    checkTime_minutesToMinute(
                      buttonSettings.warningNotWearingTime
                    )
                  )}
                </Digit>
              </Container>

              <Container>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="decrementHour"
                  onClick={warningNotWearing_decrementHour}
                >
                  <ArrowDown />
                </IconButton>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="decrementMinute"
                  onClick={warningNotWearing_decrementMinute}
                >
                  <ArrowDown />
                </IconButton>
              </Container>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>
              Automatische Vorabanrufe an den Träger
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="siestaFuction"
                heading={'Automatische Vorabanrufe'}
                value={buttonSettings.firstActiveMsg}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Vor automatischen Alarmen und vor Warnungen bei
                      Nichttragen wird ein Telefonanruf an die Rufnummer(n) des
                      Trägers / der Trägerin erzeugt, welcher daran erinnert,
                      den Sicherheitsbegleiter zu benutzen. Durch eine Bewegung
                      des Sicherheitsbegleiters kurz nach dem Telefonanruf
                      werden die Alarme & Warnungen unterbunden.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.automaticCallBeforeAlarm === 0}
                  onClick={() => {
                    edit('automaticCallBeforeAlarm', 0);
                  }}
                >
                  Keine automatischen Vorabanrufe
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.automaticCallBeforeAlarm === 1}
                  onClick={() => {
                    edit('automaticCallBeforeAlarm', 1);
                  }}
                >
                  Vorabanrufe nur wenn Sicherheitsbegleiter zu Hause
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.automaticCallBeforeAlarm === 2}
                  onClick={() => {
                    edit('automaticCallBeforeAlarm', 2);
                  }}
                >
                  Vorabanrufe sowohl zu Hause als auch unterwegs
                </ClickableText>
              </Container>
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>
              Automatische Alarme kurzzeitig pausieren
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="siestaFuction"
                heading={'Siesta-Funktion'}
                value={buttonSettings.firstActiveMsg}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Durch zweimal kurz hintereinander Drücken (Doppelklick)
                      kann der automatische Alarm bei Bewegungslosigkeit für die
                      unten eingestellte Siesta-Zeit ausgeschalten werden. Er
                      aktiviert sich nach Ablauf der Zeit von selbst wieder.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.doubleClickDoNothing}
                  onClick={() => {
                    edit('doubleClickDoNothing', true);
                  }}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!buttonSettings.doubleClickDoNothing}
                  onClick={() => {
                    edit('doubleClickDoNothing', false);
                  }}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="siestaTime"
                heading={'Siesta-Zeit'}
                value={buttonSettings.siestaTime / 60}
                onBlur={(value: any) =>
                  edit('siestaTime', limitValue(value * 60, 64800, 7200, 3600))
                }
                onChange={(value: any) => edit('siestaTime', value * 60)}
                unit={'min'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Gibt die Dauer an, wie lange der Sicherheitsbegleiter
                      Automatische Alarme ignoriert.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 60min </PopoverLine>
                    <PopoverLine>Standard: 120min (2 Stunden) </PopoverLine>
                    <PopoverLine>
                      Maximum: 1080min (ca. 18 Stunden){' '}
                    </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="pause-content"
            id="pause-header"
          >
            <Typography>Alarmfunktionen länger pausieren</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ConfigurationRow
                id="from"
                heading={'Von'}
                value={null}
                onChange={() => {}}
              />
              <Container>
                <form className={ContainerPicker} noValidate>
                  <TextFieldPicker
                    id="pauseFrom"
                    type="date"
                    value={buttonSettings.pausePeriodFrom}
                    className={TextFieldPicker}
                    InputProps={{
                      inputProps: {
                        min: moment().utc().format('YYYY-MM-DD'),
                        max: buttonSettings.pausePeriodTo
                      }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => edit('pausePeriodFrom', e.target.value)}
                  />
                </form>
              </Container>

              <ConfigurationRow
                id="to"
                heading={'Bis'}
                value={null}
                onChange={() => {}}
              />
              <Container>
                <form className={ContainerPicker} noValidate>
                  <TextFieldPicker
                    id="pauseTo"
                    type="date"
                    value={buttonSettings.pausePeriodTo}
                    className={TextFieldPicker}
                    InputProps={{
                      inputProps: { min: buttonSettings.pausePeriodFrom }
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => edit('pausePeriodTo', e.target.value)}
                  />
                </form>
              </Container>

              <p></p>

              <ConfigurationRow
                id="functions"
                heading={'Von der Pausierung betroffene Alarme / Warnungen'}
                value={null}
                onChange={() => {}}
              />
              <Container>
                <CheckboxOuterWrapper>
                  <CheckboxWrapper>
                    <Text>Manuelle Alarme</Text>
                    <Checkbox
                      checked={buttonSettings.pauseManualAlarm}
                      onChange={() => {
                        edit(
                          'pauseManualAlarm',
                          buttonSettings.pauseManualAlarm ? false : true
                        );
                      }}
                    ></Checkbox>
                  </CheckboxWrapper>
                </CheckboxOuterWrapper>
              </Container>
              <Container>
                <CheckboxOuterWrapper>
                  <CheckboxWrapper>
                    <Text>Automatische Alarme</Text>
                    <Checkbox
                      checked={buttonSettings.pauseAutomaticAlarm}
                      onChange={() => {
                        edit(
                          'pauseAutomaticAlarm',
                          buttonSettings.pauseAutomaticAlarm ? false : true
                        );
                      }}
                    ></Checkbox>
                  </CheckboxWrapper>
                </CheckboxOuterWrapper>
              </Container>
              <Container>
                <CheckboxOuterWrapper>
                  <CheckboxWrapper>
                    <Text>Warnung bei Nichttragen</Text>
                    <Checkbox
                      checked={buttonSettings.pauseDayCheck}
                      onChange={() => {
                        edit(
                          'pauseDayCheck',
                          buttonSettings.pauseDayCheck ? false : true
                        );
                      }}
                    ></Checkbox>
                  </CheckboxWrapper>
                </CheckboxOuterWrapper>
              </Container>
              <Container>
                <CheckboxOuterWrapper>
                  <CheckboxWrapper>
                    <Text>Batteriestand</Text>
                    <Checkbox
                      checked={buttonSettings.pauseBatteryWarning}
                      onChange={() => {
                        edit(
                          'pauseBatteryWarning',
                          buttonSettings.pauseBatteryWarning ? false : true
                        );
                      }}
                    ></Checkbox>
                  </CheckboxWrapper>
                </CheckboxOuterWrapper>
              </Container>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Alles o.k., Alarme stornieren</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="doubleClickReset"
                heading={'O.k.-Funktion'}
                value={buttonSettings.firstActiveMsg}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Durch zweimal kurz hintereinander Drücken kann ein
                      irrtümlich ausgelöster Alarm nachträglich zurückgenommen
                      werden. Die Sicherheitskontakte erhalten dann eine zweite
                      Nachricht, dass alles o.k. ist.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.doubleClickReset}
                  onClick={() => {
                    edit('doubleClickReset', true);
                  }}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!buttonSettings.doubleClickReset}
                  onClick={() => {
                    edit('doubleClickReset', false);
                  }}
                >
                  Aus
                </ClickableText>
              </Container>
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <SaveChangesButton onClick={() => sendUpdate()}>
          Speichern
        </SaveChangesButton>
      </Div>
    );
  }
}

export default Settings;
