import { Component } from 'react';
import styled from 'styled-components';
import Table from '../Table/container';
import Map from '../Map/container/index';
import { isUndefined } from 'lodash';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  min-height: calc(100% - 108px);
  background-color: #fafafa;

  @media (max-width: 1598px) {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  margin: 0;
  width: auto;
`;

const InfoText = styled.p`
  font-family: Montserrat;
  margin-top: -150px !important;
  font-size: 20px;
  color: #95a5a6;
  @media (max-width: 991.92px) {
    padding: 0px 20px;
  }
`;

const TableWrapper = styled.div<any>`
  height: 100%;
  ${({ width }) => `width: ${width}`};

  @media (max-width: 1598px) {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const MapWrapper = styled.div`
  height: 100%;
  min-height: 600px;
  width: calc(40% - 30px);
  margin-left: 30px;
  @media (max-width: 1598px) {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0px;
  }
`;

class Devices extends Component<any, any> {
  componentDidMount() {
    const { requestDevices } = this.props;
    requestDevices();
  }
  locationsAvailable() {
    let haveLocation = false;
    this.props.devices.forEach((device) => {
      if (!isUndefined(device.c8y_Position)) haveLocation = true;
    });
    return haveLocation;
  }
  render() {
    const { devices, loading } = this.props;
    const showMap = this.locationsAvailable();
    const tableSize = showMap ? 60 : 100;

    if (loading) return <LoadingIndicator></LoadingIndicator>;

    if (devices.length === 0) {
      return (
        <InfoWrapper>
          <InfoText>No buttons / tracker found.</InfoText>
        </InfoWrapper>
      );
    } else {
      return (
        <Wrapper>
          <TableWrapper width={`${tableSize}%`}>
            <Table />
          </TableWrapper>

          {showMap && (
            <MapWrapper>
              <Map />
            </MapWrapper>
          )}
        </Wrapper>
      );
    }
  }
}

export default Devices;
