import { C8yBaseService } from '../C8yBaseService';

export type ManagedObject = {
  id: string;
  name: string;
  type: string;
};

export type ManagedObjectsCollection = {
  managedObjects: ManagedObject[];
};

export class ImageService extends C8yBaseService {
  async searchImage(name: string): Promise<ManagedObject | null> {
    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/inventory/managedObjects?type=c8y_IsBinary&query=$filter=(name+eq+'${name}')`,
      {
        headers: {
          Authorization,
          'Content-Type': 'application/json'
        },
        method: 'GET'
      }
    );

    if (response.status >= 200 && response.status <= 299) {
      const collection = (await response.json()) as ManagedObjectsCollection;
      if (collection.managedObjects.length === 0) {
        return null;
      }
      return collection.managedObjects[0];
    }
    return null;
  }

  async uploadImage(name: string, blob: Blob): Promise<string | null> {
    const binaryManagedObject = await this.searchImage(name);

    if (binaryManagedObject) {
      return this.updateImage(binaryManagedObject.id, blob);
    }

    var formdata = new FormData();
    formdata.append(
      'object',
      JSON.stringify({
        c8y_Global: {},
        name,
        type: 'image/png'
      })
    );
    formdata.append('file', blob, name);

    const Authorization = await this.getAuthHeader();
    const response = await fetch(`${this.baseUrl}/inventory/binaries`, {
      headers: {
        // 'Content-Type': 'image/png',
        Authorization,
        Accept: 'application/json'
      },
      method: 'POST',
      body: formdata
    });

    if (response.status >= 200 && response.status <= 299) {
      return URL.createObjectURL(blob);
    } else {
      return null;
    }
  }

  async updateImage(binaryId: string, blob: Blob): Promise<string | null> {
    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/inventory/binaries/${binaryId}`,
      {
        headers: {
          'Content-Type': 'image/png',
          Authorization,
          Accept: 'application/json'
        },
        method: 'PUT',
        body: blob
      }
    );

    if (response.status >= 200 && response.status <= 299) {
      return URL.createObjectURL(blob);
    } else {
      return null;
    }
  }

  async deleteImage(name: string): Promise<string | null> {
    const binaryManagedObject = await this.searchImage(name);
    if (binaryManagedObject) {
      const managedObjectId = binaryManagedObject.id;

      const Authorization = await this.getAuthHeader();
      const response = await fetch(
        `${this.baseUrl}/inventory/binaries/${managedObjectId}`,
        {
          headers: {
            Authorization,
            Accept: 'application/json'
          },
          method: 'DELETE'
        }
      );

      if (response.status >= 200 && response.status <= 299) {
        return 'success';
      } else {
        return null;
      }
    }

    return null;
  }

  async downloadImage(name: string): Promise<string | null> {
    const managedObject = await this.searchImage(name);

    if (!managedObject!) return null;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/inventory/binaries/${managedObject.id}`,
      {
        headers: {
          Authorization
        },
        method: 'GET'
      }
    );
    const buffer = await response.arrayBuffer();
    const blob = new Blob([buffer], { type: managedObject.type });
    return URL.createObjectURL(blob);
  }
}
