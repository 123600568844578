import styled from 'styled-components';

import Text from '../Text';

const LabeledDotDiv = styled.div<any>`
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-left: 15px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Dot = styled.div<any>`
  margin-right: 8px;
  border-radius: 100%;
  border-width: 3.75px;
  border-style: solid;
  margin-top: 4px;

  height: 15px;
  width: 15px;
  min-width: 15px;

  ${({ color }) =>
    `
    background-color: ${color};
  `}
  ${({ bgColor }) =>
    `
    border-color: ${bgColor};
  `}
`;

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabeledDot = (props) => {
  const { textStyle, children, color, bgColor } = props;
  return (
    <LabeledDotDiv>
      <Dot color={color} bgColor={bgColor} />
      {children && (
        <TextWrapper>
          <Text textStyle={textStyle}>{children}</Text>
        </TextWrapper>
      )}
    </LabeledDotDiv>
  );
};

export default LabeledDot;
