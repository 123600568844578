import { createAction } from 'redux-actions';
import types from '../types';

export const approveRecipient = createAction(types.APPROVE_RECIPIENT);
export const approveRecipientSucceeded = createAction(
  types.APPROVE_RECIPIENT_SUCCEEDED
);
export const approveRecipientFailed = createAction(
  types.APPROVE_RECIPIENT_FAILED
);
export const confirmEmailTokenExpiredEmailResent = createAction(
  types.APPROVE_RECIPIENT_TOKEN_EXPIRED_EMAIL_RESENT
);
