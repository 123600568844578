import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestDevices } from '../../../../Device/components/HelperOverview/actions';

import Devices from '..';

const mapStateToProps = (state, ownProps) => {
  return {
    selectedDeviceId: state.selectedDevice,
    devices: state.deviceList.devices,
    loading: state.deviceList.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestDevices: () => dispatch(requestDevices())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Devices)
);
