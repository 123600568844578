import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import device from '../scenes/Device/components/HelperOverview/reducer/device';
import deviceList from '../scenes/Device/components/HelperOverview/reducer/deviceList';
import selectedDevice from '../scenes/Device/components/HelperOverview/reducer/selectedDevice';
import generalSettings from '../scenes/Device/components/HelperOverview/reducer/generalSettings';
import recipients from '../scenes/Device/components/HelperOverview/reducer/recipients';
import beacons from '../scenes/Device/components/HelperOverview/reducer/beacons';
import owner from '../scenes/Device/components/HelperOverview/reducer/owner';
import order from '../scenes/Device/components/HelperOverview/reducer/order';
import hardwareInfo from '../scenes/Device/components/HelperOverview/reducer/hardwareInfo';
import helpersLoading from '../scenes/Device/components/HelperOverview/reducer/helpersLoading';
import helperModal from '../scenes/Device/components/HelperOverview/reducer/helperModal';
import closeAlarmModal from '../components/DeviceTile/reducer/closeAlarmModal';
import lastEvent from '../scenes/Device/components/HelperOverview/reducer/lastEvent';
import alarmEvents from '../components/DeviceTile/reducer/alarmEvents';
import emergencyPlans from '../scenes/Device/components/HelperOverview/reducer/emergencyPlans';
import buttonSettings from '../scenes/Device/components/HelperOverview/reducer/buttonSettings';
import trackingSettings from '../scenes/Device/components/HelperOverview/reducer/trackingSettings';
import bluetoothSettings from '../scenes/Device/components/HelperOverview/reducer/bluetoothSettings';
import deviceOverview from '../scenes/Device/components/HelperOverview/reducer/deviceOverview';
import overview from '../scenes/Overview/reducer';
import avatarEditor from '../components/AvatarEditor/reducer';
import avatar from '../components/Avatar/reducer';
import activity from '../components/DeviceTile/reducer/activity';
import alarm from '../components/DeviceTile/reducer/alarm';
import verifyEmail from '../scenes/VerifyEmail/reducer';
import verifyButtonOwnership from '../scenes/VerifyButtonOwnership/reducer';
import approveRecipient from '../scenes/ApproveRecipient/reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    deviceList,
    device,
    selectedDevice,
    generalSettings,
    recipients,
    beacons,
    hardwareInfo,
    owner,
    order,
    helperModal,
    closeAlarmModal,
    helpersLoading,
    lastEvent,
    alarmEvents,
    emergencyPlans,
    buttonSettings,
    trackingSettings,
    bluetoothSettings,
    deviceOverview,
    overview,
    avatarEditor,
    avatar,
    activity,
    alarm,
    verifyEmail,
    verifyButtonOwnership,
    approveRecipient
  });

export default rootReducer;
