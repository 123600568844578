import sizeMe from 'react-sizeme';
import styled from 'styled-components';

const Container = styled.div<any>`
  margin-bottom: 20px;
  height: 60px;
  ${({ width }) => width && `width: ${width}`}
`;

const DropdownField = styled.div`
  height: 40px;
  border: none;
  border-bottom: 1px solid #707070;
`;

const DescriptionField = styled.div`
  height: 20px;
  width: 100%;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
`;

const Dropdown = styled.select`
  color: #707070;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 20px;
  font-family: 'Montserrat';
  outline: none;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Description = styled.p<any>`
  color: #707070;
  font-size: 15px;
  font-family: 'Montserrat';

  ${({ login }) =>
    login &&
    `
    margin-left: auto
    color: #007BFF;
    &:hover {
      cursor: pointer;
    }
`};
`;

/** Page Content */
const DropdownElement = (props) => {
  const { name, title, value, options, onChange, width, fontSize } = props;
  return (
    <Container width={width}>
      <DropdownField>
        <Dropdown
          style={{ fontSize: fontSize }}
          name={name}
          onChange={onChange}
        >
          <option key={0} value={0}>
            nicht definiert
          </option>
          {options.map((option, index) => {
            return (
              <option
                key={`year${index}`}
                value={option.value}
                selected={value === option.value}
              >
                {option.label}
              </option>
            );
          })}
        </Dropdown>
      </DropdownField>
      <DescriptionField>
        <Description>{title || '-'}</Description>
      </DescriptionField>
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  DropdownElement
);
