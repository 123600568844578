import { Component } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoPopover from '../../../../../../components/InfoPopover';
import { Button } from 'reactstrap';
import { NumberInput, TextInput } from '../Input';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  @media (min-width: 760px) {
    flex-direction: row;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
  color: #707070;
  font-family: Montserrat;
  padding-top: 35px;
  width: auto;
  max-width: 100%;
  margin-bottom: 25px;
`;

const Description = styled.div`
  color: #707070;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
`;

const InputWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  display: flex;
  max-width: 100px;
  @media (min-width: 760px) {
    margin-left: auto;
  }
  @media (max-width: 760px) {
    margin: auto;
  }
  margin-top: -7.25px;
`;

const UuidDescription = styled.div`
  color: #707070;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  padding-right: 175px;
`;

const UuidInputWrapper = styled.div`
  width: 325px;
  margin-left: 50px;
  margin-top: -7.25px;
`;

const Unit = styled.p`
  display: flex;
  margin-left: 5px;
  margin-top: 7.25px;
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const TopButton = styled(Button)`
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const SaveChangesButton = styled<any>(TopButton)`
  background-color: #ea6000;
  border-color: #ea6000;

  margin-top: 35px;
  margin-left: auto;
  width: 150px;
`;

interface IConfigurationRow {
  id: string;
  heading: string;
  value: string | number;
  onChange: (value: number | string) => void;
  onBlur: (value: number | string) => void;
  isNumber?: boolean;
  unit?: string;
  info?: any;
}

const ConfigurationRow = ({
  id,
  heading,
  value,
  onChange,
  onBlur,
  unit = '',
  isNumber = false,
  info = null
}: IConfigurationRow) => (
  <Row>
    {isNumber ? (
      <>
        <Description>
          {heading}
          {info && (
            <InfoPopover id={id} title={heading}>
              {info}
            </InfoPopover>
          )}
        </Description>
        <InputWrapper>
          <NumberInput
            name={heading}
            value={value}
            onChange={(e) => {
              if (typeof e.target.value !== 'undefined') {
                onChange(parseInt(e.target.value, 10));
              }
            }}
            onBlur={(e) => {
              if (typeof e.target.value !== 'undefined') {
                onBlur(parseInt(e.target.value, 10));
              }
            }}
          />
          <Unit>{unit}</Unit>
        </InputWrapper>
      </>
    ) : (
      <>
        <UuidDescription>
          {heading}
          {info && (
            <InfoPopover id={id} title={heading}>
              {info}
            </InfoPopover>
          )}
        </UuidDescription>
        <UuidInputWrapper>
          <TextInput
            name={heading}
            value={value}
            onChange={(e) => {
              if (typeof e.target.value !== 'undefined') {
                onChange(e.target.value);
              }
            }}
            onBlur={(e) => {
              if (typeof e.target.value !== 'undefined') {
                onBlur(e.target.value);
              }
            }}
          />
          <Unit>{unit}</Unit>
        </UuidInputWrapper>
      </>
    )}
  </Row>
);

const limitValue = (
  value: number | string,
  max: number,
  std: number,
  min: number
): number => {
  const v = typeof value === 'string' ? parseInt(value, 10) : value;
  if (v > max) return max;
  if (v <= min) return min;
  if (!v) return std;
  return v;
};

/** Page Content */
class BluetoothSettings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  render() {
    const { bluetoothSettings, edit, save } = this.props;

    return (
      <Div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>BLE Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ConfigurationRow
                id="scanTime"
                heading={'Scan Time'}
                value={bluetoothSettings.scanTime}
                onBlur={(value) =>
                  edit('scanTime', limitValue(value, 255, 6, 0))
                }
                onChange={(value) => edit('scanTime', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>Aktive Scan Zeit</PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 (Scan deaktiviert) </PopoverLine>
                    <PopoverLine>Standard: 6s </PopoverLine>
                    <PopoverLine>Maximum: 255s </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="scanningCycle"
                heading={'Scanning Cycle Pause'}
                value={bluetoothSettings.scanningCycle}
                onBlur={(value) =>
                  edit('scanningCycle', limitValue(value, 255, 24, 0))
                }
                onChange={(value) => edit('scanningCycle', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeit zwischen dem Ende und dem Beginn einer aktiven Scan
                      Zeit.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0s </PopoverLine>
                    <PopoverLine>Standard: 24s </PopoverLine>
                    <PopoverLine>Maximum: 255s </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="minimalRssi"
                heading={'Minimal RSSI'}
                value={bluetoothSettings.minimalRssi}
                onBlur={(value) =>
                  edit('minimalRssi', limitValue(value, 255, 0, 0))
                }
                onChange={(value) => edit('minimalRssi', value)}
                unit={'dbm[coded]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Minimale Signalstärke ab welcher Geräte als gefunden
                      gelten.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Standard: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Maximum: 255dbm[coded] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="timeSlot"
                heading={'Time Slot'}
                value={bluetoothSettings.timeSlot}
                onBlur={(value) =>
                  edit('timeSlot', limitValue(value, 65536, 300, 30))
                }
                onChange={(value) => edit('timeSlot', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeitliche Auflösung der Lokalisation durch Beacon
                      Zuordnung.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 30s </PopoverLine>
                    <PopoverLine>Standard: 300s </PopoverLine>
                    <PopoverLine>Maximum: 65536s </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="advInterval"
                heading={'Advertising Interval'}
                value={bluetoothSettings.advInterval}
                onBlur={(value) =>
                  edit('advInterval', limitValue(value, 255, 10, 0))
                }
                onChange={(value) => edit('advInterval', value)}
                unit={'[100ms]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeitabstand zwischen dem Senden von Advertising Signalen.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0[100ms] </PopoverLine>
                    <PopoverLine>Standard: 10[100ms] </PopoverLine>
                    <PopoverLine>Maximum: 255[100ms] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="advTxPower"
                heading={'Advertising TX Power'}
                value={bluetoothSettings.advTxPower}
                onBlur={(value) =>
                  edit('advTxPower', limitValue(value, 255, 0, 0))
                }
                onChange={(value) => edit('advTxPower', value)}
                unit={'dbm[coded]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>Signalstärke für das Advertising.</PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Standard: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Maximum: 255dbm[coded] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="advTimeout"
                heading={'Advertising Timeout'}
                value={bluetoothSettings.advTimeout}
                onBlur={(value) =>
                  edit('advTimeout', limitValue(value, 255, 30, 0))
                }
                onChange={(value) => edit('advTimeout', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Dauer des Advertising. Advertising Signale werden für die
                      hier definierte Dauer in der oben eingestellten (Interval)
                      Häufigkeit versendet.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 0s (Advertising ist immer an){' '}
                    </PopoverLine>
                    <PopoverLine>Standard: 30s </PopoverLine>
                    <PopoverLine>Maximum: 255s </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="advCompanyId"
                heading={'Advertising Company ID'}
                value={bluetoothSettings.advCompanyId}
                onBlur={(value) =>
                  edit('advCompanyId', limitValue(value, 65535, 0, 0))
                }
                onChange={(value) => edit('advCompanyId', value)}
                isNumber
                info={
                  <div>
                    <PopoverLine>Company Identifier</PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 </PopoverLine>
                    <PopoverLine>Standard: 0 </PopoverLine>
                    <PopoverLine>Maximum: 65535 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="advDeviceId"
                heading={'Advertising Device ID'}
                value={bluetoothSettings.advDeviceId}
                onBlur={(value) =>
                  edit('advDeviceId', limitValue(value, 65535, 0, 0))
                }
                onChange={(value) => edit('advDeviceId', value)}
                isNumber
                info={
                  <div>
                    <PopoverLine>Device Identifier</PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 </PopoverLine>
                    <PopoverLine>Standard: 0 </PopoverLine>
                    <PopoverLine>Maximum: 65535 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="beaconInterval"
                heading={'Beacon Interval'}
                value={bluetoothSettings.beaconInterval}
                onBlur={(value) =>
                  edit('beaconInterval', limitValue(value, 255, 5, 0))
                }
                onChange={(value) => edit('beaconInterval', value)}
                unit={'[100ms]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Advertising interval im Beacon Modus.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 0[100ms] (Beacon inaktiv){' '}
                    </PopoverLine>
                    <PopoverLine>Standard: 5[100ms] </PopoverLine>
                    <PopoverLine>Maximum: 255[100ms] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="beaconTxPower"
                heading={'Beacon TX Power'}
                value={bluetoothSettings.beaconTxPower}
                onBlur={(value) =>
                  edit('beaconTxPower', limitValue(value, 255, 0, 0))
                }
                onChange={(value) => edit('beaconTxPower', value)}
                unit={'dbm[coded]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Signalstärke für das Advertising im Beacon Modus.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Standard: 0dbm[coded] </PopoverLine>
                    <PopoverLine>Maximum: 255dbm[coded] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="beaconMajor"
                heading={'Beacon Major'}
                value={bluetoothSettings.beaconMajor}
                onBlur={(value) =>
                  edit('beaconMajor', limitValue(value, 65535, 1, 0))
                }
                onChange={(value) => edit('beaconMajor', value)}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Beacon Major Identifier
                      <br />
                      Dieser zugewiesene Wert dient der Identifikation sowie
                      Gruppierung von Beacons.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 </PopoverLine>
                    <PopoverLine>Standard: 1 </PopoverLine>
                    <PopoverLine>Maximum: 65535 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="beaconMinor"
                heading={'Beacon Minor'}
                value={bluetoothSettings.beaconMinor}
                onBlur={(value) =>
                  edit('beaconMinor', limitValue(value, 65535, 1, 0))
                }
                onChange={(value) => edit('beaconMinor', value)}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Beacon Minor Identifier
                      <br />
                      Dieser zugewiesene Wert dient der Identifikation sowie
                      Gruppierung von Beacons.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 </PopoverLine>
                    <PopoverLine>Standard: 1 </PopoverLine>
                    <PopoverLine>Maximum: 65535 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="masterConnectionInterval"
                heading={'Master Conntecion Interval'}
                value={bluetoothSettings.masterConnectionInterval}
                onBlur={(value) =>
                  edit(
                    'masterConnectionInterval',
                    limitValue(value, 3200, 6, 6)
                  )
                }
                onChange={(value) => edit('masterConnectionInterval', value)}
                unit={'[*1,25ms]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeit zwischen zwei Connection Events.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 6[*1,25ms] </PopoverLine>
                    <PopoverLine>Standard: 6[*1,25ms] </PopoverLine>
                    <PopoverLine>Maximum: 3200[*1,25ms] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="masterSlaveLatency"
                heading={'Master Slave Latency'}
                value={bluetoothSettings.masterSlaveLatency}
                onBlur={(value) =>
                  edit('masterSlaveLatency', limitValue(value, 499, 499, 0))
                }
                onChange={(value) => edit('masterSlaveLatency', value)}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Definiert, wie viele Connection Intervalle von einem Slave
                      übersprungen werden können um Energie zu sparen.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0 </PopoverLine>
                    <PopoverLine>Standard: 499 </PopoverLine>
                    <PopoverLine>Maximum: 499 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="masterSupervisionTimeout"
                heading={'Master Supervision Timeout'}
                value={bluetoothSettings.masterSupervisionTimeout}
                onBlur={(value) =>
                  edit(
                    'masterSupervisionTimeout',
                    limitValue(value, 3200, 376, 10)
                  )
                }
                onChange={(value) => edit('masterSupervisionTimeout', value)}
                unit={'[*10ms]'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Maximale Zeit zwischen zwei erfolgreichen Connection
                      Intervallen. Bei überschreitung gilt die Verbindung als
                      verloren.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10[*10ms] </PopoverLine>
                    <PopoverLine>Standard: 376[*10ms] </PopoverLine>
                    <PopoverLine>Maximum: 3200[*10ms] </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="uuid"
                heading={'UUID'}
                value={bluetoothSettings.uuid}
                onBlur={() => {}}
                onChange={(value) => edit('uuid', value)}
                isNumber={false}
                info={
                  <div>
                    <PopoverLine>
                      [128bit=16 Bytes=32 hex characters]
                      <br />
                      Diese UUID wird sowohl für das Scannen nach Beacons als
                      auch für das Advertising im Beacon Modus verwendet.
                    </PopoverLine>
                  </div>
                }
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <SaveChangesButton onClick={() => save()}>Speichern</SaveChangesButton>
      </Div>
    );
  }
}

export default BluetoothSettings;
