import { C8yBaseService } from './C8yBaseService';
import { ButtonService } from './ButtonService';
import { ButtonInfoService } from './ButtonInfoService';
export interface Device {
  id: string;
  name: string;
  uuid: string;
  location: { lng: number; lat: number };
}

export interface DeviceList {
  totalPages: number;
  currentPage: number;
  devices: Device[];
}
/**
 * Fetches cumulocity devices
 */
export class DeviceService extends C8yBaseService {
  // /**
  //  * Fetch devices from cumulocity
  //  * @returns devices
  //  */
  protected buttonService = new ButtonService();

  async getDevices(
    fetchedEntries: Array<Device> = [],
    page: number = 1
  ): Promise<Array<Device>> {
    const pageSize = 50;
    const url = `${this.baseUrl}/inventory/managedObjects?fragmentType=c8y_IsDevice&pageSize=${pageSize}&currentPage=${page}`;

    const Authorization = await this.getAuthHeader();
    const res = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        Authorization,
        UseXBasic: true,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer' // no-referrer, *client
    });

    const json = await res.json();

    if (json.error) {
      throw new Error(json.error);
    }

    const deviceDataHasura = await this.buttonService.getButtons(
      json.managedObjects.map((o) => o.id)
    );

    for (let key in deviceDataHasura) {
      // eslint-disable-next-line
      const ind = json.managedObjects.findIndex((o) => o.id == key);

      json.managedObjects[ind] = {
        ...json.managedObjects[ind],
        ...deviceDataHasura[key]
      };
    }

    let data = [...fetchedEntries, ...json.managedObjects];

    if (
      typeof json.next !== 'undefined' &&
      json.managedObjects.length >= pageSize
    ) {
      return this.getDevices(data, page + 1);
    }

    return data;
  }

  async requestButtonInfo(deviceId: string) {
    const buttonInfoService = new ButtonInfoService();
    return await buttonInfoService.getDeviceInfo(deviceId);
  }

  // /**
  //  * Fetch device from cumulocity
  //  * @returns devices
  //  */
  async getDevice(id: string): Promise<Device> {
    const url = `${this.baseUrl}/inventory/managedObjects/${id}`;

    const Authorization = await this.getAuthHeader();
    const res = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        Authorization,
        UseXBasic: true,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer' // no-referrer, *client
    });

    let json = await res.json();

    if (json.error) {
      throw new Error(json.error);
    }

    try {
      const buttonInfo = await this.requestButtonInfo(json.id);

      json = {
        ...json,
        ...buttonInfo
      };
    } catch {
      console.error(`Could not load order and owner for button ${json.id}`);
    }

    return json;
  }

  async getFragment(deviceId: string, fragment: string): Promise<any> {
    try {
      const device = await this.getDevice(deviceId);

      // TODO Define list of fragments
      // @ts-ignore
      if (device && device[fragment]) {
        // @ts-ignore
        return device[fragment];
      }
    } catch (e) {}

    return null;
  }

  async updateFragment(
    deviceId: string,
    fragmentName: string,
    value: any
  ): Promise<boolean> {
    const url = `${this.baseUrl}/inventory/managedObjects/${deviceId}`;

    const readOnlyFragments = [
      'c8y_Configuration',
      'secufy_ThingstreamButtonConfiguration'
    ];

    if (
      readOnlyFragments.some((_fragmentName) => _fragmentName === fragmentName)
    ) {
      throw new Error(`Do not modify read only fragments! (${fragmentName})`);
    }

    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({
        [fragmentName]: value
      })
    });

    return response.status === 200;
  }

  async bulkUpdateFragments(
    deviceIds: string[],
    fragmentName: string,
    value: any
  ): Promise<boolean> {
    const promiseList = deviceIds.map((deviceId) =>
      this.updateFragment(deviceId, fragmentName, value)
    );

    return Promise.all(promiseList).then((responses) =>
      responses.every((success) => success)
    );
  }
}
