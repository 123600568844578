import { createAction } from 'redux-actions';
import types from '../types';

export const openAvatarEditor = createAction(types.OPEN_AVATAR_EDITOR);
export const closeAvatarEditor = createAction(types.CLOSE_AVATAR_EDITOR);
export const uploadAvatar = createAction(types.UPLOAD_AVATAR);
export const uploadAvatarSucceeded = createAction(
  types.UPLOAD_AVATAR_SUCCEEDED
);
export const uploadAvatarFailed = createAction(types.UPLOAD_AVATAR_FAILED);

export const deleteAvatar = createAction(types.DELETE_AVATAR);
export const deleteAvatarSucceeded = createAction(
  types.DELETE_AVATAR_SUCCEEDED
);
export const deleteAvatarFailed = createAction(types.DELETE_AVATAR_FAILED);
