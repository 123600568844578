import { Component } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import LoginPanel from './components/ResetPanel';

// const FullPageDiv = styled(Div100Vh)`
const Page = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

/** Page Content */
class LoginPage extends Component<any, any> {
  render() {
    return (
      <Page>
        <LoginPanel></LoginPanel>
      </Page>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(LoginPage);
