import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  userName: '',
  ownerEmail: '',
  batteryWarningRecipients: [],
  edited: false,
  wearerName: '',
  wearerEmail: ''
};

const editSettings = (state, { payload = {} }) => ({
  ...state,
  //@ts-ignore
  [payload.scope]: payload.value,
  edited: true
});

export default handleActions(
  {
    [types.REQUEST_CONFIG_SUCCEEDED]: (state, { payload = {} }) => {
      return {
        //@ts-ignore
        wearerName: payload.wearerName || '',
        //@ts-ignore
        wearerEmail: payload.wearerEmail || '',
        //@ts-ignore
        userName: payload.userName || '',
        //@ts-ignore
        ownerEmail: payload.ownerEmail || '',
        //@ts-ignore
        batteryWarningRecipients: payload.batteryWarningRecipients || [],
        edited: false
      };
    },
    [types.EDIT_GENERAL_SETTINGS]: editSettings,
    [types.EDIT_AND_SAVE_GENERAL_SETTINGS]: editSettings,
    [types.SAVE_GENERAL_SETTINGS]: (state, { payload }) => ({
      ...state,
      edited: false
    })
  },
  initialState
);
