// @ts-nocheck
import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {};

export default handleActions(
  {
    [types.REQUEST_BEACONS_SUCCEEDED]: (state, action: any) => {
      return {
        ...state,
        [action.payload.deviceId]: action.payload.beacons
      };
    },

    [types.UPDATE_BEACON_SUCCEEDED]: (state, { payload }) => {
      let newState = state;
      Object.keys(state).forEach((deviceId) => {
        const index = state[deviceId].findIndex(
          (beacon) =>
            payload.major === beacon.major && payload.minor === beacon.minor
        );
        if (index >= 0) {
          newState = {
            ...newState
          };
          newState[deviceId][index] = { ...state[deviceId][index], ...payload };
        }
      });

      return newState;
    },

    [types.UPDATE_ADDRESS_ALL_BEACONS_SUCCEEDED]: (state, action: any) => {
      return {
        [action.payload.deviceId]: [
          ...action.payload.beacons.map((beacon) => ({
            ...beacon
          }))
        ]
      };
    }
  },

  initialState
);
