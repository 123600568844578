export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

export const CONFIRM_OWNERSHIP = 'CONFIRM_OWNERSHIP';
export const CONFIRM_OWNERSHIP_SUCCEEDED = 'CONFIRM_OWNERSHIP_SUCCEEDED';
export const CONFIRM_OWNERSHIP_FAILED = 'CONFIRM_OWNERSHIP_FAILED';

const TYPES = {
  CONFIRM_OWNERSHIP,
  CONFIRM_OWNERSHIP_SUCCEEDED,
  CONFIRM_OWNERSHIP_FAILED,
};

export default TYPES;
