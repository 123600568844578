type RefetchObject = {
  id: string;
  func: () => void;
  // func: (...args: [any]) => void;
  // args?: [any];
};
export class RefetchService {
  private static _instance: RefetchService;

  private running: boolean = false;
  private refetchObjects: RefetchObject[] = [];

  public static get instance() {
    if (!this._instance) {
      this._instance = new RefetchService();
    }

    return this._instance;
  }

  public start() {
    if (this.running) return;

    this.running = true;
    this.fetch();
  }

  public stop() {
    this.running = false;
  }

  public addRefetchFunction(refetchObject: RefetchObject) {

    this.removeRefetchFunction(refetchObject.id);
    this.refetchObjects.push(refetchObject);
  }

  public removeRefetchFunction(id: string) {
    //console.log('Removing refetch function:', id);
    this.refetchObjects = this.refetchObjects.filter((obj) => obj.id !== id);
  }

  private async fetch() {
    if (this.running) {
      try {
//        console.log('Refetching objects');
        await Promise.allSettled(
          this.refetchObjects.map((obj) => {
            // const args = obj.args || ([] as unknown as [any]);
            // return obj.func(...args);
            return obj.func();
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(this.fetch.bind(this), 10000);
      }
    }
  }
}
