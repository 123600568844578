// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  open: false,
  userId: '',
  role: '',
  phoneNumber: '',
  phoneNumber2: '',
  countryCode: '',
  countryCode2: '',
  smsEnabled: false,
  smsEnabled2: false,
  emailEnabled: false,
  voicemailEnabled: false,
  voicemailEnabled2: false,
  approved: false,
  receiveAlarms: true,
  firstName: '',
  lastName: '',
  yearOfBirth: 0,
  gender: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    passwordSet: false,
    yearOfBirth: 0,
    gender: ''
  },
  device: { c8yId: '', id: '' }
};

export default handleActions(
  {
    [types.TOGGLE_DELETE_CONFIRMATION_MODAL]: (state, action) => ({
      ...state,
      deleteConfirmationModalOpen: !state.deleteConfirmationModalOpen
    }),

    [types.RECIPIENT_START_EDITING]: (state, { payload = {} }) => {
      //@ts-ignore
      const {
        userId,
        user = {},
        device = {},
        smsEnabled = false,
        smsEnabled2 = false,
        emailEnabled = false,
        voicemailEnabled = false,
        voicemailEnabled2 = false,
        role = 'helper',
        approved = false,
        receiveAlarms = true,
        firstName,
        lastName,
        yearOfBirth,
        gender
      } = payload;

      return {
        open: true,
        userId,
        role,
        phoneNumber: payload?.phoneNumber || '',
        phoneNumber2: payload?.phoneNumber2 || '',
        countryCode: payload?.countryCode || 'DE',
        countryCode2: payload?.countryCode2 || 'DE',
        smsEnabled,
        smsEnabled2,
        emailEnabled,
        voicemailEnabled,
        voicemailEnabled2,
        approved,
        receiveAlarms,
        firstName,
        lastName,
        yearOfBirth,
        gender,
        user: {
          // firstname & lastname here are fallback values, in case data is not set inside the recipient
          id: user?.id || '',
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          passwordSet: user?.passwordSet || false,
          yearOfBirth: user?.yearOfBirth || 0,
          gender: user?.gender || ''
        },
        device: { c8yId: device.c8yId || '', id: device.id || '' }
      };
    },
    [types.RECIPIENT_CANCEL_EDITING]: (state, { payload }) => initialState,
    [types.EDIT_RECIPIENT]: (state, { payload }) => {
      const { scope, value } = payload;

      switch (scope) {
        case 'user.email':
          state.user = { ...state.user, email: value };
          break;
        default:
          state[scope] = value;
      }

      return {
        ...state
      };
    },
    [types.CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED]: (state, { payload }) =>
      initialState,
    [types.CREATE_OR_UPDATE_RECIPIENT_FAILED]: (state, { payload }) =>
      initialState,
    [types.DELETE_RECIPIENT]: (state, { payload }) => initialState
  },
  initialState
);
