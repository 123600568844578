import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import VerifyButtonOwnership from '..';
import { confirmOwnership } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  verifying: state.verifyButtonOwnership.verifying,
  verified: state.verifyButtonOwnership.verified,
  verifyFailed: state.verifyButtonOwnership.verifyFailed
});

const mapDispatchToProps = (dispatch) => ({
  confirmOwnership: (token) => dispatch(confirmOwnership(token))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyButtonOwnership)
);
