import { put, takeEvery, call } from 'redux-saga/effects';
import types from '../types';
import {
  requestActivitySucceeded,
  requestActivityFailed,
  requestAlarmSucceeded,
  requestAlarmFailed,
  acknowledgeAlarmSucceeded,
  acknowledgeAlarmFailed,
  acceptAlarmSucceeded,
  acceptAlarmFailed,
  requestAlarmEvents,
  alarmCloseFailed,
  alarmCloseSucceeded
} from '../actions';
import { ActivityService } from '../../../services/ActivityService';
import { AlarmService } from '../../../services/AlarmService';

function* fetchActivity(action) {
  try {
    const { deviceId } = action.payload;
    const activityService = new ActivityService();

    const activity: [number, number] = yield call(
      activityService.getTodayUsage.bind(activityService),
      deviceId
    );

    yield put(requestActivitySucceeded({ deviceId, activity }));
  } catch (error) {
    console.log(error);
    yield put(requestActivityFailed());
  }
}

function* fetchAlarms(action) {
  try {
    const { deviceId } = action.payload;
    const alarmService = new AlarmService();

    const alarm = yield call(
      alarmService.getAlarms.bind(alarmService),
      deviceId
    );
    yield put(requestAlarmSucceeded({ deviceId, alarm }));
  } catch (error) {
    console.log(error);
    yield put(requestAlarmFailed());
  }
}

function* acknowledgeAlarm(action) {
  try {
    const { deviceId, alarmId } = action.payload;
    const alarmService = new AlarmService();

    const acknowledged: boolean = yield call(
      alarmService.acknowledgeAlarm.bind(alarmService),
      alarmId
    );
    yield put(acknowledgeAlarmSucceeded({ deviceId, alarmId, acknowledged }));
  } catch (error) {
    console.log(error);
    yield put(acknowledgeAlarmFailed());
  }
}

function* acceptAlarm(action) {
  try {
    const { alarmId, deviceId } = action.payload;
    const alarmService = new AlarmService();

    yield call(alarmService.acceptAlarm.bind(alarmService), alarmId);
    yield put(acceptAlarmSucceeded({ alarmId }));
    yield put(requestAlarmEvents(deviceId));
  } catch (error) {
    console.log(error);
    yield put(acceptAlarmFailed());
  }
}

function* alarmClose(action) {
  try {
    const { alarmId, alarmReason, optionalText, deviceId } = action.payload;
    const alarmService = new AlarmService();

    yield call(
      alarmService.closeAlarm.bind(alarmService),
      alarmId,
      alarmReason,
      optionalText
    );
    yield put(alarmCloseSucceeded({ alarmId, deviceId }));
  } catch (error) {
    console.log(error);
    yield put(alarmCloseFailed());
  }
}

const sagas = [
  takeEvery(types.REQUEST_ACTIVITY, fetchActivity),
  takeEvery(types.REQUEST_ALARM, fetchAlarms),
  takeEvery(types.ACKNOWLEDGE_ALARM, acknowledgeAlarm),
  takeEvery(types.ACCEPT_ALARM, acceptAlarm),
  takeEvery(types.ALARM_CLOSE, alarmClose)
];

export default sagas;
