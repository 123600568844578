import styled from 'styled-components';

const TextP = styled.p<any>`
  margin-bottom: 0px;
  color: #2d3436;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ textStyle = {} }) => `
  font-family: ${textStyle.bold ? 'Montserrat Bold;' : 'Montserrat;'}
  font-size: ${textStyle.bold ? '14px;' : '16px;'}  
    ${textStyle.color && `color: ${textStyle.color}`}}    
  `}
`;

const Text = ({ children, textStyle }) => (
  <TextP textStyle={textStyle}>{children}</TextP>
);

export default Text;
