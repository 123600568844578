import { useState, useRef, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import Editor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';

declare var imageCompression: any;

const Wrapper = styled.div<any>`
  width: 300px;
  //   height: 300px;
  padding: 20px;
`;

const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const DropzoneText = styled.p`
  margin: auto;
  text-align: center;
`;

export interface AvatarEditorProps {
  isOpen: boolean;
  isUploading: boolean;
  close: () => void;
  upload: (blob: Blob) => void;
}

const AvatarEditor = (props: AvatarEditorProps) => {
  const { close, upload, isOpen, isUploading } = props;

  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setImage(null);
    }
  }, [isOpen]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (dropped) => {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true
        };
        const compressedImage = await imageCompression(dropped[0], options);
        const compressedFile = new File([compressedImage], dropped[0].name);
        setImage(compressedFile);
      } catch (e) {
        console.log(
          "Couldn't compress image, using uncompressed image instead!",
          e
        );
        setImage(dropped[0]);
      }
    },
    accept: 'image/jpeg, image/png'
  });

  const [scale, setScale] = useState<number>(50);

  const handleScaleChange = (event: any, newValue: number | number[]) => {
    setScale(newValue as number);
  };

  const [rotation, setRotation] = useState<number>(50);

  const handleRotationChange = (event: any, newValue: number | number[]) => {
    setRotation(newValue as number);
  };

  const limitScale = (scale: number) => {
    return 0.012 * scale + 0.4;
  };

  const editor = useRef<Editor>(null);

  const onSave = () => {
    if (editor !== null && editor.current !== null) {
      const canvas = editor.current.getImage();
      canvas.toBlob(async (blob: Blob | null) => {
        if (blob === null) return;
        upload(blob);
      });
    }
  };

  return (
    <Dialog onClose={close} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle id="simple-dialog-title">Profilbild hinzufügen</DialogTitle>
      <Wrapper className="dropzone-wrapper">
        {!image && (
          <Dropzone {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <DropzoneText>
              Ziehe ein Bild hierher, oder klicke um eines auszuwählen
            </DropzoneText>
          </Dropzone>
        )}
        {image && (
          <div>
            <Editor
              ref={editor}
              className="avatar-editor"
              width={260}
              height={260}
              image={image}
              borderRadius={125}
              scale={limitScale(scale)}
              rotate={(rotation - 50) * 3.6}
              color={[255, 255, 255, 0.6]}
              border={0}
            />
            <Typography id="continuous-slider" gutterBottom>
              Zoom
            </Typography>
            <Slider
              value={scale}
              onChange={handleScaleChange}
              aria-labelledby="continuous-slider"
            />
            <Typography id="continuous-slider" gutterBottom>
              Rotation
            </Typography>
            <Slider
              value={rotation}
              onChange={handleRotationChange}
              aria-labelledby="continuous-slider"
            />
            {!isUploading && (
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            )}
            {isUploading && <LinearProgress />}
          </div>
        )}
      </Wrapper>
    </Dialog>
  );
};

export default AvatarEditor;
