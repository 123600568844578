// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  open: false,
  alarmReason: '',
  optionalText: '',
  alarmId: '',
  severity: '',
  trigger: '',
  deviceId: ''
};

export default handleActions(
  {
    [types.ALARM_START_CLOSE]: (state, { payload = {} }) => {
      //@ts-ignore
      const {
        alarmReason,
        optionalText,
        alarmId,
        severity,
        deviceId,
        trigger
      } = payload;

      return {
        open: true,
        alarmReason: alarmReason || '',
        optionalText: optionalText || '',
        alarmId: alarmId || '',
        severity: severity || '',
        trigger: trigger || '',
        deviceId: deviceId || ''
      };
    },
    [types.ALARM_CANCEL_CLOSE]: (state, { payload }) => initialState,
    [types.ALARM_CLOSE_EDIT]: (state, { payload }) => {
      const { scope, value } = payload;

      if (scope === 'alarmReason') {
        state.alarmReason = value;
      }

      if (scope === 'optionalText') {
        state.optionalText = value;
      }

      return {
        ...state
      };
    },
    [types.ALARM_CLOSE_SUCCEEDED]: () => initialState,
    [types.ALARM_CLOSE_FAILED]: () => initialState
  },
  initialState
);
