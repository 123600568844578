import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import BeaconSettings from '..';


import {
  requestBeacons,
} from '../../../actions';
import { getBeacons } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  beacons: getBeacons(state, ownProps),
  device: state.device,
});

const mapDispatchToProps = (dispatch) => ({
  requestBeacons: (id) => dispatch(requestBeacons(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BeaconSettings)
);
