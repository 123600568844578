import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Overview from '..';
import { refetchedDeviceList } from '../../Device/components/HelperOverview/actions';
import {
  autoAcceptAlarm,
  toggleAddButtonModal,
  toggleListView
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
  showTileView: state.overview.showTileView,
  addButtonModalOpen: state.overview.addButtonModalOpen
});

const mapDispatchToProps = (dispatch) => ({
  toggleListView: () => dispatch(toggleListView()),
  toggleAddButtonModal: () => dispatch(toggleAddButtonModal()),
  acceptAlarm: (alarmId) => dispatch(autoAcceptAlarm(alarmId)),
  refetchedDeviceList: (deviceList) => dispatch(refetchedDeviceList(deviceList)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
