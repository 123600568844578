export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCEEDED = 'CONFIRM_EMAIL_SUCCEEDED';
export const CONFIRM_EMAIL_FAILED = 'CONFIRM_EMAIL_FAILED';
export const CONFIRM_EMAIL_TOKEN_EXPIRED_EMAIL_RESENT =
  'CONFIRM_EMAIL_TOKEN_EXPIRED_EMAIL_RESENT';

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCEEDED = 'RESEND_EMAIL_SUCCEEDED';
export const RESEND_EMAIL_FAILED = 'RESEND_EMAIL_FAILED';

const TYPES = {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCEEDED,
  CONFIRM_EMAIL_FAILED,
  CONFIRM_EMAIL_TOKEN_EXPIRED_EMAIL_RESENT,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCEEDED,
  RESEND_EMAIL_FAILED
};

export default TYPES;
