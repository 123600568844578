import { createAction } from 'redux-actions';
import types from '../types';

export const resetPassword = createAction(types.RESET_PASSWORD);
export const resetPasswordSucceeded = createAction(
  types.RESET_PASSWORD_SUCCEDED
);
export const resetPasswordFailed = createAction(types.RESET_PASSWORD_FAILED);

export const setPassword = createAction(types.SET_PASSWORD);
export const setPasswordSucceeded = createAction(types.SET_PASSWORD_SUCCEDED);
export const setPasswordFailed = createAction(types.SET_PASSWORD_FAILED);
