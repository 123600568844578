export const TOGGLE_DELETE_CONFIRMATION_MODAL =
  'TOGGLE_DELETE_CONFIRMATION_MODAL';

export const REQUEST_CONFIG = 'REQUEST_CONFIG';
export const REQUEST_CONFIG_SUCCEEDED = 'REQUEST_CONFIG_SUCCEEDED';
export const REQUEST_CONFIG_FAILED = 'REQUEST_CONFIG_FAILED';

export const REQUEST_RECIPIENTS = 'REQUEST_RECIPIENTS';
export const REQUEST_RECIPIENTS_SUCCEEDED = 'REQUEST_RECIPIENTS_SUCCEEDED';
export const REQUEST_RECIPIENTS_FAILED = 'REQUEST_RECIPIENTS_FAILED';

export const REQUEST_BEACONS = 'REQUEST_BEACONS';
export const REQUEST_BEACONS_SUCCEEDED = 'REQUEST_BEACONS_SUCCEEDED';
export const REQUEST_BEACONS_FAILED = 'REQUEST_BEACONS_FAILED';

export const REQUEST_BUTTON_INFO = 'REQUEST_BUTTON_INFO';
export const REQUEST_OWNER_SUCCEEDED = 'REQUEST_OWNER_SUCCEEDED';
export const REQUEST_OWNER_FAILED = 'REQUEST_OWNER_FAILED';
export const REQUEST_ORDER_SUCCEEDED = 'REQUEST_ORDER_SUCCEEDED';
export const REQUEST_ORDER_FAILED = 'REQUEST_ORDER_FAILED';

export const REQUEST_HARDWARE_INFO = 'REQUEST_HARDWARE_INFO';
export const REQUEST_HARDWARE_INFO_SUCCEEDED =
  'REQUEST_HARDWARE_INFO_SUCCEEDED';
export const REQUEST_HARDWARE_INFO_FAILED = 'REQUEST_HARDWARE_INFO_FAILED';

export const RECIPIENT_START_EDITING = 'RECIPIENT_START_EDITING';
export const RECIPIENT_CANCEL_EDITING = 'RECIPIENT_CANCEL_EDITING';
export const EDIT_RECIPIENT = 'EDIT_RECIPIENT';

export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_SUCCEEDED = 'GET_DEVICE_SUCCEEDED';
export const GET_DEVICE_FAILED = 'GET_DEVICE_FAILED';
export const SELECT_DEVICE_PAGE = 'SELECT_DEVICE_PAGE';
export const SORT_DEVICES = 'SORT_DEVICES';

export const SAVE_GENERAL_SETTINGS = 'UPDATE_USERNAME';
export const EDIT_GENERAL_SETTINGS = 'EDIT_GENERAL_SETTINGS';
export const EDIT_AND_SAVE_GENERAL_SETTINGS = 'EDIT_AND_SAVE_GENERAL_SETTINGS';
export const UPDATE_USERNAME_SUCCEEDED = 'UPDATE_USERNAME_SUCCEEDED';
export const UPDATE_USERNAME_FAILED = 'UPDATE_USERNAME_FAILED';

export const SAVE_CUSTOM_MESSAGES = 'SAVE_CUSTOM_MESSAGES';
export const EDIT_CUSTOM_MESSAGES = 'EDIT_CUSTOM_MESSAGES';

export const EDIT_EMERGENCY_PLANS = 'EDIT_EMERGENCY_PLANS';
export const SAVE_EMERGENCY_PLANS = 'SAVE_EMERGENCY_PLANS';

export const EDIT_BUTTON_SETTINGS = 'EDIT_BUTTON_SETTINGS';
export const SAVE_BUTTON_SETTINGS = 'SAVE_BUTTON_SETTINGS';

export const EDIT_TRACKING_SETTINGS = 'EDIT_TRACKING_SETTINGS';
export const SAVE_TRACKING_SETTINGS = 'SAVE_TRACKING_SETTINGS';

export const EDIT_BLUETOOTH_SETTINGS = 'EDIT_BLUETOOTH_SETTINGS';
export const SAVE_BLUETOOTH_SETTINGS = 'SAVE_BLUETOOTH_SETTINGS';

export const SAVE_OWNER_EMAIL = 'UPDATE_OWNER_EMAIL';
export const EDIT_OWNER_EMAIL = 'EDIT_OWNER_EMAIL';
export const UPDATE_OWNER_EMAIL_SUCCEEDED = 'UPDATE_OWNER_EMAIL_SUCCEEDED';
export const UPDATE_OWNER_EMAIL_FAILED = 'UPDATE_OWNER_EMAIL_FAILED';

export const UPDATE_BEACON = 'UPDATE_BEACON';
export const UPDATE_BEACON_SUCCEEDED = 'UPDATE_BEACON_SUCCEEDED';
export const UPDATE_BEACON_FAILED = 'UPDATE_BEACON_FAILED';

export const UPDATE_ADDRESS_ALL_BEACONS = 'UPDATE_ADDRESS_ALL_BEACONS';
export const UPDATE_ADDRESS_ALL_BEACONS_SUCCEEDED =
  'UPDATE_ADDRESS_ALL_BEACONS_SUCCEEDED';
export const UPDATE_ADDRESS_ALL_BEACONS_FAILED =
  'UPDATE_ADDRESS_ALL_BEACONS_FAILED';

export const CREATE_OR_UPDATE_RECIPIENT = 'CREATE_OR_UPDATE_RECIPIENT';
export const CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED =
  'CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED';
export const CREATE_OR_UPDATE_RECIPIENT_FAILED =
  'CREATE_OR_UPDATE_RECIPIENT_FAILED';
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT';
export const DELETE_RECIPIENT_SUCCEEDED = 'DELETE_RECIPIENT_SUCCEEDED';
export const DELETE_RECIPIENT_FAILED = 'DELETE_RECIPIENT_FAILED';

export const REQUEST_DEVICES = 'REQUEST_DEVICES';
export const REQUEST_DEVICES_SUCCEEDED = 'REQUEST_DEVICES_SUCCEEDED';
export const REQUEST_DEVICES_FAILED = 'REQUEST_DEVICES_FAILED';
export const SELECT_DEVICE = 'SELECT_DEVICE';

export const REQUEST_EVENT = 'REQUEST_EVENT';
export const REQUEST_EVENT_SUCCEEDED = 'REQUEST_EVENT_SUCCEEDED';
export const REQUEST_EVENT_FAILED = 'REQUEST_EVENT_FAILED';

export const OPEN_SAVE_FOR_ALL_MODAL = 'OPEN_SAVE_FOR_ALL_MODAL';
export const CLOSE_SAVE_FOR_ALL_MODAL = 'CLOSE_SAVE_FOR_ALL_MODAL';
export const CHANGE_SAVE_FOR_ALL = 'CHANGE_SAVE_FOR_ALL';

export const SAVE_ALL_REQUEST_CONFIRMATION = 'SAVE_ALL_REQUEST_CONFIRMATION';
export const SAVE_ALL_CANCEL = 'SAVE_ALL_CANCEL';
export const SAVE_ALL_CONFIRM = 'SAVE_ALL_CONFIRM';
export const REQUEST_SAVE_ALL_SUCCEEDED = 'REQUEST_SAVE_ALL_SUCCEEDED';
export const REQUEST_SAVE_ALL_FAILED = 'REQUEST_SAVE_ALL_FAILED';

export const SELECT_DEVICE_PAGE_SIZE = 'SELECT_DEVICE_PAGE_SIZE';

export const SET_FIRST_ACTIVE = 'SET_FIRST_ACTIVE';

export const SET_ACTIVE_BACKEND = 'SET_ACTIVE_BACKEND';

export const SET_DEFAULT_MESSAGE = 'SET_DEFAULT_MESSAGE';

export const REFETCHED_DEVICE = 'REFETCHED_DEVICE';

export const REFETCHED_DEVICE_LIST = 'REFETCHED_DEVICE_LIST';

const TYPES = {
  TOGGLE_DELETE_CONFIRMATION_MODAL,
  REQUEST_CONFIG,
  REQUEST_CONFIG_SUCCEEDED,
  REQUEST_CONFIG_FAILED,
  REQUEST_RECIPIENTS,
  REQUEST_RECIPIENTS_SUCCEEDED,
  REQUEST_RECIPIENTS_FAILED,
  REQUEST_BUTTON_INFO,
  REQUEST_OWNER_SUCCEEDED,
  REQUEST_OWNER_FAILED,
  REQUEST_ORDER_SUCCEEDED,
  REQUEST_ORDER_FAILED,
  REQUEST_HARDWARE_INFO,
  REQUEST_HARDWARE_INFO_SUCCEEDED,
  REQUEST_HARDWARE_INFO_FAILED,
  REQUEST_BEACONS,
  REQUEST_BEACONS_SUCCEEDED,
  REQUEST_BEACONS_FAILED,
  GET_DEVICE,
  GET_DEVICE_SUCCEEDED,
  GET_DEVICE_FAILED,
  SELECT_DEVICE_PAGE,
  SORT_DEVICES,
  RECIPIENT_START_EDITING,
  RECIPIENT_CANCEL_EDITING,
  EDIT_RECIPIENT,
  EDIT_GENERAL_SETTINGS,
  SAVE_GENERAL_SETTINGS,
  EDIT_AND_SAVE_GENERAL_SETTINGS,
  EDIT_CUSTOM_MESSAGES,
  SAVE_CUSTOM_MESSAGES,
  EDIT_EMERGENCY_PLANS,
  SAVE_EMERGENCY_PLANS,
  EDIT_BUTTON_SETTINGS,
  SAVE_BUTTON_SETTINGS,
  EDIT_TRACKING_SETTINGS,
  SAVE_TRACKING_SETTINGS,
  EDIT_BLUETOOTH_SETTINGS,
  SAVE_BLUETOOTH_SETTINGS,
  OPEN_SAVE_FOR_ALL_MODAL,
  CLOSE_SAVE_FOR_ALL_MODAL,
  CHANGE_SAVE_FOR_ALL,
  UPDATE_USERNAME_SUCCEEDED,
  UPDATE_USERNAME_FAILED,
  UPDATE_BEACON,
  UPDATE_BEACON_SUCCEEDED,
  UPDATE_BEACON_FAILED,
  UPDATE_ADDRESS_ALL_BEACONS,
  UPDATE_ADDRESS_ALL_BEACONS_SUCCEEDED,
  UPDATE_ADDRESS_ALL_BEACONS_FAILED,
  CREATE_OR_UPDATE_RECIPIENT,
  CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED,
  CREATE_OR_UPDATE_RECIPIENT_FAILED,
  REQUEST_DEVICES,
  REQUEST_DEVICES_SUCCEEDED,
  REQUEST_DEVICES_FAILED,
  SELECT_DEVICE,
  DELETE_RECIPIENT,
  DELETE_RECIPIENT_SUCCEEDED,
  DELETE_RECIPIENT_FAILED,
  REQUEST_EVENT,
  REQUEST_EVENT_SUCCEEDED,
  REQUEST_EVENT_FAILED,
  SAVE_ALL_REQUEST_CONFIRMATION,
  SAVE_ALL_CANCEL,
  SAVE_ALL_CONFIRM,
  REQUEST_SAVE_ALL_SUCCEEDED,
  REQUEST_SAVE_ALL_FAILED,
  SELECT_DEVICE_PAGE_SIZE,
  SET_FIRST_ACTIVE,
  SET_ACTIVE_BACKEND,
  SET_DEFAULT_MESSAGE,
  REFETCHED_DEVICE,
  REFETCHED_DEVICE_LIST
};

export default TYPES;
