// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';
import {
  getDeviceStatus,
  getAlarmState,
  getLocationType
} from '../../../../../../services/DeviceUtils';

const initialState = {
  devices: [],
  loading: false,
  currentPage: 0,
  totalPages: 0,
  pageSize: 5,
  sortMetric: 'status', // id, name, battery, lastSeen
  sortAsc: false // ascending or false for descending
};

const getConfig = (device) => {
  try {
    return JSON.parse(device.c8y_Configuration.config);
  } catch {
    return null;
  }
};

const sortDevices = (devices, metric, order = false) => {
  switch (metric) {
    case 'timeZone':
      return devices.sort((a, b) => {
        if (
          typeof a.secufy_Time === 'undefined' ||
          typeof a.secufy_Time.timezone === 'undefined'
        )
          return 1;
        if (
          typeof b.secufy_Time === 'undefined' ||
          typeof b.secufy_Time.timezone === 'undefined'
        )
          return -1;

        if (a.secufy_Time.timezone === b.secufy_Time.timezone) {
          return 0;
        }

        if (order) {
          return a.secufy_Time.timezone < b.secufy_Time.timezone ? -1 : 1;
        } else {
          return a.secufy_Time.timezone < b.secufy_Time.timezone ? 1 : -1;
        }
      });
    case 'firmwareVersion':
      return devices.sort((a, b) => {
        if (
          typeof a.secufy_Hardware === 'undefined' ||
          typeof a.secufy_Hardware.firmwareVersion === 'undefined'
        )
          return 1;
        if (
          typeof b.secufy_Hardware === 'undefined' ||
          typeof b.secufy_Hardware.firmwareVersion === 'undefined'
        )
          return -1;

        if (
          a.secufy_Hardware.firmwareVersion ===
          b.secufy_Hardware.firmwareVersion
        ) {
          return 0;
        }

        if (order) {
          return a.secufy_Hardware.firmwareVersion <
            b.secufy_Hardware.firmwareVersion
            ? -1
            : 1;
        } else {
          return a.secufy_Hardware.firmwareVersion <
            b.secufy_Hardware.firmwareVersion
            ? 1
            : -1;
        }
      });
    case 'id':
      return devices.sort((a, b) => {
        if (typeof a.name === 'undefined') return 1;
        if (typeof b.name === 'undefined') return -1;

        const aName = a.name.slice(a.name.length - 6);
        const bName = b.name.slice(b.name.length - 6);

        if (aName === bName) return 0;

        if (order) {
          return aName < bName ? -1 : 1;
        } else {
          return aName < bName ? 1 : -1;
        }
      });
    case 'name':
      return devices.sort((a, b) => {
        const aConf = getConfig(a);
        const bConf = getConfig(b);

        if (!aConf || !aConf.userName) return 1;
        if (!bConf || !bConf.userName) return -1;

        if (aConf.userName === bConf.userName) {
          return 0;
        }

        if (order) {
          return aConf.userName > bConf.userName ? -1 : 1;
        } else {
          return aConf.userName > bConf.userName ? 1 : -1;
        }
      });
    case 'battery':
      return devices.sort((a, b) => {
        if (
          typeof a.c8y_Battery === 'undefined' ||
          typeof a.c8y_Battery.level === 'undefined'
        )
          return 1;
        if (
          typeof b.c8y_Battery === 'undefined' ||
          typeof b.c8y_Battery.level === 'undefined'
        )
          return -1;

        if (a.c8y_Battery.level.value === b.c8y_Battery.level.value) {
          return 0;
        }

        if (order) {
          return a.c8y_Battery.level.value < b.c8y_Battery.level.value ? -1 : 1;
        } else {
          return a.c8y_Battery.level.value < b.c8y_Battery.level.value ? 1 : -1;
        }
      });
    case 'lastSeen':
      return devices.sort((a, b) => {
        if (
          typeof a.c8y_lastSeen === 'undefined' ||
          typeof a.c8y_lastSeen.lastMessage === 'undefined'
        ) {
          return 1;
        }

        if (
          typeof b.c8y_lastSeen === 'undefined' ||
          typeof b.c8y_lastSeen.lastMessage === 'undefined'
        ) {
          return -1;
        }

        const aDate = new Date(a.c8y_lastSeen.lastMessage);
        const bDate = new Date(b.c8y_lastSeen.lastMessage);

        if (aDate === bDate) {
          return 0;
        }

        if (order) {
          return aDate < bDate ? -1 : 1;
        } else {
          return aDate < bDate ? 1 : -1;
        }
      });
    case 'alarmState':
      return devices.sort((a, b) => {
        const alarmStateA = getAlarmState(a);
        const alarmStateB = getAlarmState(b);

        if (!alarmStateA) return 1;
        if (!alarmStateB) return -1;

        if (alarmStateA === alarmStateB) {
          return 0;
        }

        if (order) {
          return alarmStateA > alarmStateB ? -1 : 1;
        } else {
          return alarmStateA > alarmStateB ? 1 : -1;
        }
      });
    case 'status':
      return devices.sort((a, b) => {
        const aStatus = getDeviceStatus(a);
        const bStatus = getDeviceStatus(b);

        const ranking = ['UNKNOWN', 'OFFLINE', 'RED', 'YELLOW', 'GREEN'];

        if (aStatus === ranking[0] || aStatus === ranking[1]) {
          return 1;
        }

        if (bStatus === ranking[0] || bStatus === ranking[1]) {
          return -1;
        }

        if (aStatus === bStatus) {
          return 0;
        }

        if (order) {
          return ranking.indexOf(aStatus) < ranking.indexOf(bStatus) ? -1 : 1;
        } else {
          return ranking.indexOf(aStatus) < ranking.indexOf(bStatus) ? 1 : -1;
        }
      });
    case 'location':
      return devices.sort((a, b) => {
        const locationTypeA = getLocationType(a);
        const locationTypeB = getLocationType(b);

        if (!locationTypeA || locationTypeA === '-') return 1;
        if (!locationTypeB || locationTypeB === '-') return -1;

        if (locationTypeA === locationTypeB) {
          return 0;
        }

        if (order) {
          return locationTypeA > locationTypeB ? -1 : 1;
        } else {
          return locationTypeA > locationTypeB ? 1 : -1;
        }
      });

    default:
      return devices;
  }
};

export default handleActions(
  {
    [types.REQUEST_DEVICES]: (state, { payload }) => ({
      ...state,
      devices: [],
      loading: true
    }),
    [types.REQUEST_DEVICES_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      devices: sortDevices(payload.devices, state.sortMetric, state.sortAsc),
      loading: false,
      currentPage: 0,
      totalPages: 0
    }),
    [types.REQUEST_DEVICES_FAILED]: (state, { payload }) => initialState,
    [types.SELECT_DEVICE_PAGE]: (state, { payload }) => ({
      ...state,
      currentPage: payload
    }),

    [types.REFETCHED_DEVICE_LIST]: (state, { payload }) => ({
      ...state,
      devices: sortDevices(payload, state.sortMetric, state.sortAsc)
    }),

    [types.SORT_DEVICES]: (state, { payload }) => {
      const metric = payload;
      let order = state.sortAsc;

      if (state.sortMetric === metric) {
        order = !order;
      } else {
        order = false;
      }

      let devices = sortDevices(state.devices, metric, order);

      return {
        ...state,
        devices,
        sortMetric: metric,
        sortAsc: order
      };
    },
    [types.SELECT_DEVICE_PAGE_SIZE]: (state, { payload }) => ({
      ...state,
      pageSize: payload
    })
  },
  initialState
);
