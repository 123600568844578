import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { recipientStartEditing } from '../../Device/components/HelperOverview/actions';
import { requestRecipients } from '../../../scenes/Device/components/HelperOverview/actions';

import FallbackHelper from '..';
import { getRecipients } from '../selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    recipients: getRecipients(state),
    router: state.router,
    overview: state.deviceOverview
  };
};

const mapDispatchToProps = (dispatch) => ({
    openHelperModal: (recipient) => dispatch(recipientStartEditing(recipient)),
    requestRecipients: (deviceId) => dispatch(requestRecipients(deviceId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FallbackHelper)
);
