import { createAction } from 'redux-actions';
import types from '../types';

export const toggleDeleteConfirmationModal = createAction(
  types.TOGGLE_DELETE_CONFIRMATION_MODAL
);

export const requestConfig = createAction(types.REQUEST_CONFIG);
export const requestConfigSucceeded = createAction(
  types.REQUEST_CONFIG_SUCCEEDED
);
export const requestConfigFailed = createAction(types.REQUEST_CONFIG_FAILED);

export const requestButtonInfo = createAction(types.REQUEST_BUTTON_INFO);
export const requestOwnerSucceeded = createAction(
  types.REQUEST_OWNER_SUCCEEDED
);
export const requestOwnerFailed = createAction(types.REQUEST_OWNER_FAILED);
export const requestOrderSucceeded = createAction(
  types.REQUEST_ORDER_SUCCEEDED
);
export const requestOrderFailed = createAction(types.REQUEST_ORDER_FAILED);

export const requestHardwareInfo = createAction(types.REQUEST_HARDWARE_INFO);
export const requestHardwareInfoSucceeded = createAction(
  types.REQUEST_HARDWARE_INFO_SUCCEEDED
);
export const requestHardwareInfoFailed = createAction(
  types.REQUEST_HARDWARE_INFO_FAILED
);

export const requestBeacons = createAction(types.REQUEST_BEACONS);

export const requestBeaconsSucceeded = createAction(
  types.REQUEST_BEACONS_SUCCEEDED
);
export const requestBeaconsFailed = createAction(types.REQUEST_BEACONS_FAILED);

export const updateBeacon = createAction(types.UPDATE_BEACON);
export const updateBeaconSucceeded = createAction(
  types.UPDATE_BEACON_SUCCEEDED
);
export const updateBeaconFailed = createAction(types.UPDATE_BEACON_FAILED);

export const updateAddressAllBeacons = createAction(
  types.UPDATE_ADDRESS_ALL_BEACONS
);
export const updateAddressAllBeaconsSucceeded = createAction(
  types.UPDATE_ADDRESS_ALL_BEACONS_SUCCEEDED
);
export const updateAddressAllBeaconsFailed = createAction(
  types.UPDATE_ADDRESS_ALL_BEACONS_FAILED
);

export const requestRecipients = createAction(types.REQUEST_RECIPIENTS);
export const requestRecipientsSucceeded = createAction(
  types.REQUEST_RECIPIENTS_SUCCEEDED
);
export const requestRecipientsFailed = createAction(
  types.REQUEST_RECIPIENTS_FAILED
);

export const recipientStartEditing = createAction(
  types.RECIPIENT_START_EDITING
);
export const recipientCancelEditing = createAction(
  types.RECIPIENT_CANCEL_EDITING
);
export const editRecipient = createAction(types.EDIT_RECIPIENT);

export const getDevice = createAction(types.GET_DEVICE);
export const getDeviceSucceeded = createAction(types.GET_DEVICE_SUCCEEDED);
export const getDeviceFailed = createAction(types.GET_DEVICE_FAILED);
export const selectDevicePage = createAction(types.SELECT_DEVICE_PAGE);
export const sortDevices = createAction(types.SORT_DEVICES);

export const editGeneralSettings = createAction(types.EDIT_GENERAL_SETTINGS);
export const saveGeneralSettings = createAction(types.SAVE_GENERAL_SETTINGS);
export const editAndSaveGeneralSettings = createAction(
  types.EDIT_AND_SAVE_GENERAL_SETTINGS
);
export const updateGeneralSettingsSucceeded = createAction(
  types.UPDATE_USERNAME_SUCCEEDED
);
export const updateGeneralSettingsFailed = createAction(
  types.UPDATE_USERNAME_FAILED
);

export const editEmergencyPlans = createAction(types.EDIT_EMERGENCY_PLANS);
export const saveEmergencyPlans = createAction(types.SAVE_EMERGENCY_PLANS);

export const editButtonSettings = createAction(types.EDIT_BUTTON_SETTINGS);
export const saveButtonSettings = createAction(types.SAVE_BUTTON_SETTINGS);

export const editTrackingSettings = createAction(types.EDIT_TRACKING_SETTINGS);
export const saveTrackingSettings = createAction(types.SAVE_TRACKING_SETTINGS);

export const editBluetoothSettings = createAction(
  types.EDIT_BLUETOOTH_SETTINGS
);
export const saveBluetoothSettings = createAction(
  types.SAVE_BLUETOOTH_SETTINGS
);

export const createOrUpdateRecipient = createAction(
  types.CREATE_OR_UPDATE_RECIPIENT
);
export const createOrUpdateRecipientSucceeded = createAction(
  types.CREATE_OR_UPDATE_RECIPIENT_SUCCEEDED
);
export const createOrUpdateRecipientFailed = createAction(
  types.CREATE_OR_UPDATE_RECIPIENT_FAILED
);
export const deleteRecipient = createAction(types.DELETE_RECIPIENT);
export const deleteRecipientSucceeded = createAction(
  types.DELETE_RECIPIENT_SUCCEEDED
);
export const deleteRecipientFailed = createAction(
  types.DELETE_RECIPIENT_FAILED
);

export const requestDevices = createAction(types.REQUEST_DEVICES);
export const requestDevicesSucceeded = createAction(
  types.REQUEST_DEVICES_SUCCEEDED
);
export const requestDevicesFailed = createAction(types.REQUEST_DEVICES_FAILED);
export const selectDevice = createAction(types.SELECT_DEVICE);

export const requestEvent = createAction(types.REQUEST_EVENT);
export const requestEventSucceeded = createAction(
  types.REQUEST_EVENT_SUCCEEDED
);
export const requestEventFailed = createAction(types.REQUEST_EVENT_FAILED);

export const selectDevicePageSize = createAction(types.SELECT_DEVICE_PAGE_SIZE);

export const setFirstActive = createAction(types.SET_FIRST_ACTIVE);

export const setActiveBackend = createAction(types.SET_ACTIVE_BACKEND);

export const setDefaultMessage = createAction(types.SET_DEFAULT_MESSAGE);

export const refetchedDevice = createAction(types.REFETCHED_DEVICE);

export const refetchedDeviceList = createAction(types.REFETCHED_DEVICE_LIST);
