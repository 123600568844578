import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const SecufyTextField = styled(TextField)`
  .MuiInput-underline:after {
    border-bottom: 2px solid #ea6000 !important;
  }
`;

interface IInputArea {
  name: string;
  value: string | number;
  alignRight?: boolean;
  disabled?: boolean;
  placeholder?: string;
  rowsMin?: number;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
}

interface IDefaultInputArea extends IInputArea {
  type: string;
}

const DefaultInputArea = ({
  value,
  onChange = () => {},
  name = 'InputArea',
  disabled = false,
  placeholder = '',
  rowsMin = 6,
  onBlur = () => {}
}: IDefaultInputArea) => (
  <SecufyTextField
    name={name}
    value={value}
    placeholder={placeholder}
    fullWidth={true}
    multiline={true}
    rows={1}
    rowsMax={100}
    disabled={disabled}
    onChange={(e) => onChange(e)}
    onBlur={(e) => onBlur(e)}
    onKeyDown={(e: any) => {
      if (e.key === 'Enter') {
        onBlur(e);
      }
    }}
  ></SecufyTextField>
);

const TextInput = (props: IInputArea) => (
  <DefaultInputArea type={'text'} {...props} />
);

const NumberInput = (props: IInputArea) => (
  <DefaultInputArea type={'number'} alignRight {...props} />
);

export { DefaultInputArea as InputArea, NumberInput, TextInput };
