import { Component } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { limitUnicodeString } from '../../../../utils';
import { InputArea } from '../../../../scenes/Device/components/HelperOverview/components/InputArea';

const StyledModal = styled(Modal)`
  // width: 50%;
  // min-width: 600px;
  // max-width: 800px;
  margin-top: 50px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 20px;
`;

const Title = styled.p`
  color: #707070;
  font-size: 20px;
  font-family: 'Montserrat';
  margin-bottom: 0px;
`;

const RadioGroup = styled.p`
  color: #707070;
  font-size: 16px;
  font-family: 'Montserrat';
  margin-bottom: 0px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 15px;
  font-family: 'Montserrat';
`;

const SubTitle = styled.p`
  color: #707070;
  font-size: 16px;
  font-family: 'Montserrat';
  margin-bottom: 0px;
`;

const SaveButton = styled(Button)`
  background-color: #ea6000;
  border-color: #ea6000;
  width: 200px;

  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

/** Page Content */
class CloseAlarmModal extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ' '
    };
  }

  getWearer(recipients) {
    let wearer = 'Der Träger des Sicherheitsbegleiters';
    let i = 0;
    for (i; i < recipients.length; i++) {
      const recipient = recipients[i];
      if (recipient) {
        if (recipient.role === 'wearer') {
          wearer = recipient.firstName + ' ' + recipient.lastName;
        }
      }
    }
    return wearer.trim();
  }

  getRadioGroup(severity, trigger, edit, wearer) {
    if (severity === 'CRITICAL') {
      if (trigger === 'auto') {
        return (
          <RadioGroup
            onChange={(e: any) => edit(e.target.value, 'alarmReason')}
          >
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, mit Secufy Connect konnte (schneller) geholfen werden'
                }
                name="auto"
              />{' '}
              {wearer} war in Not, mit Secufy Connect konnte (schneller)
              geholfen werden
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, es kam Hilfe unabhängig von Secufy Connect'
                }
                name="auto"
              />{' '}
              {wearer} war in Not, es kam Hilfe unabhängig von Secufy Connect
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer + ' war in Not, konnte sich dann aber selbst helfen'
                }
                name="auto"
              />{' '}
              {wearer} war in Not, konnte sich dann aber selbst helfen
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, aber keiner der Sicherheitskontakte hat sich (schnell genug) gekümmert'
                }
                name="auto"
              />{' '}
              {wearer} war in Not, aber keiner der Sicherheitskontakte hat sich
              (schnell genug) gekümmert
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' hat den Sicherheitsbegleiter nicht flach abgelegt, dadurch hat er fälschlich ausgelöst'
                }
                name="auto"
              />{' '}
              {wearer} hat den Sicherheitsbegleiter nicht flach abgelegt,
              dadurch hat er fälschlich ausgelöst
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' hat den Sicherheitsbegleiter getragen, dieser hat aber fälschlich ausgelöst. Es konnte unproblematisch geklärt werden'
                }
                name="auto"
              />{' '}
              {wearer} hat den Sicherheitsbegleiter getragen, dieser hat aber
              fälschlich ausgelöst. Es konnte unproblematisch geklärt werden{' '}
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' hat den Sicherheitsbegleiter getragen, dieser hat aber fälschlich ausgelöst. Das Klären hat erheblichen Aufwand produziert'
                }
                name="auto"
              />{' '}
              {wearer} hat den Sicherheitsbegleiter getragen, dieser hat aber
              fälschlich ausgelöst. Das Klären hat erheblichen Aufwand
              produziert{' '}
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value="Sonstiges (im Eingabefeld genauer spezifiziert)"
                name="auto"
              />{' '}
              Sonstiges (im Eingabefeld genauer spezifiziert)
            </label>
            <br />
            <br />
          </RadioGroup>
        );
      } else {
        return (
          <RadioGroup
            onChange={(e: any) => edit(e.target.value, 'alarmReason')}
          >
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, mit Secufy Connect konnte (schneller) geholfen werden'
                }
                name="manual"
              />{' '}
              {wearer} war in Not, mit Secufy Connect konnte (schneller)
              geholfen werden{' '}
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, es kam Hilfe unabhängig von Secufy Connect'
                }
                name="manual"
              />{' '}
              {wearer} war in Not, es kam Hilfe unabhängig von Secufy Connect
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer + ' war in Not, konnte sich dann aber selbst helfen'
                }
                name="manual"
              />{' '}
              {wearer} war in Not, konnte sich dann aber selbst helfen
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' war in Not, aber keiner der Sicherheitskontakte hat sich (schnell genug) gekümmert'
                }
                name="manual"
              />{' '}
              {wearer} war in Not, aber keiner der Sicherheitskontakte hat sich
              (schnell genug) gekümmert
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' wollte Kontakt aufnehmen, ohne dass ein Notfall vorlag'
                }
                name="manual"
              />{' '}
              {wearer} wollte Kontakt aufnehmen, ohne dass ein Notfall vorlag
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' hat den Alarm versehentlich ausgelöst, dann aber selbst wieder storniert'
                }
                name="manual"
              />{' '}
              {wearer} hat den Alarm versehentlich ausgelöst, dann aber selbst
              wieder storniert
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value={
                  wearer +
                  ' hat den Alarm versehentlich ausgelöst ohne es zu bemerken'
                }
                name="manual"
              />{' '}
              {wearer} hat den Alarm versehentlich ausgelöst ohne es zu bemerken{' '}
            </label>
            <br />
            <br />
            <label>
              <input
                type="radio"
                value="Sonstiges (im Eingabefeld genauer spezifiziert)"
                name="manual"
              />{' '}
              Sonstiges (im Eingabefeld genauer spezifiziert)
            </label>
            <br />
            <br />
          </RadioGroup>
        );
      }
    } else {
      return (
        <RadioGroup onChange={(e: any) => edit(e.target.value, 'alarmReason')}>
          <label>
            <input
              type="radio"
              value={
                wearer + ' hat den Sicherheitsbegleiter (zu tragen) vergessen'
              }
              name="warning"
            />{' '}
            {wearer} hat den Sicherheitsbegleiter (zu tragen) vergessen
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value={wearer + ' ging es gut, wollte heute ausschlafen'}
              name="warning"
            />{' '}
            {wearer} ging es gut, wollte heute ausschlafen
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value={
                wearer +
                ' ging es heute nicht so gut, ist deswegen erst später aufgestanden'
              }
              name="warning"
            />{' '}
            {wearer} ging es heute nicht so gut, ist deswegen erst später
            aufgestanden
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value={
                wearer +
                ' war in Not, mit Secufy Connect konnte (schneller) geholfen werden'
              }
              name="warning"
            />{' '}
            {wearer} war in Not, mit Secufy Connect konnte (schneller) geholfen
            werden
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value={
                wearer +
                ' war in Not, mit Secufy Connect konnte (schneller) geholfen werden'
              }
              name="warning"
            />{' '}
            {wearer} war in Not, mit Secufy Connect konnte (schneller) geholfen
            werden
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value={
                wearer + ' war in Not, konnte sich dann aber selbst helfen'
              }
              name="warning"
            />{' '}
            {wearer} war in Not, konnte sich dann aber selbst helfen
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value="Der Sicherheitsbegleiter hat fälschlich eine Warnung gegeben, Jemand war auf und hatten den Sicherheitsbegleiter dabei"
              name="warning"
            />{' '}
            Der Sicherheitsbegleiter hat fälschlich eine Warnung gegeben, Jemand
            war auf und hatten den Sicherheitsbegleiter dabei
          </label>
          <br />
          <br />
          <label>
            <input
              type="radio"
              value="Sonstiges (im Eingabefeld genauer spezifiziert)"
              name="warning"
            />{' '}
            Sonstiges (im Eingabefeld genauer spezifiziert)
          </label>
          <br />
          <br />
        </RadioGroup>
      );
    }
  }

  validateInput(input) {
    const { alarmReason } = input;

    // Empty Name
    if (alarmReason === '') {
      this.setState({
        error: 'Bitte wählen Sie einen Grund für den Alarm aus.'
      });
      return;
    }

    this.props.save({
      ...input
    });
    this.setState({
      error: ''
    });
  }

  getHeading(severity) {
    if (severity === 'WARNING') {
      return 'Warnung beenden';
    }
    return 'Alarm beenden';
  }

  render() {
    let {
      open,
      edit,
      close,
      alarmReason,
      optionalText,
      severity,
      trigger,
      alarmId,
      deviceId,
      recipients
    } = this.props;

    return (
      <StyledModal isOpen={open} modalTransition={{ timeout: 200 }}>
        <ModalHeader toggle={close}>
          <Title>{this.getHeading(severity)}</Title>
        </ModalHeader>
        <StyledModalBody>
          <SubTitle>Bitte wählen Sie einen Grund für den Alarm aus:</SubTitle>
          <br />
          <br />

          {this.getRadioGroup(
            severity,
            trigger,
            edit,
            this.getWearer(recipients)
          )}

          <br />
          <br />
          <InputArea
            name="alertPlanHome"
            placeholder={'Optionaler Freitext'}
            type={'text'}
            value={optionalText}
            onChange={(e) => {
              edit(limitUnicodeString(e.target.value, 500), 'optionalText');
            }}
          ></InputArea>
          <br />
          <br />
          {this.state.error && <ErrorText>{this.state.error}</ErrorText>}
        </StyledModalBody>
        <ModalFooter>
          <SaveButton
            color="primary"
            type={'submit'}
            onClick={() =>
              this.validateInput({
                alarmReason,
                optionalText,
                alarmId,
                severity,
                deviceId
              })
            }
          >
            {this.getHeading(severity)}
          </SaveButton>{' '}
        </ModalFooter>
      </StyledModal>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  CloseAlarmModal
);
