import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  recipientStartEditing,
  selectDevice,
  requestConfig,
  requestHardwareInfo,
  requestDevices,
  requestEvent,
  requestRecipients,
  getDevice,
  refetchedDevice
} from '../actions';

import HelperOverview from '..';
import { getRecipients } from '../components/Tabs/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    recipients: getRecipients(state, ownProps),
    loading: state.helperLoading,
    modal: state.helperModal,
    devices: state.devices,
    lastEvent: state.lastEvent,
    location: state.router.location,
    device: state.device,
    deviceList: state.deviceList,
    router: state.router,
    overview: state.deviceOverview
  };
};

const mapDispatchToProps = (dispatch) => ({
  openHelperModal: (recipient) => dispatch(recipientStartEditing(recipient)),
  requestConfig: (id) => dispatch(requestConfig(id)),
  requestHardwareInfo: (id) => dispatch(requestHardwareInfo(id)),
  requestRecipients: (id) => dispatch(requestRecipients(id)),
  requestDevices: () => dispatch(requestDevices()),
  selectDevice: (id) => dispatch(selectDevice(id)),
  requestEvent: (id) => dispatch(requestEvent(id)),
  requestDevice: (id) => dispatch(getDevice(id)),
  refetchedDevice: (device) => dispatch(refetchedDevice(device))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HelperOverview)
);
