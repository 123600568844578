// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = null;

export default handleActions(
  {
    [types.SELECT_DEVICE]: (state, { payload }) => payload,
    [types.REQUEST_DEVICES_SUCCEEDED]: (state, { payload }) => {
      try {
        return payload[0].id;
      } catch (e) {
        return null;
      }
    }
  },
  initialState
);
