import { RestService } from '../RestService';

type RecipientRole = 'helper' | 'wearer' | 'admin';

type Recipient = {
  userId: string;
  role: RecipientRole;
  phoneNumber: string;
  phoneNumber2: string;
  countryCode: string;
  countryCode2: string;
  smsEnabled: boolean;
  smsEnabled2: boolean;
  emailEnabled: boolean;
  voicemailEnabled: boolean;
  voicemailEnabled2: boolean;
  approved: boolean;
  receiveAlarms: boolean;
  firstName: string;
  lastName: string;
  email: string;
  yearOfBirth: number;
  gender: string;
  user: {
    id: string;
  };
  device: {
    c8yId: string;
    id: string;
  };
};

export enum ConfirmMailState {
  SUCCESS,
  FAILURE,
  TOKEN_EXPIRED_AND_MAIL_SENT
}

export class RecipientService extends RestService {
  async getDeviceRecipients(deviceId: string): Promise<Recipient[]> {
    const url = `${this.baseUrl}/recipients/${deviceId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      return (await response.json()) as Recipient[];
    }

    return [];
  }

  async createRecipient(
    deviceId: string,
    recipient: Recipient
  ): Promise<Recipient> {
    const url = `${this.baseUrl}/recipients`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...recipient,
        device: {
          c8yId: deviceId
        }
      }),
      method: 'POST'
    });

    if (response.status >= 300) {
      let json;
      try {
        json = await response.json();
      } catch (e) {
        throw new Error('Create recipient failed');
      }
      throw new Error(json.error || 'Create recipient failed');
    }

    return (await response.json()) as Recipient;
  }

  async updateRecipient(recipient: Recipient): Promise<Recipient> {
    const url = `${this.baseUrl}/recipients`;

    recipient.yearOfBirth = Number(recipient.yearOfBirth);

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(recipient),
      method: 'PUT'
    });

    if (response.status >= 200 && response.status <= 299) {
      return await response.json();
    }
    throw new Error((await response.json()) || 'Update recipient failed');
  }

  async deleteRecipient(recipient: Recipient) {
    const url = `${this.baseUrl}/recipients/?userId=${recipient.user.id}&deviceId=${recipient.device.id}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'DELETE'
    });

    return response.status === 204;
  }

  public async approveRecipient(token): Promise<ConfirmMailState> {
    const url = `${this.baseUrl}/recipients/approve`;

    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status === 204) {
      return ConfirmMailState.SUCCESS;
    } else if (response.status === 401) {
      const json = await response.json();
      if (json.resendMail) {
        const resendMailSuccess = await this.resendEmail(token);

        return resendMailSuccess
          ? ConfirmMailState.TOKEN_EXPIRED_AND_MAIL_SENT
          : ConfirmMailState.FAILURE;
      }
    }

    return ConfirmMailState.FAILURE;
  }

  public async resendEmail(token): Promise<boolean> {
    const url = `${this.baseUrl}/recipients/resendverifyemail`;
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //@ts-ignore
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.status === 204;
  }
}
