import { useState } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import TextElement from '../../../../components/TextElement';

import Logo from '../../../../assets/logo.png';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import { FullWidthButton } from '../../../../components/Buttons';
import {
  Panel,
  ButtonDiv,
  LogoImg,
  Content,
  LinkText,
  Link
} from '../../../../components/Panel';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginPanel = () => {
  const authService = AuthenticationService.instance;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const location = useLocation();

  const login = async () => {
    try {
      await authService.login(email.toLowerCase(), password);
      history.push({ pathname: `/`, search: location.search });
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
        toastId: 300
      });
    }
  };

  return (
    <Container>
      <Panel>
        <Content>
          <LogoImg src={Logo} alt="logo" />
          <TextElement
            name="email"
            title={'Email'}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={async (e: any) => {
              if (e.key === 'Enter') {
                login();
              }
            }}
            width="100%"
          ></TextElement>
          <TextElement
            name="password"
            title={'Password'}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={async (e: any) => {
              if (e.key === 'Enter') {
                login();
              }
            }}
            login
            type={'password'}
            width="100%"
          ></TextElement>
          <ButtonDiv>
            <FullWidthButton
              variant="contained"
              color="primary"
              onClick={async () => {
                login();
              }}
            >
              Einloggen
            </FullWidthButton>
          </ButtonDiv>
          <LinkText>
            Du hast noch keinen Account?{' '}
            <Link onClick={() => history.push(`/signup`)}>
              Account erstellen
            </Link>
          </LinkText>
        </Content>
      </Panel>
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(LoginPanel);
