import { useEffect, useState } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import TextElement from '../../../../components/TextElement';
import Logo from '../../../../assets/logo.png';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import {
  Panel,
  ButtonDiv,
  LogoImg,
  Content,
  LinkText,
  Link
} from '../../../../components/Panel';
import { FullWidthButton } from '../../../../components/Buttons';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { RecipientService } from '../../../../services/RecipientService';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorTextElement = styled.div`
  width: 100%;
  color: #e74c3c;
`;

const PasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const IButton = styled(IconButton)`
  margin-left: -50px !important;
  margin-top: 8px !important;
  height: 20px !important;
  &:hover {
    background-color: transparent !important;
  }
`;

const validateInput = (
  email,
  firstName,
  lastname,
  password,
  confirmPassword
): string => {
  if (email === '') return 'Bitte geben Sie eine gültige Email Adresse ein.';
  if (firstName === '') return 'Bitte gib deinen Vornamen ein.';
  if (lastname === '') return 'Bitte gib deinen Nachnamen ein.';
  if (password.length < 8)
    return 'Das Passwort muss mindestens aus 8 Zeichen bestehen.';
  if (confirmPassword !== password)
    return 'Die eingegebenen Passwörter stimmen nicht überein.';

  return '';
};

const RegisterPanel = () => {
  const authService = AuthenticationService.instance;
  const recipientService = new RecipientService();
  const [email, setEmail] = useState('');
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const history = useHistory();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsEmail = params.get('email');
    const paramsName = params.get('name');
    if (paramsEmail) {
      setEmail(paramsEmail);
      setEmailFieldDisabled(true);
    }
    if (paramsName) {
      setFirstName(paramsName.split(' ')[0] ?? '');
      setLastName(paramsName.split(' ')[1] ?? '');
    }
  }, [location]);

  return (
    <Container>
      <Panel>
        <Content>
          <LogoImg src={Logo} alt="logo" />

          <TextElement
            name="firstName"
            title={'Vorname'}
            value={firstName}
            autoComplete="off"
            onChange={(e) => setFirstName(e.target.value)}
            width="100%"
          ></TextElement>
          <TextElement
            name="lastName"
            title={'Nachname'}
            value={lastName}
            autoComplete="off"
            onChange={(e) => setLastName(e.target.value)}
            width="100%"
          ></TextElement>
          <TextElement
            name="email"
            title={'Email'}
            value={email}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            disabled={emailFieldDisabled}
            width="100%"
          ></TextElement>

          <PasswordContainer>
            <TextElement
              name="password"
              title={'Passwort'}
              value={password}
              autoComplete="new-password"
              password
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              width="100%"
            ></TextElement>

            {showPassword ? (
              <IButton
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                <VisibilityOffIcon></VisibilityOffIcon>
              </IButton>
            ) : (
              <IButton
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                <VisibilityIcon></VisibilityIcon>
              </IButton>
            )}
          </PasswordContainer>

          <PasswordContainer>
            <TextElement
              name="confirmPassword"
              title={'Passwort wiederholen'}
              value={confirmPassword}
              autoComplete="new-password"
              password
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? 'text' : 'password'}
              width="100%"
            ></TextElement>

            {showConfirmPassword ? (
              <IButton
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
              >
                <VisibilityOffIcon></VisibilityOffIcon>
              </IButton>
            ) : (
              <IButton
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
              >
                <VisibilityIcon></VisibilityIcon>
              </IButton>
            )}
          </PasswordContainer>
          {errorText && <ErrorTextElement>{errorText}</ErrorTextElement>}
          <ButtonDiv>
            <FullWidthButton
              variant="contained"
              color="primary"
              onClick={async () => {
                const error = validateInput(
                  email,
                  firstName,
                  lastName,
                  password,
                  confirmPassword
                );
                if (error !== '') {
                  setErrorText(error);
                  return;
                }

                if (token) {
                  try {
                    await recipientService.approveRecipient(token);
                  } catch (e) {
                    console.error(e);
                    setErrorText('Verifizierung der Email fehlgeschlagen');
                    return;
                  }
                }

                try {
                  await authService.signup(
                    email.toLowerCase(),
                    password,
                    firstName,
                    lastName
                  );
                } catch (e) {
                  console.error(e);
                  setErrorText('Registrierung fehlgeschlagen');
                  return;
                }

                setErrorText('');
                history.push('/');
              }}
            >
              Registrieren
            </FullWidthButton>
          </ButtonDiv>
          <LinkText>
            Du bist bereits registriert?{' '}
            <Link onClick={() => history.push(`/login`)}>Einloggen</Link>{' '}
          </LinkText>
        </Content>
      </Panel>
    </Container>
  );
};

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  RegisterPanel
);
