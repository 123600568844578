import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  loading: false
};

export default handleActions(
  {
    [types.REQUEST_CONFIG]: (state, action) => ({
      ...state,
      loading: true
    }),
    [types.REQUEST_CONFIG_SUCCEEDED]: (state, action) => ({
      ...state,
      loading: false
    })
  },
  initialState
);
