import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GridOverview from '..';
import { requestDevices } from '../../../../Device/components/HelperOverview/actions';

const mapStateToProps = (state, ownProps) => ({
  deviceList: state.deviceList
});

const mapDispatchToProps = (dispatch) => ({
  requestDevices: () => dispatch(requestDevices())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GridOverview)
);
