import { Component } from 'react';
import styled from 'styled-components';
import Beacon from '../../components/Beacon';
import beacons from '../../reducer/beacons';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/** Page Content */
class Settings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  componentDidMount() {
    const { requestBeacons, device } = this.props;

    if (device.device?.id && !beacons) requestBeacons(device.device.id);
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.device.device &&
      oldProps.device.device?.id !== this.props.device.device?.id
    )
      this.props.requestBeacons(this.props.device.device?.id);
  }

  getDeviceId(props): string {
    return props.match.params.deviceId;
  }

  render() {
    const { beacons } = this.props;

    if (beacons.length === 0) {
      return (
        <Div>
          {
            'Dem Sicherheitsbegleiter wurden noch keine Homezone Sender zugewiesen.'
          }
        </Div>
      );
    }

    return (
      <Div>
        {beacons
          .sort((a, b) =>
            a.major > b.major
              ? 1
              : a.major === b.major
              ? a.minor > b.minor
                ? 1
                : -1
              : -1
          )
          .map((beacons) => {
            return (
              <Beacon
                key={`${beacons.major}-${beacons.minor}`}
                {...beacons}
              ></Beacon>
            );
          })}
      </Div>
    );
  }
}

export default Settings;
