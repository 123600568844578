import { put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from '../types';
import {
  requestConfigSucceeded,
  requestConfigFailed,
  requestDevicesSucceeded,
  requestDevicesFailed,
  getDeviceFailed,
  getDeviceSucceeded,
  createOrUpdateRecipientSucceeded,
  createOrUpdateRecipientFailed,
  deleteRecipientSucceeded,
  deleteRecipientFailed,
  requestEventSucceeded,
  requestEventFailed,
  updateGeneralSettingsFailed,
  updateGeneralSettingsSucceeded,
  requestBeaconsSucceeded,
  requestBeaconsFailed,
  requestHardwareInfoSucceeded,
  requestHardwareInfoFailed,
  requestOwnerSucceeded,
  requestOwnerFailed,
  updateBeaconSucceeded,
  updateBeaconFailed,
  updateAddressAllBeaconsSucceeded,
  updateAddressAllBeaconsFailed,
  requestRecipientsSucceeded,
  requestRecipientsFailed,
  requestOrderSucceeded,
  requestOrderFailed
} from '../actions';
import { ConfigService } from '../../../../../services/ConfigService';
import { DeviceService } from '../../../../../services/DeviceService';
import { EventService } from '../../../../../services/EventService';
import { ThingstreamButtonConfigService } from '../../../../../services/ConfigureThingstreamButtonService';
import { ADD_BUTTON_SUCCEEDED } from '../../../../Overview/types';
import { RecipientService } from '../../../../../services/RecipientService';
import { BeaconService } from '../../../../../services/BeaconService';
import { ButtonInfoService } from '../../../../../services/ButtonInfoService';
import {
  requestAlarmEventsFailed,
  requestAlarmEventsSucceeded
} from '../../../../../components/DeviceTile/actions';
import { REQUEST_ALARM_EVENTS } from '../../../../../components/DeviceTile/types';

const getSelectedDeviceId = (state) =>
  state.router.location.pathname.split('/')[2];
const getGeneralSettings = (state) => state.generalSettings;
const getEmergencyPlans = (state) => state.emergencyPlans;
const getButtonSettings = (state) => state.buttonSettings;
const getTrackingSettings = (state) => state.trackingSettings;
const getBeacons = (state) => state.beacons;

function* requestConfig(action) {
  try {
    const configService = new ConfigService();
    const id = action.payload;
    let config = yield call(configService.getConfig.bind(configService), id);

    yield put(requestConfigSucceeded(config));
  } catch (error) {
    console.log(error);
    toast.error('Could not load device configuration', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestConfigFailed());
  }
}

function* requestHardwareInfo(action) {
  try {
    const configService = new ConfigService();
    const id = action.payload;
    let config = yield call(
      configService.getHardwareInfo.bind(configService),
      id
    );

    yield put(requestHardwareInfoSucceeded(config));
  } catch (error) {
    console.log(error);
    toast.error('Could not load device hardware data', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestHardwareInfoFailed());
  }
}

function* requestButtonInfo(action) {
  try {
    const buttonInfoService = new ButtonInfoService();
    const deviceId = action.payload;

    let info = yield call(
      buttonInfoService.getDeviceInfo.bind(buttonInfoService),
      deviceId
    );

    yield put(requestOwnerSucceeded(info.owner));
    yield put(requestOrderSucceeded(info.order));
  } catch (error) {
    toast.error('Die Admin-Daten konnten nicht geladen werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestOwnerFailed());
    yield put(requestOrderFailed());
  }
}

function* requestBeacons(action) {
  try {
    const beaconService = new BeaconService();
    const deviceId = action.payload;

    let beacons = yield call(
      beaconService.getDeviceBeacons.bind(beaconService),
      deviceId
    );

    yield put(requestBeaconsSucceeded({ deviceId, beacons }));
  } catch (error) {
    console.log(error);
    toast.error('Sicherheitskontakte konnten nicht geladen werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestBeaconsFailed());
  }
}

function* updateBeacon(action) {
  try {
    const beaconService = new BeaconService();
    const beacon = action.payload;

    yield call(beaconService.updateBeacon.bind(beaconService), beacon);
    toast.success('Der Homezone Sender wurde erfolgreich aktualisiert.');
    yield put(updateBeaconSucceeded(beacon));
  } catch (error) {
    console.log(error);
    toast.error('Der Homezone Sender konnten nicht aktualisiert werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(updateBeaconFailed());
  }
}

function* updateAddressAllBeacons(action) {
  //const { beacons } = this.props;

  const deviceId = yield select(getSelectedDeviceId);
  const tmpBeacons = yield select(getBeacons);
  const beacons = tmpBeacons[deviceId];

  try {
    const beaconService = new BeaconService();

    let i = 0;
    if (beacons.length === 0) {
      return;
    }
    for (i; i < beacons.length; i++) {
      const beacon = beacons[i];
      beacon.address = action.payload.address;
      beacon.postalCode = action.payload.postalCode;
      beacon.town = action.payload.town;

      yield call(beaconService.updateBeacon.bind(beaconService), beacon);
    }

    toast.success('Die Adressdaten aller Homezone Sender wurden aktualisiert.');
    yield put(updateAddressAllBeaconsSucceeded({ deviceId, beacons }));
  } catch (error) {
    console.log(error);
    toast.error(
      'Die Adressdaten der anderen Homezone Sender konnte nicht aktualisiert werden.',
      {
        position: 'top-right',
        toastId: 300
      }
    );
    yield put(updateAddressAllBeaconsFailed());
  }
}

function* requestRecipients(action) {
  try {
    const recipientService = new RecipientService();
    const deviceId = action.payload;

    let recipients = yield call(
      recipientService.getDeviceRecipients.bind(recipientService),
      deviceId
    );

    yield put(requestRecipientsSucceeded({ deviceId, recipients }));
  } catch (error) {
    console.log(error);
    toast.error('Sicherheitskontakte konnten nicht geladen werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestRecipientsFailed());
  }
}

function* requestDevices() {
  try {
    const deviceService = new DeviceService();
    const devices = yield call(deviceService.getDevices.bind(deviceService));
    yield put(requestDevicesSucceeded({ devices }));
  } catch (error) {
    toast.error('Could not load buttons', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestDevicesFailed());
  }
}

function* requestEvents(action) {
  try {
    const eventService = new EventService();
    const event = yield call(
      eventService.getEvents.bind(eventService),
      action.payload
    );
    yield put(requestEventSucceeded(event));
  } catch (error) {
    toast.error('Could not load event', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestEventFailed());
  }
}

function* requestAlarmEvents(action) {
  try {
    const eventService = new EventService();
    const deviceId = action.payload;
    const type = 'secufy_AlarmAccepted';
    const count = 3;
    const events = yield call(
      eventService.getLatestEvents.bind(eventService),
      deviceId,
      type,
      count
    );
    yield put(requestAlarmEventsSucceeded({ deviceId, events }));
  } catch (error) {
    console.log(error);
    toast.error('Could not load alarm event', {
      position: 'top-right',
      toastId: 300
    });
    yield put(requestAlarmEventsFailed());
  }
}

function* createOrUpdateRecipient(action) {
  let recipient = action.payload;
  try {
    const recipientService = new RecipientService();
    let deviceId = yield select(getSelectedDeviceId);

    if (deviceId == null) {
      deviceId = '0';
    }

    if (!recipient.user.id) {
      //create
      recipient = yield call(
        recipientService.createRecipient.bind(recipientService),
        deviceId,
        recipient
      );
      toast.success('Der Sicherheitskontakt wurde erfolgreich hinzugefügt.');
    } else {
      // update
      recipient = yield call(
        recipientService.updateRecipient.bind(recipientService),
        recipient
      );
      toast.success('Der Sicherheitskontakt wurde erfolgreich aktualisiert.');
    }

    yield put(createOrUpdateRecipientSucceeded({ recipient, deviceId }));
  } catch (error: any) {
    console.log(error);

    if (
      error.message === 'already_existing_with_other_role' &&
      recipient.role === 'wearer'
    ) {
      toast.error(
        'Der Träger konnten nicht erstellt werden, da er bereits als Sicherheitskontakt hinzugefügt wurde.',
        {
          position: 'top-right',
          toastId: 300
        }
      );
    } else if (
      error.message === 'already_existing_with_other_role' &&
      recipient.role === 'helper'
    ) {
      toast.error(
        'Der Sicherheitskontakt konnten nicht erstellt werden, da er bereits als Träger hinzugefügt wurde.',
        {
          position: 'top-right',
          toastId: 300
        }
      );
    } else if (
      error.message === 'already_existing' &&
      recipient.role === 'helper'
    ) {
      toast.error(
        'Der Sicherheitskontakt konnten nicht erstellt werden, da er bereits als hinzugefügt wurde.',
        {
          position: 'top-right',
          toastId: 300
        }
      );
    } else {
      toast.error(
        'Der Sicherheitskontakte konnten nicht aktualisiert werden.',
        {
          position: 'top-right',
          toastId: 300
        }
      );
    }

    yield put(createOrUpdateRecipientFailed());
  }
}

function* deleteRecipient(action) {
  try {
    const deviceId = yield select(getSelectedDeviceId);
    const recipientService = new RecipientService();

    const recipient = action.payload;
    const success = yield call(
      recipientService.deleteRecipient.bind(recipientService),
      recipient
    );
    if (!success) {
      throw new Error('Delete recipient failed');
    } else {
      toast.success('Der Sicherheitskontakt wurde erfolgreich gelöscht.');
      yield put(deleteRecipientSucceeded({ recipient, deviceId }));

      //delete recipient from geleralSettings
      try {
        const settings = yield select(getGeneralSettings);
        let i = 0;
        for (i; i < settings.batteryWarningRecipients.length; i++) {
          const battRecipient = settings.batteryWarningRecipients[i];
          if (recipient.user.id === battRecipient.value) {
            settings.batteryWarningRecipients.splice(i, 1);
          }
        }
        const configService = new ConfigService();

        yield call(
          configService.setSettings.bind(configService),
          deviceId,
          settings
        );

        toast.success(
          'Der Sicherheitskontakt wurde erfolgreich aus den Emfängern für Batteriewarnungen entfernt.',
          {
            toastId: 1200
          }
        );

        yield put(updateGeneralSettingsSucceeded(settings));
      } catch (error) {
        toast.error(
          'Der Sicherheitskontakt konnte nicht aus den Empängern für Batteriewarnungen entfernt werden.',
          {
            position: 'top-right',
            toastId: 1201
          }
        );
        yield put(updateGeneralSettingsFailed());
      }
    }
  } catch (error) {
    console.log(error);
    toast.error('Der Sicherheitskontakt konnte nicht gelöscht werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(deleteRecipientFailed());
  }
}

function* updateGeneralSettings() {
  try {
    const deviceId = yield select(getSelectedDeviceId);
    const settings = yield select(getGeneralSettings);

    const configService = new ConfigService();

    yield call(
      configService.setSettings.bind(configService),
      deviceId,
      settings
    );

    toast.success('Successfully updated your button.', {
      toastId: 1200
    });

    yield put(updateGeneralSettingsSucceeded(settings));
  } catch (error) {
    toast.error('Could not update your button.', {
      position: 'top-right',
      toastId: 1201
    });
    yield put(updateGeneralSettingsFailed());
  }
}

function* getDevice(action) {
  try {
    const deviceService = new DeviceService();
    const device = yield call(
      deviceService.getDevice.bind(deviceService),
      action.payload
    );

    yield put(getDeviceSucceeded(device));
  } catch (error) {
    console.log('error:', error);
    toast.error('Could not load device configuration', {
      position: 'top-right',
      autoClose: false
    });
    yield put(getDeviceFailed());
  }
}

function* saveEmergencyPlans() {
  try {
    const deviceId = yield select(getSelectedDeviceId);
    const emergencyPlansState = yield select(getEmergencyPlans);

    const deviceService = new DeviceService();

    const emergencyPlans = yield call(
      deviceService.getFragment.bind(deviceService),
      deviceId,
      'secufy_EmergencyPlans'
    );

    let update = emergencyPlans === null; // only update if something changed or if there are no saved messages

    if (
      emergencyPlans &&
      emergencyPlans.alertPlanHome !== emergencyPlansState.alertPlanHome
    ) {
      update = true;
    }

    if (
      emergencyPlans &&
      emergencyPlans.alertPlanOutside !== emergencyPlansState.alertPlanOutside
    ) {
      update = true;
    }

    if (
      emergencyPlans &&
      emergencyPlans.warningPlan !== emergencyPlansState.warningPlan
    ) {
      update = true;
    }

    if (!update) return;

    const success = yield call(
      deviceService.updateFragment.bind(deviceService),
      deviceId,
      'secufy_EmergencyPlans',
      emergencyPlansState
    );

    if (success) {
      toast.success(`Successfully updated your button.`, {
        position: 'top-right',
        toastId: 1200
      });

      return;
    }
  } catch (e) {
    console.log(e);
  }

  toast.error(`Could not update your button.`, {
    position: 'top-right',
    toastId: 1201
  });
}

function* saveButtonSettings() {
  try {
    const deviceId = yield select(getSelectedDeviceId);
    const buttonSettingsState = yield select(getButtonSettings);

    const thingstreamButtonConfigService = new ThingstreamButtonConfigService();

    const success = yield call(
      thingstreamButtonConfigService.update.bind(
        thingstreamButtonConfigService
      ),
      deviceId,
      'config',
      buttonSettingsState
    );

    if (success) {
      toast.success(`Successfully updated your button.`, {
        position: 'top-right',
        toastId: 1200
      });

      return;
    }
  } catch (e) {
    console.log(e);
  }

  toast.error(`Could not update your button.`, {
    position: 'top-right',
    toastId: 1201
  });
}

function* saveTrackingSettings() {
  try {
    const deviceId = yield select(getSelectedDeviceId);
    const trackingSettingsState = yield select(getTrackingSettings);

    const thingstreamButtonConfigService = new ThingstreamButtonConfigService();

    const success = yield call(
      thingstreamButtonConfigService.update.bind(
        thingstreamButtonConfigService
      ),
      deviceId,
      'tracking',
      trackingSettingsState
    );

    if (success) {
      toast.success(`Successfully updated your button.`, {
        position: 'top-right',
        toastId: 1200
      });

      return;
    }
  } catch (e) {
    console.log(e);
  }

  toast.error(`Could not update your button.`, {
    position: 'top-right',
    toastId: 1201
  });
}

const sagas = [
  takeEvery(types.REQUEST_CONFIG, requestConfig),
  takeEvery(types.REQUEST_HARDWARE_INFO, requestHardwareInfo),
  takeEvery(types.REQUEST_DEVICES, requestDevices),
  takeEvery(types.REQUEST_BEACONS, requestBeacons),
  takeEvery(types.REQUEST_BUTTON_INFO, requestButtonInfo),
  takeEvery(types.UPDATE_BEACON, updateBeacon),
  takeEvery(types.UPDATE_ADDRESS_ALL_BEACONS, updateAddressAllBeacons),
  takeEvery(types.REQUEST_RECIPIENTS, requestRecipients),
  takeEvery(types.DELETE_RECIPIENT, deleteRecipient),
  takeEvery(types.CREATE_OR_UPDATE_RECIPIENT, createOrUpdateRecipient),
  takeEvery(types.SAVE_GENERAL_SETTINGS, updateGeneralSettings),
  takeEvery(types.EDIT_AND_SAVE_GENERAL_SETTINGS, updateGeneralSettings),
  takeEvery(types.REQUEST_EVENT, requestEvents),
  takeEvery(REQUEST_ALARM_EVENTS, requestAlarmEvents),
  takeEvery(types.GET_DEVICE, getDevice),
  takeEvery(types.SAVE_EMERGENCY_PLANS, saveEmergencyPlans),
  takeEvery(types.SAVE_BUTTON_SETTINGS, saveButtonSettings),
  takeEvery(types.SAVE_TRACKING_SETTINGS, saveTrackingSettings),

  // Refresh devices after new button added
  takeEvery(ADD_BUTTON_SUCCEEDED, requestDevices)
];

export default sagas;
