import { HTTPError } from '../HttpService';
import { RestService } from '../RestService';

interface IAlarmCollection {
  alarms: [IAlarm];
}

type IAlarm = {
  count: number;
  creationTime: string;
  id: string;
  self: string;
  severity: string;
  source: { id: string; name: string; self: string };
  status: string;
  text: string;
  time: string;
  type: string;
};

export class AlarmService extends RestService {
  async getAlarms(deviceId: string): Promise<IAlarm | null> {
    let url = `${this.baseUrl}/c8y/alarm/alarms`;

    url = `${url}?source=${deviceId}`;
    url = `${url}&status=ACTIVE,ACKNOWLEDGED`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      const alarms = (await response.json()) as IAlarmCollection;
      if (alarms.alarms.length > 0) {
        return alarms.alarms[0];
      }
    }
    return null;
  }

  async getAllAlarms(): Promise<IAlarm[]> {
    let url = `${this.baseUrl}/c8y/alarm/alarms`; // Backend returns all active alarms for accessible buttons

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      const alarms = (await response.json()) as IAlarmCollection;
      if (alarms.alarms.length > 0) {
        return alarms.alarms;
      }
    }

    return [];
  }

  /**
   * @deprecated Alarms should be cleared by the backend (/app/v1/alarms/close/:alarmId)
   */
  async acknowledgeAlarm(alarmId: string): Promise<boolean | null> {
    let url = `${this.baseUrl}/c8y/alarm/alarms/${alarmId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        status: 'CLEARED'
      }),
      method: 'PUT'
    });

    if (response.status >= 200 && response.status <= 299) {
      return true;
    }
    return false;
  }

  async acceptAlarm(alarmId: string): Promise<boolean | null> {
    let url = '';

    let sourceParams = new URLSearchParams(window.location.search);

    if (sourceParams.get('close')) {
      url = `${this.baseUrl}/alarms/close/`;
    } else {
      url = `${this.baseUrl}/alarms/accept/`;
    }

    let targetParams = new URLSearchParams();
    for (let key of sourceParams.keys()) {
      targetParams.append(key, sourceParams.get(key) || '');
    }
    url += `${alarmId}?${targetParams.toString()}`;

    let Authorization = await this.getAuthHeader();
    if (!Authorization) Authorization = `Bearer ${sourceParams.get('token')}`;

    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      method: 'PUT'
    });

    if (response.status >= 200 && response.status <= 299) {
      return true;
    }

    throw new HTTPError(
      `Failed to accept alarm. Status: ${response.status}`,
      response.status
    );
  }

  async closeAlarm(
    alarmId: string,
    alarmReason: string,
    optionalText: string
  ): Promise<boolean | null> {
    const url = `${this.baseUrl}/alarms/close/${alarmId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        alarmReason: alarmReason,
        optionalText: optionalText
      }),
      method: 'PUT'
    });

    if (response.status >= 200 && response.status <= 299) {
      return true;
    }

    throw new HTTPError(
      `Failed to close alarm. Status: ${response.status}`,
      response.status
    );
  }
}
