import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  verifying: false,
  verified: false
};

export default handleActions(
  {
    [types.CONFIRM_OWNERSHIP]: (state, action) => ({
      ...state,
      verifying: true
    }),
    [types.CONFIRM_OWNERSHIP_SUCCEEDED]: (state, action) => ({
      ...state,
      verifying: false,
      verified: true
    }),
    [types.CONFIRM_OWNERSHIP_FAILED]: (state, action) => ({
      ...state,
      verifying: false,
      verifyFailed: true
    })
  },
  initialState
);
