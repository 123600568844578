import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import LoadingIndicators from '../../components/LoadingIndicator';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

/** Page Content */
const ApproveRecipient = ({
  approveRecipient,
  verifying,
  verified,
  tokenExpiredEmailResent,
  verifyFailed
}) => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const isWearer = params.get('isWearer');

  if (token) {
    if (!verifying && !verified && !verifyFailed && !tokenExpiredEmailResent) {
      approveRecipient(token);
    }

    if (verifying) {
      return (
        <Layout hideLogout>
          <CenteredDiv>
            <LoadingIndicators />
          </CenteredDiv>
        </Layout>
      );
    }

    if (tokenExpiredEmailResent) {
      return (
        <Layout>
          <CenteredDiv>
            <p>
              Dein Bestätigungslink ist abgelaufen. Du erhälst in wenigen
              Minuten eine neue Bestätigungsemail.
            </p>
          </CenteredDiv>
        </Layout>
      );
    }

    if (verifyFailed) {
      return (
        <Layout hideLogout>
          <CenteredDiv>
            <p>Etwas ist schiefgelaufen. Versuche es später erneut.</p>
          </CenteredDiv>
        </Layout>
      );
    }

    return (
      <Layout hideLogout>
        <CenteredDiv>
          <p>
            Du wurdest erfolgreich als
            {isWearer ? ' Träger' : ' Sicherheitskontakt'} hinzugefügt.
          </p>
          <p>Diese Seite kann nun geschlossen werden.</p>
        </CenteredDiv>
      </Layout>
    );
  } else {
    history.push('/');
    return <Layout hideLogout />;
  }
};

export default ApproveRecipient;
