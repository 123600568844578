import { handleActions } from 'redux-actions';
import types from '../types';

const initialState = {
  verifying: false,
  verified: false,
  verifyFailed: false
};

export default handleActions(
  {
    [types.APPROVE_RECIPIENT]: (state, action) => ({
      ...state,
      verifying: true
    }),
    [types.APPROVE_RECIPIENT_SUCCEEDED]: (state, action) => ({
      ...state,
      verifying: false,
      verified: true
    }),
    [types.APPROVE_RECIPIENT_FAILED]: (state, action) => ({
      ...state,
      verifying: false,
      verifyFailed: true
    }),
    [types.APPROVE_RECIPIENT_TOKEN_EXPIRED_EMAIL_RESENT]: (state, action) => ({
      ...state,
      verifying: false,
      tokenExpiredEmailResent: true
    })
  },
  initialState
);
