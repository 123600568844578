import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  firmwareVersion: ''
};

export default handleActions(
  {
    [types.REQUEST_HARDWARE_INFO]: (state, { payload }) => initialState,
    [types.REQUEST_HARDWARE_INFO_SUCCEEDED]: (state, { payload }) => {
      return { firmwareVersion: payload.firmwareVersion };
    }
  },
  initialState
);
