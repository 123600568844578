import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { RefetchService } from '../../services/RefetchService';
import { AuthenticationService } from '../../services/AuthenticationService';
import { useDispatch } from 'react-redux';
import { initializeApp } from '../../actions';

const WithAuthentication = ({ children }) => {
  const dispatch = useDispatch();
  const authService = AuthenticationService.instance;
  const [triedToLogin, setTriedToLogin] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useAsyncEffect(async () => {
    if (!triedToLogin && !authService.hasAccess) {
      await authService.testRefreshToken();
      setTriedToLogin(true);
    }
  });

  // render children if logged in
  if (authService.hasAccess) {
    // Redirect to verify email scene, if email not confirmed yet
    if (!authService.emailConfirmed && location.pathname !== '/verifyemail') {
      history.push(`/verifyemail`);
      return null;
    }

    // Redirect to overview scene, if email is already confirmed
    if (
      authService.hasAccess &&
      authService.emailConfirmed &&
      location.pathname === '/verifyemail'
    ) {
      history.push('/');
      return null;
    }

    RefetchService.instance.start();

    dispatch(initializeApp());

    // Render children
    return children;
  }

  // render loading screen while logging in
  if (authService.verifyingAccess || (!authService.hasAccess && !triedToLogin))
    return null;

  // redirect to login page if no access
  history.push({ pathname: `/login`, search: location.search });

  return null;
};

export default WithAuthentication;
