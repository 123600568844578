import moment from 'moment';
import { C8yBaseService } from '../C8yBaseService';

interface IMeasurementCollection {
  values: {
    [time: string]: {
      maxUsage: number;
      maxSteps: number;
    };
  };
}
export class ActivityService extends C8yBaseService {
  async getTodayUsage(deviceId: string): Promise<[number, number]> {
    let url = `${this.baseUrl}/measurement/measurements/series`;

    url = `${url}?source=${deviceId}`;
    url = `${url}&dateTo=${moment()
      .add(-1, 'days')
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toISOString()}`;
    url = `${url}&dateFrom=${moment()
      .add(-1, 'days')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString()}`;
    url = `${url}&series=secufy_Actipoints.usage`;
    url = `${url}&series=secufy_Actipoints.steps`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.status >= 200 && response.status <= 299) {
      const measurements = (await response.json()) as IMeasurementCollection;
      let todayUsage = 0;
      let todaySteps = 0;

      Object.keys(measurements.values).forEach(
        (time) => (todayUsage = todayUsage + measurements.values[time][0].max)
      );
      Object.keys(measurements.values).forEach(
        (time) => (todaySteps = todaySteps + measurements.values[time][1].max)
      );

      return [todayUsage, todaySteps];
    }

    return [0, 0];
  }
}
