import {
  Pagination as ReactstrapPagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import styled from 'styled-components';

import { getPagination } from './services';

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: auto;
  align-content: flex-end;
`;

const CenteredPagination = styled(ReactstrapPagination)`
  margin-left: auto;
  margin-right: auto;

  ul {
    margin-bottom: 0px !important;
  }
`;

const CsPaginationItem = styled(PaginationItem)`
  &:hover {
    button {
      color: #ea6000;
    }
  }

  ${(props) =>
    props.active &&
    `
      button { 
        color: #fff !important;
        background-color: #EA6000 !important;
        border-color: #EA6000 !important;
      }
    `}
`;

const CsPaginationLink = styled(PaginationLink)`
  color: #ea6000;
`;

const renderPaginationItem = (
  item: number | string,
  currentPage: number,
  totalPages: number,
  selectPage: (x: number | string | undefined) => void,
  key: number
) => {
  const active = item === currentPage;
  const previous = item === 'previous';
  const next = item === 'next';
  let disabled = item === '...';

  let text = typeof item === 'number' ? item + 1 : item;
  let to: number | string | undefined;
  switch (item) {
    case '...':
      to = undefined;
      break;
    case 'previous':
      to = currentPage - 1;
      break;
    case 'next':
      to = currentPage + 1;
      break;
    default:
      to = item;
  }

  if (previous && to && to < 0) disabled = true;
  if (next && to && to > totalPages - 1) disabled = true;

  return (
    <CsPaginationItem active={active} disabled={disabled} key={key}>
      <CsPaginationLink
        previous={previous}
        next={next}
        onClick={() => selectPage(to)}
      >
        {!previous && !next && text}
      </CsPaginationLink>
    </CsPaginationItem>
  );
};

const Pagination = (props: any) => {
  const totalPages: number = props.totalPages;
  const currentPage: number = props.currentPage;
  const onAction: (x: string | number | undefined) => void = props.onAction;

  if (typeof totalPages === 'undefined' || typeof currentPage === 'undefined')
    return null;

  return (
    <PaginationDiv>
      <CenteredPagination>
        {getPagination(totalPages, currentPage).map((item, idx) =>
          renderPaginationItem(item, currentPage, totalPages, onAction, idx)
        )}
      </CenteredPagination>
    </PaginationDiv>
  );
};

export default Pagination;
