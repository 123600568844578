import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import DeviceTabs from './components/Tabs/container';

import { Title } from '../../../Overview';
import { EventService } from '../../../../services/EventService';
import { Button as DownloadButton } from '../../../../components/Buttons';
import { DisplayScope } from '../../../../services/AuthenticationService';
import { matchesScope } from '../../../../components/DisplayController';
import { DeviceService } from '../../../../services/DeviceService';
import { RefetchService } from '../../../../services/RefetchService';
import { MeasurementService } from '../../../../services/MeasurementService';

type CSVRow = {
  time: string;
  type: string;
  payload?: string;
  data?: any;
  parsedMessage: string;
  positionUpdateTime?: string;
  lat?: number;
  lng?: number;
  positionDelta?: number;
  positionHdop?: number;
  resumeTimestamp?: string;
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopButton = styled(Button)`
  border: 1px solid blue;
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const PreviousDevice = styled<any>(ArrowLeft)`
  margin-right: 20px;
  &:hover {
    color: #ea6000;
    cursor: pointer;
  }
`;

const NextDevice = styled<any>(ArrowRight)`
  margin-left: 20px;
  &:hover {
    color: #ea6000;
    cursor: pointer;
  }
`;

const BackButton = styled<any>(TopButton)`
  background-color: #6c757d;
  border-color: #6c757d;
  text-align: center;
  width: 200px;
  margin-bottom: 20px;
`;

const Placeholder = styled.div`
  width: 200px;
`;

const DeviceNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const DeviceName = styled.div`
  justify-content: center;
  align-items: center;
  p {
    font-size: calc(16px + 0.5vw);
  }
`;

const DownloadButtonDiv = styled.div`
  width: 200px;
  padding: 10px;
  float: left;
`;

/** Page Content */
class HelperOverview extends Component<any, any> {
  state = {
    csvData: [],
    fileSuffix: 'events'
  };
  csvLink: React.RefObject<CSVLink> = React.createRef();

  componentDidMount() {
    const {
      requestConfig,
      requestHardwareInfo,
      requestDevice,
      requestDevices
    } = this.props;
    const deviceId = this.getDeviceId(this.props);
    requestDevices();
    if (typeof deviceId !== 'undefined') {
      requestHardwareInfo(deviceId);
      requestConfig(deviceId);
      requestDevice(deviceId);
    }

    const refetchService = RefetchService.instance;
    refetchService.addRefetchFunction({
      id: 'refetchDevice',
      func: this.refetchDevice.bind(this)
    });
  }

  componentWillUnmount() {
    const refetchService = RefetchService.instance;
    refetchService.removeRefetchFunction('refetchDevice');
  }

  async refetchDevice() {
    const deviceService = new DeviceService();
    const device = await deviceService.getDevice(this.getDeviceId(this.props));

    this.props.refetchedDevice(device);
  }

  componentWillReceiveProps(nextProps) {
    const { requestConfig, requestDevice, requestRecipients } = nextProps;

    const oldDeviceId = this.getDeviceId(this.props);
    const newDeviceId = this.getDeviceId(nextProps);

    if (newDeviceId && newDeviceId !== oldDeviceId) {
      requestConfig(newDeviceId);
      requestDevice(newDeviceId);
      requestRecipients(newDeviceId);

      const refetchService = RefetchService.instance;
      refetchService.addRefetchFunction({
        id: 'refetchDevice',
        func: this.refetchDevice
      });
    }
  }

  previousDevice(): number {
    const { deviceList } = this.props;

    const index = deviceList.devices.findIndex(
      (device) => device.id === this.getDeviceId(this.props)
    );

    if (index === 0) {
      return deviceList.devices.length - 1;
    } else {
      return index - 1;
    }
  }

  nextDevice(): number {
    const { deviceList } = this.props;

    const index = deviceList.devices.findIndex(
      (device) => device.id === this.getDeviceId(this.props)
    );

    if (index === deviceList.devices.length - 1) {
      return 0;
    } else {
      return index + 1;
    }
  }

  getDeviceIdentifier() {
    const { device } = this.props;

    if (!device.device) return '';

    return device.device.name
      ? device.device.name.slice(device.device.name.length - 6)
      : '';
  }

  getWearerName() {
    const { recipients } = this.props;
    let wearer = recipients.find(
      (recipient: any) => recipient.role === 'wearer'
    );

    if (wearer && (wearer.firstName || wearer.lastName)) {
      return `${wearer.firstName || ''} ${wearer.lastName || ''}`;
    }

    return 'Bitte Träger benennen';
  }

  getDeviceName() {
    const { device } = this.props;
    try {
      return (
        this.getWearerName() +
        ' - SicherheitsbegleiterID #: ' +
        this.getDeviceIdentifier()
      );
    } catch {
      const name = !device.loading && device.device ? device.device.name : '';

      const unifiedName = name
        .replace('Thingstream device ', '')
        .replace('SigFox device ', '');

      return `     ${unifiedName}`;
    }
  }

  getDeviceId(props): string {
    return props.match.params.deviceId;
  }

  async downloadEvents() {
    const eventService = new EventService();
    const events = await eventService.getEvents(this.getDeviceId(this.props));

    const rawCsvData: CSVRow[] = [];
    events.forEach((event) => {
      if (event.type === 'c8y_LocationUpdate') {
        if (
          rawCsvData.length === 0 ||
          typeof event.c8y_Position === 'undefined'
        )
          return;

        rawCsvData[rawCsvData.length - 1] = {
          ...rawCsvData[rawCsvData.length - 1],
          positionUpdateTime: event.time,
          lat: event.c8y_Position.lat,
          lng: event.c8y_Position.lng,
          positionHdop: event.c8y_Position.hdop,
          positionDelta: event.c8y_Position.delta
        };
        return;
      }

      const payloadTypes = [
        'secufy_ThingstreamMessageData',
        'secufy_ThingstreamMessageData_paused',
        'secufy_AlarmCreated',
        'secufy_AlarmAccepted',
        'secufy_AlarmClosed',
        'secufy_HelpersNotified',
        'secufy_WakeUpCall',
        'secufy_BatteryLowNotified',
        'secufy_emergencyRecipientNotified'
      ];

      // Delete MessageType in payload and replace semicolon with comma
      if (
        event.payload?.substring(0, 3) === '+D;' ||
        event.payload?.substring(0, 3) === '+C;' ||
        event.payload?.substring(0, 3) === '+B;' ||
        event.payload?.substring(0, 3) === '+A;'
      ) {
        // Remove Type
        event.payload = event.payload?.substring(3);

        // Replace ; with ,
        event.payload = event.payload?.replaceAll(';', ',');

        // Remove Point at the end of payload
        if (event.payload?.charAt(event.payload.length - 1) === '.') {
          event.payload = event.payload.substring(0, event.payload.length - 1);
        }

        console.log(event.payload);
      }

      let data = {};
      for (const type of payloadTypes) {
        if (event[type]) {
          data = event[type];
          break;
        }
      }

      rawCsvData.push({
        time: moment(event.time).utcOffset(0, true).toISOString(),
        type: event.type,
        payload: event.payload?.replaceAll('"', "'") || '-',
        data: JSON.stringify(data).replaceAll('"', "'") || '-',
        parsedMessage: event['parsedMessage']
          ? JSON.stringify(event['parsedMessage']).replaceAll('"', "'")
          : '-',
        resumeTimestamp: event.resumeTimestamp || '-'
      });
    });

    const csvData: (string | number)[][] = [
      [
        'time',
        'class',
        'type',
        'payload',
        'parsedMessage',
        'data',
        'resumeTimestamp'
      ]
    ];

    /*
        'eventId',
        'followUp',
        'numclicks',
        'ble',
       
        'positionUpdateTime',
        'lat',
        'lng',
        'positionHdop',
        'positionDelta'




csv-push--->
         event.event.eventId,
        event.followUp,
        event.numclicks,
        event.ble !== undefined ? event.ble + '' : '-',

        event.positionUpdateTime || '-',
        event.lat || '-',
        event.lng || '-',
        event.positionHdop || '-',
        event.positionDelta || '-'
*/

    function getThingstreamType(type: string) {
      var thingstreamType = 'Internal';
      if (type.startsWith('secufy_Reconfiguration')) {
        thingstreamType = 'Thingstream_out';
      }

      if (type.startsWith('secufy_Thingstream')) {
        thingstreamType = 'Thingstream_in';
      }
      return thingstreamType;
    }

    rawCsvData.forEach((event) => {
      csvData.push([
        event.time || '-',
        getThingstreamType(event.type) || '-',
        event.type || '-',
        event.payload || '-',
        event.parsedMessage,
        event.data || '-',
        event.resumeTimestamp || '-'
      ]);
    });

    this.setState({ csvData, fileSuffix: 'events' }, () => {
      setTimeout(() => this.csvLink.current.link.click());
    });
  }

  async downloadMeasurements() {
    const eventService = new MeasurementService();
    const measurements = await eventService.getMeasurements(
      this.getDeviceId(this.props),
      256,
      'secufy_Actipoints'
    );

    const csvData: (string | number)[][] = [
      ['time', 'interval', 'index', 'usage', 'actiPoints', 'steps']
    ];

    for (const measurement of measurements) {
      csvData.push([
        moment(measurement.time).utcOffset(0, true).toISOString() || '-',
        measurement.interval || '-',
        measurement.index || '-',
        measurement.usage || '-',
        measurement.actiPoints || '-',
        measurement.steps || '-'
      ]);
    }

    this.setState({ csvData, fileSuffix: 'measurements' }, () => {
      setTimeout(() => this.csvLink.current.link.click());
    });
  }

  render() {
    const { deviceList } = this.props;

    return (
      <Page>
        <Title widthBorder={false}>
          <BackButton onClick={() => this.props.history.push(`/`)}>
            Übersicht
          </BackButton>
          <DeviceNavigation>
            <PreviousDevice
              onClick={() =>
                this.props.history.push(
                  `/device/${deviceList.devices[this.previousDevice()].id}`
                )
              }
            />
            <DeviceName>
              <p>{this.getDeviceName()}</p>
            </DeviceName>
            <NextDevice
              onClick={() =>
                this.props.history.push(
                  `/device/${deviceList.devices[this.nextDevice()].id}`
                )
              }
            />
          </DeviceNavigation>
          {matchesScope(DisplayScope.SECUFY_ADMIN) ? (
            <div>
              <DownloadButtonDiv>
                <DownloadButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadEvents()}
                >
                  Events herunterladen
                </DownloadButton>
              </DownloadButtonDiv>
              <DownloadButtonDiv>
                <DownloadButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.downloadMeasurements()}
                >
                  Measurements herunterladen
                </DownloadButton>
              </DownloadButtonDiv>
              <CSVLink
                data={this.state.csvData}
                filename={`${moment().format(
                  'YYYYMMDDHHmmss'
                )}-${this.getDeviceIdentifier()}-${this.state.fileSuffix}.csv`}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
            </div>
          ) : (
            <Placeholder />
          )}
        </Title>

        <DeviceTabs />
      </Page>
    );
  }
}

// @ts-ignore
export default HelperOverview;
