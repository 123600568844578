import styled from 'styled-components';
import ReactSelect from 'react-select';

const CustomSelect = styled(ReactSelect)`
  .exo-select__indicator-separator {
    display: none;
  }
  width: 100%;

  margin-bottom: 20px;

  @media (max-width: 700px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ea6000' : '#ffffff',
    color: state.isSelected ? '#ffffff' : '#ea6000',
    fontFamily: 'Montserrat',
    fontSize: 15
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ea6000',
    fontFamily: 'Montserrat',
    fontSize: 15
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat',
    fontSize: 15
  })
};

const Select = ({
  value,
  onChange,
  options,
  isSearchable = true,
  isMulti = false,
  placeholder = 'Select Device...',
  isDisabled = false
}) => {
  return (
    <CustomSelect
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      styles={customStyles}
      isSearchable={isSearchable}
      //   className="secufy-select-container"
      //   classNamePrefix="exo-select"
      isMulti={isMulti}
      isDisabled={isDisabled}
    />
  );
};

export default Select;
