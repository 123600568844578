import { Component } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import InfoPopover from '../../../../../../components/InfoPopover';
import { Button } from 'reactstrap';
import { NumberInput } from '../Input';
import Select from '../Select';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Digit = styled.div`
  color: #707070;
  font-family: Montserrat;
  font-size: 40pt;
  cursor: default;
  }
`;

const Row = styled.div`
  display: flex;
  @media (min-width: 760px) {
    flex-direction: row;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
  color: #707070;
  font-family: Montserrat;
  padding-top: 35px;
  width: auto;
  max-width: 100%;
  margin-bottom: 25px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  color: #707070;
  font-family: Montserrat;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  padding-right: 250px;
`;

const InputWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  display: flex;
  max-width: 100px;
  @media (min-width: 760px) {
    margin-left: auto;
  }
  @media (max-width: 760px) {
    margin: auto;
  }
  margin-top: -7.25px;
`;

const Unit = styled.p`
  display: flex;
  margin-left: 5px;
  margin-top: 7.25px;
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const ClickableText = styled.p<any>`
  color: ${({ active }) => (active ? '#ea6000' : '#707070')};
  font-size: ${({ active }) => (active ? '16pt' : '12pt')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-family: Montserrat;
  width: auto;
  &:hover {
    //color: #ea6000;
    cursor: pointer;
  }
`;

const IndicationText = styled.p<any>`
  color: ${({ active }) => (active ? '#ea6000' : '#707070')};
  font-size: ${({ active }) => (active ? '16pt' : '12pt')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-family: Montserrat;
  width: auto;
  pointer-events: none;
`;

const StyledTypography = styled(Typography)`
  width: 100%;
`;

const TopButton = styled(Button)`
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const SaveChangesButton = styled<any>(TopButton)`
  background-color: #ea6000;
  border-color: #ea6000;

  margin-top: 35px;
  margin-left: auto;
  width: 150px;
`;

const FirstActiveDiv = styled.div`
  width: 250px;
`;

interface IConfigurationRow {
  id: string;
  heading: string;
  value: string | number | null;
  onChange: (value: number | string) => void;
  onBlur: (value: number | string) => void;
  isNumber?: boolean;
  isFloat?: boolean;
  isDouble?: boolean;
  unit?: string;
  info?: any;
}

const ConfigurationRow = ({
  id,
  heading,
  value,
  onChange,
  onBlur,
  unit = '',
  isNumber = false,
  isFloat = false,
  info = null
}: IConfigurationRow) => (
  <Row>
    <Description>
      {heading}
      {info && (
        <InfoPopover id={id} title={heading}>
          {info}
        </InfoPopover>
      )}
    </Description>
    <InputWrapper>
      {isNumber ? (
        <NumberInput
          name={heading}
          value={value}
          onChange={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onChange(parseInt(e.target.value, 10));
            }
          }}
          onBlur={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onBlur(parseInt(e.target.value, 10));
            }
          }}
        />
      ) : /*
        <ToggleSwitch
          color="default"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          name={heading}
          value={value}
        />
        */
      null}
      {isFloat ? (
        <NumberInput
          name={heading}
          value={value}
          onChange={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onChange(parseFloat(e.target.value));
            }
          }}
          onBlur={(e) => {
            if (typeof e.target.value !== 'undefined') {
              onBlur(parseFloat(e.target.value));
            }
          }}
        />
      ) : /*
        <ToggleSwitch
          color="default"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          name={heading}
          value={value}
        />
        */
      null}
      <Unit>{unit}</Unit>
    </InputWrapper>
  </Row>
);

const limitValue = (
  value: number | string,
  max: number,
  std: number,
  min: number
): number => {
  const v = typeof value === 'string' ? parseInt(value, 10) : value;
  if (v > max) return max;
  if (v <= min) return min;
  if (!v) return std;
  return v;
};

/** Page Content */
class ButtonSettings extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  getActiveMessagesOptions() {
    return [
      { label: '-', value: 0 },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6+', value: 6 }
    ];
  }
  isActiveMsg(msgClickCount: string): boolean {
    const { firstActiveMsg } = this.props;
    if (firstActiveMsg !== 0) {
      const parsedClickCount = parseInt(msgClickCount, 10);

      return parsedClickCount >= firstActiveMsg;
    } else return false;
  }

  getActiveBackendOptions() {
    return [
      { label: 'Produktivumgebung', value: 104 },
      { label: 'Testumgebung', value: 204 }
    ];
  }

  render() {
    const { buttonSettings, edit, save, isDefault } = this.props;

    const sendUpdate = () => {
      save({
        //TP: buttonSettings.trackingPeriod,
        //GPSTIMEOUT: getValue(gpsTimeout, GPSTIMEOUT),
        //GPSCAPTURES: getValue(gpsCaptures, GPSCAPTURES)
        // alertFollowUpMessages,
        // followUpMessageInterval
      });
    };

    function leadingZero(n: number) {
      return n < 10 ? '0' + n : n;
    }

    function checkTime_secondsToHours(value: number) {
      return Math.floor(value / 3600);
    }

    function checkTime_secondsToMinute(value: number) {
      return Math.floor((value % 3600) / 60);
    }

    function checkTime_minutesToHours(value: number) {
      return Math.floor(value / 60);
    }

    function checkTime_minutesToMinute(value: number) {
      return Math.floor(value % 60);
    }

    function checkTimeDelta() {
      if (buttonSettings.checkTime1 + 300 >= buttonSettings.checkTime2) {
        edit(
          'checkTime2',
          (buttonSettings.checkTime2 = buttonSettings.checkTime1 + 300)
        );
      }
    }

    function checkTime1_incrementHour() {
      if (buttonSettings.checkTime1 <= 3600 * 23) {
        edit(
          'checkTime1',
          (buttonSettings.checkTime1 = buttonSettings.checkTime1 + 3600)
        );
      }
      checkTimeDelta();
    }

    function checkTime1_decrementHour() {
      if (buttonSettings.checkTime1 > 3600) {
        edit(
          'checkTime1',
          (buttonSettings.checkTime1 = buttonSettings.checkTime1 - 3600)
        );
      }
      checkTimeDelta();
    }

    function checkTime1_incrementMinute() {
      if (
        Math.floor(buttonSettings.checkTime1 % 3600) < 3600 &&
        buttonSettings.checkTime1 < 86340
      ) {
        edit(
          'checkTime1',
          (buttonSettings.checkTime1 = buttonSettings.checkTime1 + 60)
        );
      }
      checkTimeDelta();
    }

    function checkTime1_decrementMinute() {
      if (buttonSettings.checkTime1 > 60) {
        edit(
          'checkTime1',
          (buttonSettings.checkTime1 = buttonSettings.checkTime1 - 60)
        );
      }
      checkTimeDelta();
    }

    function checkTime2_incrementHour() {
      if (buttonSettings.checkTime2 <= 3600 * 23) {
        edit(
          'checkTime2',
          (buttonSettings.checkTime2 = buttonSettings.checkTime2 + 3600)
        );
      }
      checkTimeDelta();
    }

    function checkTime2_decrementHour() {
      if (buttonSettings.checkTime2 > 3600) {
        edit(
          'checkTime2',
          (buttonSettings.checkTime2 = buttonSettings.checkTime2 - 3600)
        );
      }
      checkTimeDelta();
    }

    function checkTime2_incrementMinute() {
      if (
        Math.floor(buttonSettings.checkTime2 % 3600) < 3600 &&
        buttonSettings.checkTime2 < 86340
      ) {
        edit(
          'checkTime2',
          (buttonSettings.checkTime2 = buttonSettings.checkTime2 + 60)
        );
      }
      checkTimeDelta();
    }

    function checkTime2_decrementMinute() {
      if (buttonSettings.checkTime2 > 60) {
        edit(
          'checkTime2',
          (buttonSettings.checkTime2 = buttonSettings.checkTime2 - 60)
        );
      }
      checkTimeDelta();
    }

    function warningNotWearing_incrementHour() {
      if (buttonSettings.warningNotWearingTime <= 60 * 14 - 10) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime + 60)
        );
      }
    }

    function warningNotWearing_decrementHour() {
      if (buttonSettings.warningNotWearingTime >= 60 * 6 - 10) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime - 60)
        );
      }
    }

    function warningNotWearing_incrementMinute() {
      if (
        Math.floor(buttonSettings.warningNotWearingTime % 60) < 60 &&
        buttonSettings.warningNotWearingTime < 60 * 15 - 10
      ) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime + 1)
        );
      }
    }

    function warningNotWearing_decrementMinute() {
      if (buttonSettings.warningNotWearingTime > 60 * 5 - 10) {
        edit(
          'warningNotWearingTime',
          (buttonSettings.warningNotWearingTime =
            buttonSettings.warningNotWearingTime - 1)
        );
      }
    }

    function getCheckFrequency() {
      if (
        buttonSettings.useFirstDailyActivityMessage &&
        buttonSettings.useSecondDailyActivityMessage
      ) {
        return 2;
      }
      if (
        buttonSettings.useFirstDailyActivityMessage ||
        buttonSettings.useSecondDailyActivityMessage
      ) {
        return 1;
      }
      return 0;
    }
    function checkInterval_increment() {
      if (getCheckFrequency() === 0) {
        edit('useFirstDailyActivityMessage', true);
      }
      if (getCheckFrequency() === 1) {
        edit('useSecondDailyActivityMessage', true);
      }
    }

    function checkInterval_decrement() {
      if (getCheckFrequency() === 2) {
        edit('useSecondDailyActivityMessage', false);
      }
      if (getCheckFrequency() === 1) {
        edit('useFirstDailyActivityMessage', false);
      }
    }

    function getColor1() {
      if (getCheckFrequency() === 0) {
        return 'lightGrey';
      }
      return '#707070';
    }

    function getColor2() {
      if (getCheckFrequency() < 2) {
        return 'lightGrey';
      }
      return '#707070';
    }

    function getPointer1() {
      if (getCheckFrequency() === 0) {
        return 'none';
      }
      return 'auto';
    }

    function getPointer2() {
      if (getCheckFrequency() < 2) {
        return 'none';
      }
      return 'auto';
    }

    function getColor() {
      if (buttonSettings.warningNotWearing === false) {
        return 'lightGrey';
      }
      return '#707070';
    }

    function getPointer() {
      if (buttonSettings.warningNotWearing === false) {
        return 'none';
      }
      return 'auto';
    }

    return (
      <Div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <StyledTypography>
              Trageposition / Schrittkalibierung
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="wearingLocation"
                heading={'Trageposition'}
                value={buttonSettings.wearingLocation}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Definiert, wo am Körper der Secufy Sicherheitsbegleiter
                      getragen wird.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={!buttonSettings.wearingLocation}
                  onClick={() => edit('wearingLocation', false)}
                >
                  Hüfte
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.wearingLocation}
                  onClick={() => edit('wearingLocation', true)}
                >
                  Handgelenk
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="stepCalibration"
                heading={'Schrittkalibrierung'}
                value={buttonSettings.wearingLocation}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Getrennt einstellbar für Hüfte und Handgelenk, wobei
                      Schrittzählungen am Handgelenk deutlich ungenauer sind.
                      Ein Faktor 2 (100% Zuschlag) ist wegen der Umrechnung von
                      Doppelschritten auf Einzelschritten jeweils einzuplanen.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10%</PopoverLine>
                    <PopoverLine>
                      Standard: 300% (bzw. 200% bei Handgelenk){' '}
                    </PopoverLine>
                    <PopoverLine>Maximum: 1000% </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="stepsCaliFactorHip"
                heading={'Kalibrierungs-Faktor Hüfte'}
                value={buttonSettings.stepsCaliFactorHip}
                onBlur={(value) =>
                  edit('stepsCaliFactorHip', limitValue(value, 1000, 300, 10))
                }
                onChange={(value) => edit('stepsCaliFactorHip', value)}
                unit={'%'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Multiplikator zur individuellen Kalibration der vom
                      Sicherheitsbegleiter ermittelten Schrittzahl beim Tragen
                      an der Hüfte.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10%</PopoverLine>
                    <PopoverLine>Standard: 300%</PopoverLine>
                    <PopoverLine>Maximum: 1000% </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="stepsCaliFactorArm"
                heading={'Kalibrierungs-Faktor Handgelenk'}
                value={buttonSettings.stepsCaliFactorArm}
                onBlur={(value) =>
                  edit('stepsCaliFactorArm', limitValue(value, 1000, 200, 10))
                }
                onChange={(value) => edit('stepsCaliFactorArm', value)}
                unit={'%'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Multiplikator zur individuellen Kalibration der vom
                      Sicherheitsbegleiter ermittelten Schrittzahl beim Tragen
                      am Handgelenk.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10%</PopoverLine>
                    <PopoverLine>Standard: 200%</PopoverLine>
                    <PopoverLine>Maximum: 1000% </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <StyledTypography>Tageschecks</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="checkTime"
                heading={'Uhrzeit des Tagescheck'}
                value={null}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Tageszeitpunkt in hh:mm, an dem das Gerät eine
                      Bewegungsanalyse übermittelt.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <IconButton
                  style={{ color: getColor1(), pointerEvents: getPointer1() }}
                  aria-label="incrementHour"
                  onClick={checkTime1_incrementHour}
                >
                  <ArrowUp />
                </IconButton>
                <IconButton
                  style={{ color: getColor1(), pointerEvents: getPointer1() }}
                  aria-label="incrementMinute"
                  onClick={checkTime1_incrementMinute}
                >
                  <ArrowUp />
                </IconButton>
              </Container>

              <Container>
                <Digit style={{ color: getColor1() }}>
                  {leadingZero(
                    checkTime_secondsToHours(buttonSettings.checkTime1)
                  )}
                </Digit>
                <Digit style={{ color: getColor1() }}> : </Digit>
                <Digit style={{ color: getColor1() }}>
                  {leadingZero(
                    checkTime_secondsToMinute(buttonSettings.checkTime1)
                  )}
                </Digit>
              </Container>

              <Container>
                <IconButton
                  style={{ color: getColor1(), pointerEvents: getPointer1() }}
                  aria-label="decrementHour"
                  onClick={checkTime1_decrementHour}
                >
                  <ArrowDown />
                </IconButton>
                <IconButton
                  style={{ color: getColor1(), pointerEvents: getPointer1() }}
                  aria-label="decrementMinute"
                  onClick={checkTime1_decrementMinute}
                >
                  <ArrowDown />
                </IconButton>
              </Container>

              <br />

              <Container>
                <IconButton
                  style={{ color: getColor2(), pointerEvents: getPointer2() }}
                  aria-label="incrementHour"
                  onClick={checkTime2_incrementHour}
                >
                  <ArrowUp />
                </IconButton>
                <IconButton
                  style={{ color: getColor2(), pointerEvents: getPointer2() }}
                  aria-label="incrementMinute"
                  onClick={checkTime2_incrementMinute}
                >
                  <ArrowUp />
                </IconButton>
              </Container>

              <Container>
                <Digit style={{ color: getColor2() }}>
                  {leadingZero(
                    checkTime_secondsToHours(buttonSettings.checkTime2)
                  )}
                </Digit>
                <Digit style={{ color: getColor2() }}> : </Digit>
                <Digit style={{ color: getColor2() }}>
                  {leadingZero(
                    checkTime_secondsToMinute(buttonSettings.checkTime2)
                  )}
                </Digit>
              </Container>

              <Container>
                <IconButton
                  style={{ color: getColor2(), pointerEvents: getPointer2() }}
                  aria-label="decrementHour"
                  onClick={checkTime2_decrementHour}
                >
                  <ArrowDown />
                </IconButton>
                <IconButton
                  style={{ color: getColor2(), pointerEvents: getPointer2() }}
                  aria-label="decrementMinute"
                  onClick={checkTime2_decrementMinute}
                >
                  <ArrowDown />
                </IconButton>
              </Container>

              <ConfigurationRow
                id="checkInterval"
                heading={'Häufigkeit der Checks pro Tag'}
                value={getCheckFrequency()}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Standard 1x pro Tag am obigen Zeitpunkt. Kann auch zweimal
                      pro Tag, mit dem unteren Zeitpunkt auf Kosten von Akkuzeit
                      erfolgen. 0x schaltet den Zeitbezug aus, der
                      Sicherheitsbegleiter meldet sich trotzdem typischerweise
                      einmal täglich (ohne auf die Tageszeit zu achten).
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0x (Tagescheck Aus) </PopoverLine>
                    <PopoverLine>Standard: 1x </PopoverLine>
                    <PopoverLine>Maximum: 96x (Alle 15min) </PopoverLine>
                  </div>
                }
              />

              <Container>
                <IconButton
                  aria-label="decrement"
                  onClick={checkInterval_decrement}
                >
                  <ArrowLeft />
                </IconButton>

                <Digit>{getCheckFrequency()}x</Digit>
                <IconButton
                  aria-label="increment"
                  onClick={checkInterval_increment}
                >
                  <ArrowRight />
                </IconButton>
              </Container>
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Alarmeinstellungen</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="autoQuitAlarm"
                heading={'Alarme automatisch beenden'}
                value={buttonSettings.autoQuitAlarm}
                onBlur={(value) =>
                  edit('autoQuitAlarm', limitValue(value, 10080, 15, 5))
                }
                onChange={(value) => edit('autoQuitAlarm', value)}
                unit={'min'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Gibt die Dauer vor, nach der sich der angenommene Alarme
                      automatisch beendet werden.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 5min</PopoverLine>
                    <PopoverLine>Standard: 5min </PopoverLine>
                    <PopoverLine>Maximum: 10080 (1 Woche) </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="noMotionAccept"
                heading={'Warnung bei Nichttragen annehmbar trotz Bewegung'}
                value={buttonSettings.noMotionAccept}
                onBlur={(value) =>
                  edit('noMotionAccept', limitValue(value, 1440, 15, 5))
                }
                onChange={(value) => edit('noMotionAccept', value)}
                unit={'min'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Gibt die Dauer vor, nach der sich der angenommene Alarme
                      automatisch beendet werden.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 5min</PopoverLine>
                    <PopoverLine>Standard: 15min </PopoverLine>
                    <PopoverLine>Maximum: 1440min (1 Tag) </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>
        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Manuelle Alarme</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="followUpMessages"
                heading={
                  'Indoor - Anzahl der nachfolgenden Nachrichten nach auslösendem Alarm'
                }
                value={buttonSettings.followUpMessagesIndoor}
                onBlur={(value) =>
                  edit('followUpMessagesIndoor', limitValue(value, 255, 1, 0))
                }
                onChange={(value) => edit('followUpMessagesIndoor', value)}
                unit={'x'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Geänderte Positionsangaben werden angezeigt.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 0x (Keine GSM Ortung der Homezonesender){' '}
                    </PopoverLine>
                    <PopoverLine>Standard: 1x </PopoverLine>
                    <PopoverLine>Maximum: 255x </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="followUpMessages"
                heading={
                  'Outdoor - Anzahl der nachfolgenden Nachrichten nach auslösendem Alarm'
                }
                value={buttonSettings.followUpMessagesOutdoor}
                onBlur={(value) =>
                  edit('followUpMessagesOutdoor', limitValue(value, 255, 2, 0))
                }
                onChange={(value) => edit('followUpMessagesOutdoor', value)}
                unit={'x'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Geänderte Positionsangaben werden angezeigt. Bei
                      Einstellung 0x wird gar keine Position ermittelt, bei
                      Einstellung 1x nur ein ungenaue GSM-Zellen Ortung. Erst ab
                      2x wird eine exakte GPS-Ortung vorgenommen.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0x (GPS-Position aus) </PopoverLine>
                    <PopoverLine>Standard: 2x </PopoverLine>
                    <PopoverLine>Maximum: 255x </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="followUpMessagesTiming"
                heading={'Timing der nachfolgenden Nachrichten'}
                value={buttonSettings.followUpMessagesTiming}
                onBlur={(value) =>
                  edit(
                    'followUpMessagesTiming',
                    limitValue(value, 900, 180, 60)
                  )
                }
                onChange={(value) => edit('followUpMessagesTiming', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Zeitabstand zu welchem die Folgenachricht(en) zur neuen
                      Positionsermittlung übertragen wird.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 60s (1min) </PopoverLine>
                    <PopoverLine>Standard: 180s (3min) </PopoverLine>
                    <PopoverLine>Maximum: 900s (15min) </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Automatische Alarme</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="preAlertTime"
                heading={'Vorwarnzeit'}
                value={buttonSettings.preAlertTime}
                onBlur={(value) =>
                  edit('preAlertTime', limitValue(value, 255, 30, 5))
                }
                onChange={(value) => edit('preAlertTime', value)}
                unit={'s'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Bei einem automatischen Alarm durch Bewegungslosgigkeit
                      wird mittels Vibration vorgewarnt. Innerhalb der
                      eingestellten Vorwarnzeit kann der Alarm durch einfaches
                      Bewegen des Gerätes abgebrochen werden.{' '}
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 5s </PopoverLine>
                    <PopoverLine>Standard: 30s </PopoverLine>
                    <PopoverLine>Maximum: 255s </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="vibrationStrength"
                heading={'Vibrationsstärke'}
                value={buttonSettings.vibrationStrength}
                onBlur={(value) =>
                  edit('vibrationStrength', limitValue(value, 255, 100, 0))
                }
                onChange={(value) => edit('vibrationStrength', value)}
                unit={'%'}
                isNumber
                info={
                  <div>
                    <PopoverLine>Stärke der Vibrationsrückmeldung.</PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 0% (Schaltet den Vibrator aus){' '}
                    </PopoverLine>
                    <PopoverLine>Standard: 100% </PopoverLine>
                    <PopoverLine>
                      Maximum: 255% (2,55 mal so lange Vibration){' '}
                    </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="noMotionAlert"
                heading={'Alarm bei Bewegungslosigkeit'}
                value={buttonSettings.noMotionAlert}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Wenn das Gerät die eingestellte Zeit nicht bewegt wird,
                      z.B. nach Sturz mit Bewusstlosigkeit, löst es einen Alarm
                      aus.
                      <br />
                      Eine geringere eingestellte Zeit macht eine schnellere
                      Reaktionszeit, kann aber zu einer höherer Quote von
                      Fehlalarmen führen.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 180s (3min) </PopoverLine>
                    <PopoverLine>Standard: 3600s (1h) </PopoverLine>
                    <PopoverLine>Maximum: 65535s (18,2h) </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.noMotionAlert}
                  onClick={() => edit('noMotionAlert', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!buttonSettings.noMotionAlert}
                  onClick={() => edit('noMotionAlert', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="noMotionAlertTime"
                heading={'Zeit der Bewegungslosigkeit'}
                value={buttonSettings.noMotionAlertTime}
                onBlur={(value) =>
                  edit('noMotionAlertTime', limitValue(value, 65535, 3600, 180))
                }
                onChange={(value) => edit('noMotionAlertTime', value)}
                unit={'s'}
                isNumber
              />

              <ConfigurationRow
                id="noMotionAlertFlat"
                heading={
                  'Ausschalten des Alarms bei Bewegungslosigkeit wenn das Gerät flach abgelegt ist'
                }
                value={buttonSettings.noMotionAlertFlat}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Bei Standardeinstellung „An“ ist der Alarm bei
                      Bewegungslosigkeit abgeschaltet, wenn das Gerät flach
                      liegt. Bei Einstellung „Aus“ ist der Alarm nur dann
                      abgeschaltet, wenn das Gerät am Ladepad liegt (für den
                      Einsatz im Arbeitsschutz).
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={!buttonSettings.noMotionAlertFlat}
                  onClick={() => edit('noMotionAlertFlat', false)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.noMotionAlertFlat}
                  onClick={() => edit('noMotionAlertFlat', true)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="releaseAngle"
                heading={'Grenzwinkel'}
                value={buttonSettings.releaseAngle}
                onBlur={(value) =>
                  edit('releaseAngle', limitValue(value, 90, 10, 0))
                }
                onChange={(value) => edit('releaseAngle', value)}
                unit={'°'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Je kleiner der Winkel, desto exakter muss der
                      Sicherheitsbegleiter flach abgelegt werden, um den Alarm
                      auszuschalten. Der Wert soll aber möglichst niedrig sein,
                      damit im Alarmfall der Sicherheitsbegleiter auslöst, auch
                      wenn er nur wenig schräg liegt.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 0° </PopoverLine>
                    <PopoverLine>Standard: 10° </PopoverLine>
                    <PopoverLine>Maximum: 90° </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="releaseValueNoMotion"
                heading={'Grenzwert des Sensors für Bewegungslosigkeit'}
                value={buttonSettings.releaseValueNoMotion}
                onBlur={(value) =>
                  edit('releaseValueNoMotion', limitValue(value, 255, 100, 1))
                }
                onChange={(value) => edit('releaseValueNoMotion', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Je kleiner der Wert (vielfaches von 0.48mg), desto
                      geringere Bewegungen reichen aus, damit keine
                      Bewegungslosigkeit auslöst. Bei zu geringen Werte können
                      aber äußere Erschütterungen das Auslösen verhindern.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 1 </PopoverLine>
                    <PopoverLine>Standard: 100 </PopoverLine>
                    <PopoverLine>Maximum: 255 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="minTimeMotion"
                heading={'Mindestdauer der Bewegung'}
                value={buttonSettings.minTimeMotion}
                onBlur={(value) =>
                  edit('minTimeMotion', limitValue(value, 255, 5, 1))
                }
                onChange={(value) => edit('minTimeMotion', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Bei 1 reicht jede Bewegung über dem Grenzwert aus, damit
                      keine Bewegungslosigkeit auslöst. Bei höheren Werten
                      (vielfaches von 10ms) ist muss die Bewegung etwas länger
                      überschwellig sein.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 1 </PopoverLine>
                    <PopoverLine>Standard: 5 </PopoverLine>
                    <PopoverLine>Maximum: 255 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="releaseValueActivity"
                heading={'Grenzwert des Sensors für Aktivität'}
                value={buttonSettings.releaseValueActivity}
                onBlur={(value) =>
                  edit('releaseValueActivity', limitValue(value, 255, 150, 1))
                }
                onChange={(value) => edit('releaseValueActivity', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      je höher der Wert (vielfaches von 125mg), desto stärkere
                      Bewegungen sind erforderlich, um als Aktiviät registriert
                      zu werden. Der Wert muss deutlich höher sein, als der
                      Grenzwert des Sensors bei Bewegungslosigkeit.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 1 </PopoverLine>
                    <PopoverLine>Standard: 150 </PopoverLine>
                    <PopoverLine>Maximum: 255 </PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="minTimeActivity"
                heading={'Mindestdauer der Aktivität'}
                value={buttonSettings.minTimeActivity}
                onBlur={(value) =>
                  edit('minTimeActivity', limitValue(value, 255, 4, 1))
                }
                onChange={(value) => edit('minTimeActivity', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Bei 1 reicht jede Aktivität über dem Grenzwert aus, um als
                      Aktiviät registriert zu werden. Bei höheren Werten
                      (vielfaches von 10ms) muss die Aktivität etwas länger
                      überschwellig sein.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 1 </PopoverLine>
                    <PopoverLine>Standard: 4 </PopoverLine>
                    <PopoverLine>Maximum: 255 </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Automatische Warnungen</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="warningNotWearing"
                heading={'Warnung bei Nichttragen'}
                value={buttonSettings.warningNotWearing}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Das Gerät muss jeden Tag innerhalb der unten angegebenen
                      Uhrzeit bewegt werden, auch wenn das Gerät auf dem Ladepad
                      liegt, sonst wird eine Warnung ausgelöst.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <ClickableText
                  active={buttonSettings.warningNotWearing}
                  onClick={() => edit('warningNotWearing', true)}
                >
                  An
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={!buttonSettings.warningNotWearing}
                  onClick={() => edit('warningNotWearing', false)}
                >
                  Aus
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="warningNotWearingTime"
                heading={'Zeitpunkt für Warnung bei Nichttragen'}
                value={null}
                onBlur={() => {}}
                onChange={() => {}}
              />

              <Container>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="incrementHour"
                  onClick={warningNotWearing_incrementHour}
                >
                  <ArrowUp />
                </IconButton>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="incrementMinute"
                  onClick={warningNotWearing_incrementMinute}
                >
                  <ArrowUp />
                </IconButton>
              </Container>

              <Container>
                <Digit style={{ color: getColor() }}>
                  {leadingZero(
                    checkTime_minutesToHours(
                      buttonSettings.warningNotWearingTime
                    )
                  )}
                </Digit>
                <Digit style={{ color: getColor() }}> : </Digit>
                <Digit style={{ color: getColor() }}>
                  {leadingZero(
                    checkTime_minutesToMinute(
                      buttonSettings.warningNotWearingTime
                    )
                  )}
                </Digit>
              </Container>

              <Container>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="decrementHour"
                  onClick={warningNotWearing_decrementHour}
                >
                  <ArrowDown />
                </IconButton>
                <IconButton
                  style={{ color: getColor(), pointerEvents: getPointer() }}
                  aria-label="decrementMinute"
                  onClick={warningNotWearing_decrementMinute}
                >
                  <ArrowDown />
                </IconButton>
              </Container>

              <ConfigurationRow
                id="sensitivityDayRoutineWarning"
                heading={
                  'Sensitivität für Warnungen bei Abweichungen vom Tagesrhythmus'
                }
                value={buttonSettings.sensitivityDayRoutineWarning}
                onBlur={(value) =>
                  edit(
                    'sensitivityDayRoutineWarning',
                    limitValue(value, 255, 20, 10)
                  )
                }
                onChange={(value) =>
                  edit('sensitivityDayRoutineWarning', value)
                }
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      je niedriger der Wert, desto eher wird eine Warnung
                      ausgelöst, wenn das Aktivitätsprofil abweichend von den
                      historischen Werten ist.
                    </PopoverLine>
                    <br />
                    <PopoverLine>Minimum: 10 (1std.)</PopoverLine>
                    <PopoverLine>Standard: 20 (2std.)</PopoverLine>
                    <PopoverLine>Maximum: 255 (25,5std.)</PopoverLine>
                  </div>
                }
              />

              <ConfigurationRow
                id="inertiaOldDayRoutineValues"
                heading={'Trägheit alter Werte des Tagesrhythmus'}
                value={buttonSettings.inertiaOldDayRoutineValues}
                onBlur={(value) =>
                  edit(
                    'inertiaOldDayRoutineValues',
                    limitValue(value, 255, 50, 1)
                  )
                }
                onChange={(value) => edit('inertiaOldDayRoutineValues', value)}
                unit={''}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      je niedriger der Wert, desto weniger bedeutend sind alte
                      Werte des Tagesrhythmus und desto schneller passt die
                      Analyse sich neuen Gewohnheiten an.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 1 (alte Werte sind mit 0,1 gewichtet)
                    </PopoverLine>
                    <PopoverLine>
                      Standard: 50 (alte Werte sind mit 5 gewichtet)
                    </PopoverLine>
                    <PopoverLine>
                      Maximum: 255 (alte Werte sind mit 25,5 gewichtet)
                    </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Keep-Alive Time</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="keepAliveTime"
                heading={'Keep-Alive Time'}
                value={buttonSettings.keepAliveTime}
                onBlur={(value) =>
                  edit('keepAliveTime', limitValue(value, 65535, 1441, 0))
                }
                onChange={(value) => edit('keepAliveTime', value)}
                unit={'min'}
                isNumber
                info={
                  <div>
                    <PopoverLine>
                      Gibt die Dauer vor, nach der sich der Button mit dem
                      Server verbindet, wenn noch nicht aufgrund des Tagescheck
                      geschehen oder die Häufigkeit des Tagescheck auf 0
                      gestellt ist.
                    </PopoverLine>
                    <br />
                    <PopoverLine>
                      Minimum: 0min (Schaltet Keep-Alive aus)
                    </PopoverLine>
                    <PopoverLine>Standard: 1441min (1Tag+1Min) </PopoverLine>
                    <PopoverLine>Maximum: 65535min (45Tage) </PopoverLine>
                  </div>
                }
              />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>
        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>
              Alarmauslösung durch Mehrfachdrücken
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography>
              <ConfigurationRow
                id="firstActiveMsg"
                heading={'Mehrfach-Drücken'}
                value={buttonSettings.firstActiveMsg}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Gibt an, wie oft der Button kurz gedrückt werden muss, um
                      einen Alarm auszulösen.
                      <br />
                      <br />
                      Die Alarmauslösung durch langes drücken bleibt hiervon
                      unverändert.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <FirstActiveDiv>
                  <Select
                    isMulti={false}
                    options={this.getActiveMessagesOptions()}
                    value={this.getActiveMessagesOptions().find(
                      ({ value }) =>
                        value ===
                        (isDefault ? 3 : buttonSettings.firstActiveMsg)
                    )}
                    onChange={(option) => {
                      edit('firstActiveMsg', option.value);
                    }}
                    placeholder="Select active messages"
                  ></Select>
                </FirstActiveDiv>
              </Container>

              <ConfigurationRow
                id="firstActiveMsg"
                heading={'Info, ob Sonderfunktion(en) bei Doppelklick an sind'}
                value={buttonSettings.firstActiveMsg}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Orangen Fettdruck zeigt an, dass eine Sonderfunktion bei
                      Doppelklick ausgewählt ist, graue Normalschrift zeigt an,
                      dass diese Funktion nicht ausgewählt ist. Das An- und
                      Ausschalten dieser Sonderfunktionen bitte im Reiter
                      Einstellungen vornehmen: Automatische Alarme kurzzeitig
                      pausieren für die Siesta Funktion, Alles o.k., Alarme
                      stornieren für die O.k. Funktion.
                    </PopoverLine>
                  </div>
                }
              />

              <Container>
                <IndicationText active={buttonSettings.doubleClickDoNothing}>
                  Siesta-Funktion
                </IndicationText>
              </Container>
              <Container>
                <IndicationText active={buttonSettings.doubleClickReset}>
                  O.k.-Funktion
                </IndicationText>
              </Container>
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <p></p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <StyledTypography>Test Modus</StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography style={{ width: '100%' }}>
              <ConfigurationRow
                id="blinkMode"
                heading={'Blink Modus'}
                value={buttonSettings.blinkMode}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Reduzierter Blinkcode: beim Laden grün und bei 1x kurz
                      drücken Akkustandanzeige 1x bis 4x orange blinken.
                      Standard zusätzlich: Voralarm, Alarm, Batterie schwach,
                      Batterie voll.
                    </PopoverLine>
                  </div>
                }
              />
              <Container>
                <ClickableText
                  active={buttonSettings.blinkMode === false}
                  onClick={() => edit('blinkMode', false)}
                >
                  Standard
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.blinkMode === true}
                  onClick={() => edit('blinkMode', true)}
                >
                  Reduziert
                </ClickableText>
              </Container>

              <ConfigurationRow
                id="activeBackend"
                heading={'Backend Anbindung'}
                value={buttonSettings.activeBackend}
                onBlur={() => {}}
                onChange={() => {}}
                info={
                  <div>
                    <PopoverLine>
                      Definiert welches Backend vom Button verwendet wird.
                    </PopoverLine>
                  </div>
                }
              />
              <Container>
                <ClickableText
                  active={buttonSettings.activeBackend === 104}
                  onClick={() => edit('activeBackend', 104)}
                >
                  Produktivumgebung
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.activeBackend === 204}
                  onClick={() => edit('activeBackend', 204)}
                >
                  Testumgebung (Codestryke)
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.activeBackend === 304}
                  onClick={() => edit('activeBackend', 304)}
                >
                  Testumgebung (Secufy)
                </ClickableText>
              </Container>
              <Container>
                <ClickableText
                  active={buttonSettings.activeBackend === 404}
                  onClick={() => edit('activeBackend', 404)}
                >
                  Entwicklungsumgebung
                </ClickableText>
              </Container>
            </StyledTypography>
          </AccordionDetails>
        </Accordion>

        <SaveChangesButton onClick={() => sendUpdate()}>
          Speichern
        </SaveChangesButton>
      </Div>
    );
  }
}

export default ButtonSettings;
