//libraries

import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

const Message = styled.div`
  width: 100%;
  height: 500px;

  color: #7f8c8d;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const NotFound = (props) => {
  return (
    <Message>
      <h1>Page not found</h1>
      <h5>The page you requested was not found</h5>
      <Link to={`/`}>Back</Link>
    </Message>
  );
};

export default withRouter(NotFound);
