import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ApproveRecipient from '..';
import {
  approveRecipient,
  confirmEmailTokenExpiredEmailResent
} from '../actions';

const mapStateToProps = (state, ownProps) => ({
  verifying: state.approveRecipient.verifying,
  verified: state.approveRecipient.verified,
  tokenExpiredEmailResent: state.approveRecipient.tokenExpiredEmailResent,
  verifyFailed: state.approveRecipient.verifyFailed
});

const mapDispatchToProps = (dispatch) => ({
  approveRecipient: (token) => dispatch(approveRecipient(token)),
  confirmEmailTokenExpiredEmailResent: (token) =>
    dispatch(confirmEmailTokenExpiredEmailResent(token))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApproveRecipient)
);
