import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {};

export default handleActions(
  {
    [types.REQUEST_EVENT]: (state, { payload }) => ({
      ...state
    }),
    [types.REQUEST_EVENT_SUCCEEDED]: (state, { payload }) => {
      return payload;
    },
    [types.REQUEST_EVENT_FAILED]: (state, { payload }) => ({
      ...state
    })
  },
  initialState
);
