import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { recipientStartEditing, requestRecipients } from '../../../actions';

import {
  editGeneralSettings,
  saveGeneralSettings,
  editAndSaveGeneralSettings,
  requestButtonInfo
} from '../../../actions';

import EditModal from '..';
import { getRecipients } from '../../Tabs/selectors';

const mapStateToProps = (state, ownProps) => ({
  recipients: getRecipients(state, ownProps),
  settings: state.generalSettings,
  device: state.device,
  owner: state.owner,
  order: state.order
});

const mapDispatchToProps = (dispatch) => ({
  requestButtonInfo: (id) => dispatch(requestButtonInfo(id)),
  requestRecipients: (deviceId) => dispatch(requestRecipients(deviceId)),
  openHelperModal: (recipient) => dispatch(recipientStartEditing(recipient)),
  save: () => dispatch(saveGeneralSettings()),
  edit: (scope, value) => dispatch(editGeneralSettings({ scope, value })),
  editAndSave: (scope, value) =>
    dispatch(editAndSaveGeneralSettings({ scope, value }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditModal)
);
