import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { updateAddressAllBeacons, updateBeacon } from '../../actions';
import Checkbox from '../Checkbox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: 25px;
`;

const TopButton = styled(Button)`
  &:hover {
    background-color: #d35400;
    border-color: #d35400;
    outline: #d35400;
  }
`;

const SaveChangesButton = styled<any>(TopButton)`
  background-color: #ea6000;
  border-color: #ea6000;
  width: 150px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  font-family: Montserrat;
  margin-bottom: 35px;
`;

const TitleCol = styled.div`
  display: flex;
  width: auto;
  margin: auto;
  margin-left: 0px;
  margin-right: 30px;
  width: 120px;
`;

const ContentCol = styled.div`
  display: flex;
  margin-right: 0px;
  width: 250px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const CheckDiv = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 600px;
`;

const Text = styled.p<any>`
  color: #707070;
  font-size: 15px;
  font-family: 'Montserrat';
`;

type BeaconType = {
  location: string;
  address: string;
  postalCode: string;
  town: string;
  checked: boolean;
  showCheckbox: boolean;
};

/** Page Content */
const Beacon = (props) => {
  const { id, location, major, minor } = props;
  const dispatch = useDispatch();

  const [state, setState] = useState<BeaconType>({
    ...props,
    showCheckbox: false,
    checked: false
  });

  useEffect(() => {
    if (!state.checked && !state.showCheckbox) {
      //workaround: otherwhise first keyboardinput will be reverted/overwritten with old state!!!
      setState((prevState) => {
        return { ...prevState, ...props };
      });
    } else {
      setState((prevState) => {
        return { ...prevState };
      });
    }
  }, [props, state.checked, state.showCheckbox]);

  return (
    <Container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {id} Secufy_{major}_{minor} - {location ? location : ''}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Row>
              <TitleCol>Bezeichnung</TitleCol>
              <ContentCol>
                <TextField
                  style={{ width: '250px' }}
                  value={state.location}
                  onChange={(e) =>
                    setState({
                      ...state,
                      location: e.target.value,
                      showCheckbox: true
                    })
                  }
                >
                  {' '}
                </TextField>
              </ContentCol>
            </Row>

            <Row>
              <TitleCol>Straße & Nr</TitleCol>
              <ContentCol>
                <TextField
                  style={{ width: '250px' }}
                  value={state.address}
                  onChange={(e) =>
                    setState({
                      ...state,
                      address: e.target.value,
                      showCheckbox: true
                    })
                  }
                >
                  {' '}
                </TextField>
              </ContentCol>
            </Row>

            <Row>
              <TitleCol>PLZ</TitleCol>
              <ContentCol>
                <TextField
                  style={{ width: '250px' }}
                  value={state.postalCode}
                  onChange={(e) =>
                    setState({
                      ...state,
                      postalCode: e.target.value,
                      showCheckbox: true
                    })
                  }
                >
                  {' '}
                </TextField>
              </ContentCol>
            </Row>

            <Row>
              <TitleCol>Ort</TitleCol>
              <ContentCol>
                <TextField
                  style={{ width: '250px' }}
                  value={state.town}
                  onChange={(e) => {
                    setState({
                      ...state,
                      town: e.target.value,
                      showCheckbox: true
                    });
                  }}
                >
                  {' '}
                </TextField>
              </ContentCol>
            </Row>

            <Row>
              <CheckboxWrapper>
                <SaveChangesButton
                  color="primary"
                  type={'submit'}
                  onClick={() => {
                    dispatch(
                      updateBeacon({
                        ...state,
                        major,
                        minor
                      })
                    );
                    if (state.checked) {
                      dispatch(
                        updateAddressAllBeacons({
                          address: state.address,
                          postalCode: state.postalCode,
                          town: state.town
                        })
                      );
                      state.showCheckbox = false;
                      state.checked = false;
                    }
                  }}
                >
                  Speichern
                </SaveChangesButton>

                {state.showCheckbox ? (
                  <CheckDiv>
                    <Checkbox
                      checked={state.checked}
                      onChange={() =>
                        setState({
                          ...state,
                          checked: !state.checked
                        })
                      }
                    ></Checkbox>
                    <Text>
                      Adresse für alle anderen Homezone-Sender übernehmen
                    </Text>
                  </CheckDiv>
                ) : null}
              </CheckboxWrapper>
            </Row>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

// @ts-ignore
export default sizeMe({ monitorHeight: true, noPlaceholder: true })(Beacon);
