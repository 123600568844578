import { handleActions } from 'redux-actions';
import types from '../types';

interface IActivityState {
  [deviceId: string]: {
    loading: boolean;
    usage: number;
  };
}


const initialState: 
IActivityState = {}
;



export default handleActions(
  {
    [types.REQUEST_ACTIVITY]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: true,
        usage: 0,
        steps: 0
      }
    }),
    [types.REQUEST_ACTIVITY_SUCCEEDED]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: false,
        usage: action.payload.activity[0],
        steps: action.payload.activity[1]
      }
    }),
    [types.REQUEST_ACTIVITY_FAILED]: (state, action: any) => ({
      ...state,
      [action.payload.deviceId]: {
        ...state[action.payload.deviceId],
        loading: false
      }
    }),




  },


  initialState
);
