import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { recipientStartEditing } from '../../../actions';

import DeviceTabs from '..';
import { getRecipients } from '../selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    recipients: getRecipients(state, ownProps),
    overview: state.deviceOverview,
    device: state.device
  };
};

const mapDispatchToProps = (dispatch) => ({
  openHelperModal: (recipient) => dispatch(recipientStartEditing(recipient))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceTabs)
);
