import { Component } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px #e2e2e2

  margin-bottom: 25px;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  color: #707070;
  font-size: 20px;
  margin-top: 10px;
  font-family: 'Montserrat';
  margin-bottom: '10px';
`;

const Note = styled.p`
  color: #707070;
  font-size: 14px;
`;

/** Page Content */

function getText(role) {
  if (role === 'helper') {
    return 'Klicken Sie hier, um eine vertraute Person als Sicherheitskontakt hinzuzufügen.';
  }
  if (role === 'wearer') {
    return 'Klicken Sie hier, um den Träger des Sicherheitsbegleiter hinzuzufügen.';
  }
  if (role === 'emergencyRecipient') {
    return 'klicken Sie hier, um einen Notfallkontakt hinzuzufügen.';
  }
}

class EmptyHelper extends Component<any, any> {
  render() {
    return (
      <Container onClick={this.props.add}>
        <Title>{this.props.title}</Title>
        <Note>{getText(this.props.role)}</Note>
      </Container>
    );
  }
}

export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  EmptyHelper
);
