import { PushNotifications } from '@capacitor/push-notifications';
import getEnvVar from '../getEnvVar';
import { RestService } from '../RestService';

enum NotificationPlatform {
  'ios' = 'apn',
  'android' = 'fcm'
}

class PushNotificationService extends RestService {
  init(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const platform = getEnvVar('REACT_APP_PLATFORM') || 'web';
      if (platform !== 'ios' && platform !== 'android') reject();

      await PushNotifications.addListener('registration', (token) => {
        console.info('Registration token: ', token.value);
        resolve(token.value);
      });

      await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log('Push notification received: ', notification);
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          console.log(
            'Push notification action performed',
            notification.actionId,
            notification.inputValue
          );
        }
      );

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    });
  }

  async storeToken(user: string, token: string): Promise<boolean> {
    const url = `${this.baseUrl}/apptoken`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        user,
        token,
        platform: NotificationPlatform[getEnvVar('REACT_APP_PLATFORM')!],
        appVersion: getEnvVar('REACT_APP_VERSION')
      })
    });

    if (response.status >= 200 && response.status <= 299) {
      return true;
    }

    return false;
  }
}

export default PushNotificationService;
