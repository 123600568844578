import { useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import LoadingIndicators from '../../components/LoadingIndicator';
import WithAuthentication from '../../components/WithAuthentication';

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

/** Page Content */
const VerifyEmail = ({
  confirmEmail,
  resendEmail,
  verifying,
  verified,
  tokenExpiredEmailResent,
  verifyFailed
}) => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  if (token) {
    if (!verifying && !verified && !verifyFailed && !tokenExpiredEmailResent) {
      confirmEmail(token);
    }

    if (verifying) {
      return (
        <Layout>
          <CenteredDiv>
            <LoadingIndicators />
          </CenteredDiv>
        </Layout>
      );
    }

    if (tokenExpiredEmailResent) {
      return (
        <Layout>
          <CenteredDiv>
            <p>
              Dein Bestätigungslink ist abgelaufen. Du erhälst in wenigen
              Minuten eine neue Bestätigungsemail.
            </p>
          </CenteredDiv>
        </Layout>
      );
    }

    if (verifyFailed) {
      return (
        <Layout>
          <CenteredDiv>
            <p>Verifizierungslink ist ungültig.</p>
          </CenteredDiv>
        </Layout>
      );
    }

    if (verified) {
      return (
        <Layout>
          <CenteredDiv>
            <p>Deine Email Adresse wurde erfolgreich verifiziert</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/')}
            >
              Weiter zur Übersicht
            </Button>
          </CenteredDiv>
        </Layout>
      );
    }
  }

  return (
    <WithAuthentication>
      <Layout>
        <CenteredDiv>
          <p>Bitte verifiziere deine Email Adresse.</p>
          <p></p>
          <p>Keine Email erhalten?</p>
          {
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resendEmail();
              }}
            >
              Erneut senden
            </Button>
          }
        </CenteredDiv>
      </Layout>
    </WithAuthentication>
  );
};

export default VerifyEmail;
