import styled from 'styled-components';

export const ButtonDiv = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const LogoImg = styled.img`
  width: 400px;
  height: auto;
  margin-bottom: 40px;
  object-fit: contain;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  padding-top: 60px;
  width: 100%;

  overflow-y: scroll;

  @media (max-width: 700px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    height: 100%;
  }
`;

export const Panel = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 600px;
  background-color: #ffffff;
  box-shadow: 1px 0px 4px #e2e2e2;

  @media (max-width: 700px) {
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }
`;

export const LinkText = styled.p`
  color: #707070;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 15px;

  font-family: 'Montserrat';
  &:hover {
    cursor: pointer;
  }
`;

export const Link = styled.span`
  text-decoration: underline #707070;
  margin-bottom: 0px;
`;
