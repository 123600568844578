import { put, takeEvery, call } from 'redux-saga/effects';

import types from '../types';
import {
  approveRecipientSucceeded,
  approveRecipientFailed,
  confirmEmailTokenExpiredEmailResent
} from '../actions';
import {
  RecipientService,
  ConfirmMailState
} from '../../../services/RecipientService';

function* approveRecipient(action) {
  try {
    const recipientService = new RecipientService();
    const confirmMailState = yield call(
      recipientService.approveRecipient.bind(recipientService),
      action.payload
    );

    if (confirmMailState === ConfirmMailState.SUCCESS)
      yield put(approveRecipientSucceeded());
    else if (confirmMailState === ConfirmMailState.TOKEN_EXPIRED_AND_MAIL_SENT)
      yield put(confirmEmailTokenExpiredEmailResent());
    else yield put(approveRecipientFailed());
  } catch (error) {
    yield put(approveRecipientFailed());
  }
}

const sagas = [takeEvery(types.APPROVE_RECIPIENT, approveRecipient)];

export default sagas;
