import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  firstLoad: true,
  loading: false
};

export default handleActions(
  {
    [types.REQUEST_RECIPIENTS]: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    [types.REQUEST_RECIPIENTS_SUCCEEDED]: (state, { payload }) => ({
      ...state,
      firstLoad: false,
      loading: true
    }),
    [types.REQUEST_RECIPIENTS_FAILED]: (state, { payload }) => ({
      ...state,
      loading: false
    })
  },
  initialState
);
