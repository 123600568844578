import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { editTrackingSettings, saveTrackingSettings } from '../../../actions';

import TrackingSettings from '..';

const mapStateToProps = (state, ownProps) => ({
  trackingSettings: state.trackingSettings
});

const mapDispatchToProps = (dispatch) => ({
  edit: (scope, value) => dispatch(editTrackingSettings({ scope, value })),
  save: () => dispatch(saveTrackingSettings())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrackingSettings)
);
