import moment from 'moment';
import { C8yBaseService } from '../C8yBaseService';

interface IEventCollection {
  events: [IEvent];
}

type IEvent = {
  count: number;
  creationTime: string;
  id: string;
  self: string;
  severity: string;
  source: { id: string; name: string; self: string };
  status: string;
  text: string;
  time: string;
  type: string;
  resumeTimestamp: string;
  c8y_Position?: {
    lat: number;
    lng: number;
    hdop: number;
    delta: number;
  };
  payload?: string;
  secufy_ThingstreamMessageData?: {
    eventId: number;
    numclicks: number;
    followUp: number;
  };
};

export class EventService extends C8yBaseService {
  private async fetchEventsFromCumulocity(
    deviceId: string,
    dateFrom: string,
    count: number,
    currentPage: number
  ) {
    console.log('fetching page', currentPage);
    let url = `${this.baseUrl}/event/events`;

    url = `${url}?source=${deviceId}`;
    url = `${url}&pageSize=${count}&currentPage=${currentPage}`;
    url = `${url}&dateFrom=${dateFrom}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(encodeURI(url), {
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    if (response.status >= 200 && response.status <= 299) {
      return ((await response.json()) as IEventCollection).events;
    }
    return [];
  }

  async getEvents(
    deviceId: string,
    days: number = 256,
    count: number = 65536 // Cumulocity will set this to 2000 anyways
  ): Promise<IEvent[]> {
    const dateFrom = encodeURIComponent(
      moment().subtract(days, 'days').format('YYYY-MM-DD')
    );

    let currentPage = 1;
    let events: Array<any> = [];
    let newEvents: Array<IEvent> = [];
    do {
      newEvents = await this.fetchEventsFromCumulocity(
        deviceId,
        dateFrom,
        count,
        currentPage
      );
      events = events.concat(newEvents);
      currentPage++;
    } while (newEvents.length && events.length < count);

    return events;
  }

  async getLatestEvent(deviceId: string, type: string): Promise<IEvent | null> {
    const now = encodeURIComponent(
      moment().utc().format('YYYY-MM-DDTHH:mm:ssZ')
    );

    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/event/events?dateTo=${now}&pageSize=1&source=${deviceId}&type=${type}&withSourceAssets=true&withSourceDevices=true`,
      {
        headers: {
          Authorization,
          'Content-Type': 'application/json'
        },
        method: 'GET'
      }
    );
    if (response.status >= 200 && response.status <= 299) {
      const { events } = (await response.json()) as IEventCollection;

      if (events.length > 0) {
        return events[0];
      }

      return null;
    }
    return null;
  }

  async getLatestEvents(
    deviceId: string,
    type: string,
    count: number = 1
  ): Promise<IEvent[]> {
    const now = encodeURIComponent(
      moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    );

    const Authorization = await this.getAuthHeader();
    const response = await fetch(
      `${this.baseUrl}/event/events?dateTo=${now}&pageSize=${count}&source=${deviceId}&type=${type}&withSourceAssets=true&withSourceDevices=true`,
      {
        headers: {
          Authorization,
          'Content-Type': 'application/json'
        },
        method: 'GET'
      }
    );
    if (response.status >= 200 && response.status <= 299) {
      const { events } = (await response.json()) as IEventCollection;
      return events;
    }
    return [];
  }
}
