import { put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import types from '../types';
import {
  autoAcceptAlarmFailed,
  autoAcceptAlarmSucceeded,
  addButtonFailed,
  addButtonSucceeded
} from '../actions';
import {
  ButtonService,
  AddButtonResponse
} from '../../../services/ButtonService';
import { AlarmService } from '../../../services/AlarmService';
import { HTTPError } from '../../../services/HttpService';

const getSearch = (state) => state.router.location.search;

function* addButton(action) {
  try {
    const buttonService = new ButtonService();
    const success: AddButtonResponse = yield call(
      buttonService.addButton.bind(buttonService),
      action.payload.buttonId,
      action.payload.buyerEmail
    );

    if (success === 'failure') {
      throw new Error('Failed to add button!');
    }

    if (success === 'emailSent') {
      toast.success(
        'Der Sicherheitsbegleiter ist bereits in Verwendung. Dem Verwalter wurde eine Email gesendet. Sobald dieser der Anfrage zugestimmt hat, wird der Sicherheitsbegleiter Ihrem Account zugeordnet.',
        {
          position: 'top-right'
        }
      );
    }

    if (success === 'success') {
      toast.success('Der Sicherheitsbegleiter wurde erfolgreich hinzugefügt.', {
        position: 'top-right'
      });
    }

    yield put(addButtonSucceeded());
  } catch (error) {
    console.log(error);
    toast.error(
      'Der Sicherheitsbegleiter konnte nicht hinzugefügt werden. Versuche es später erneut.',
      {
        position: 'top-right',
        toastId: 300
      }
    );
    yield put(addButtonFailed());
  }
}

function* acceptAlarm(action) {
  try {
    const alarmService = new AlarmService();

    const search = yield select(getSearch);
    const params = new URLSearchParams(search);

    yield call(alarmService.acceptAlarm.bind(alarmService), action.payload);
    toast.success(
      `Der Alarm wurde erfolgreich ${
        params.get('close') ? 'abgeschlossen' : 'angenommen'
      }.`,
      {
        position: 'top-right'
      }
    );

    params.delete('acceptAlarm');
    params.delete('token');
    params.delete('asEmergencyRecipient');
    params.delete('close');
    const newSearch = params.toString();

    yield put(push({ pathname: '/', search: `?${newSearch}` }));

    yield put(autoAcceptAlarmSucceeded());
  } catch (error: any) {
    console.error(error);

    // Don't show error if user isn't logged in
    if (error instanceof HTTPError && error.statusCode === 400) {
      toast.error('Der Alarm ist bereits geschlossen worden.', {
        position: 'top-right',
        toastId: 300
      });
    } else if (error instanceof HTTPError && error.statusCode === 401) {
      toast.info('Bitte loggen Sie sich ein, um den Alarm anzunehmen.', {
        position: 'top-right',
        toastId: 301
      });
    } else if (error instanceof HTTPError && error.statusCode === 403) {
      toast.info('Sie sind nicht berechtigt, den Alarm anzunehmen.', {
        position: 'top-right',
        toastId: 300
      });
    } else {
      toast.error(
        'Der Alarm konnte nicht angenommen werden. Versuchen Sie es später erneut.',
        {
          position: 'top-right',
          toastId: 300
        }
      );
    }

    yield put(autoAcceptAlarmFailed());
  }
}

const sagas = [
  takeEvery(types.ADD_BUTTON, addButton),
  takeEvery(types.AUTO_ACCEPT_ALARM, acceptAlarm)
];

export default sagas;
