import styled from 'styled-components';

const InputField = styled.div`
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #707070;
  margin-bottom: 10px;
`;

const Input = styled.input<IDefaultInput>`
  color: #707070;
  background-color: transparent;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
  font-family: 'Montserrat';
  outline: none;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -webkit-text-fill-color: #707070;
  -webkit-opacity: 1;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
`;

interface IInput {
  name: string;
  value: string | number | null;
  alignRight?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
}

interface IDefaultInput extends IInput {
  type: string;
}

const DefaultInput = ({
  type,
  value,
  onChange = () => {},
  name = 'Input',
  alignRight = false,
  disabled = false,
  onBlur = () => {}
}: IDefaultInput) => (
  <InputField>
    <Input
      name={name}
      type={type}
      // @ts-ignore TODO
      value={value}
      alignRight={alignRight}
      disabled={disabled}
      onChange={(e) => onChange(e)}
      onBlur={(e) => onBlur(e)}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          onBlur(e);
        }
      }}
    ></Input>
  </InputField>
);

const TextInput = (props: IInput) => <DefaultInput type={'text'} {...props} />;

const NumberInput = (props: IInput) => (
  <DefaultInput type={'number'} alignRight {...props} />
);

export { DefaultInput as Input, NumberInput, TextInput };
