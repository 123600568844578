import { C8yBaseService } from '../C8yBaseService';

export class ThingstreamButtonConfigService extends C8yBaseService {
  protected setBaseUrl() {
    if (process.env.REACT_APP_PLATFORM === 'web') {
      this.baseUrl = '/app/v1/configure/thingstreambutton';
    } else {
      if (!process.env.REACT_APP_ENDPOINT)
        throw new Error(
          'REACT_APP_ENDPOINT env variable must be provided for mobile platforms!'
        );
      this.baseUrl = `${process.env.REACT_APP_ENDPOINT}/app/v1/configure/thingstreambutton`;
    }
  }

  async update(
    deviceId: string,
    type: string,
    payload: object
  ): Promise<boolean> {
    const url = `${this.baseUrl}/${deviceId}/${type}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(payload)
    });

    return response.status >= 200 && response.status <= 299;
  }
}
