import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  checkTime1: 3600,
  checkTime2: 54000,
  useFirstDailyActivityMessage: true,
  useSecondDailyActivityMessage: true,
  followUpMessagesIndoor: 1,
  followUpMessagesOutdoor: 2,
  followUpMessagesTiming: 180,
  preAlertTime: 30,
  vibrationStrength: 100,
  warningNotWearing: true,
  warningNotWearingTime: 540,
  wearingLocation: 0,
  stepsCaliFactorHip: 300,
  stepsCaliFactorArm: 200,
  doubleClickReset: false,
  doubleClickDoNothing: false,
  siestaTime: 7200,
  automaticCallBeforeAlarm: 2,
  noMotionAlert: true,
  noMotionAlertTime: 3600,
  noMotionAlertFlat: false,
  releaseAngle: 10,
  releaseValueNoMotion: 100,
  minTimeMotion: 5,
  releaseValueActivity: 150,
  minTimeActivity: 4,
  sensitivityDayRoutineWarning: 20,
  inertiaOldDayRoutineValues: 50,
  keepAliveTime: 1441,
  autoQuitAlarm: 15,
  noMotionAccept: 15,
  firstActiveMsg: 0,
  activeBackend: process.env.REACT_APP_DEFAULT_BACKEND || 104,
  blinkMode: false,
  pauseManualAlarm: true,
  pauseAutomaticAlarm: true,
  pauseDayCheck: true,
  pauseBatteryWarning: true,
  pausePeriodFrom: '',
  pausePeriodTo: ''
};

export default handleActions(
  {
    [types.GET_DEVICE_SUCCEEDED]: (state, action: any) => {
      if (!action.payload.secufy_ButtonSettings) return initialState;

      let newState = action.payload.secufy_ButtonSettings;

      if (newState.warningNotWearingTime > 1440)
        newState.warningNotWearingTime = 540;

      Object.keys(initialState).forEach((key) => {
        if (typeof newState[key] === 'undefined')
          newState[key] = initialState[key];
      });

      return newState;
    },

    [types.EDIT_BUTTON_SETTINGS]: (state, action: any) => {
      const { scope, value } = action.payload;
      return {
        ...state,
        [scope]: value
      };
    }
  },
  initialState
);
