import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectDevice } from '../../../../Device/components/HelperOverview/actions';

import Map from '..';

const mapStateToProps = (state, ownProps) => ({
  selectedDeviceId: state.selectedDevice,
  devices: state.deviceList.devices
});

const mapDispatchToProps = (dispatch) => ({
  selectDevice: (id) => dispatch(selectDevice(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
