import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { Panel, ButtonDiv, LogoImg, Content } from '../Panel';
import { FullWidthButton } from '../Buttons';
import Logo from '../../assets/logo.png';
import getEnvVar from '../../services/getEnvVar';

const SubheadingContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

function getUpdateURL() {
  const platform = getEnvVar('REACT_APP_PLATFORM')!;

  if (platform === 'ios') {
    return 'https://apps.apple.com/de/app/secufy-connect/id1568550787';
  }
  if (platform === 'android') {
    return 'https://play.google.com/store/apps/details?id=com.secufy.secufyconnect';
  }
}

const InfoOverlay = ({ content1, content2 }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  if (open)
    return (
      <Backdrop open={true} className={classes.backdrop}>
        <Container>
          <Panel>
            <Content>
              <LogoImg src={Logo} alt="logo" />
              <SubheadingContainer>
                <strong> {content1} </strong>
              </SubheadingContainer>

              <SubheadingContainer>
                <ButtonDiv>
                  <FullWidthButton
                    variant="contained"
                    color="primary"
                    onClick={() => openInNewTab('' + getUpdateURL() + '')}
                  >
                    Aktualisieren
                  </FullWidthButton>
                </ButtonDiv>
              </SubheadingContainer>

              <SubheadingContainer>{content2}</SubheadingContainer>
              <ButtonDiv>
                <FullWidthButton
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    setOpen(false);
                  }}
                >
                  Bestätigen
                </FullWidthButton>
              </ButtonDiv>
            </Content>
          </Panel>
        </Container>
      </Backdrop>
    );
  else return <></>;
};

export default InfoOverlay;
