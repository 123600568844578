export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

export const APPROVE_RECIPIENT = 'APPROVE_RECIPIENT';
export const APPROVE_RECIPIENT_SUCCEEDED = 'APPROVE_RECIPIENT_SUCCEEDED';
export const APPROVE_RECIPIENT_FAILED = 'APPROVE_RECIPIENT_FAILED';
export const APPROVE_RECIPIENT_TOKEN_EXPIRED_EMAIL_RESENT =
  'APPROVE_RECIPIENT_TOKEN_EXPIRED_EMAIL_RESENT';

const TYPES = {
  APPROVE_RECIPIENT,
  APPROVE_RECIPIENT_SUCCEEDED,
  APPROVE_RECIPIENT_FAILED,
  APPROVE_RECIPIENT_TOKEN_EXPIRED_EMAIL_RESENT
};

export default TYPES;
