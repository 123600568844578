import styled from 'styled-components';

const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 0px 10px;

  div {
    padding: 0px 10px;

    p {
      font-family: Montserrat Bold;
      margin-bottom: 0px;
    }
  }
`;

const Header = ({ children }) => <HeaderDiv>{children}</HeaderDiv>;

export default Header;
