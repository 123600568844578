import styled from 'styled-components';

const SubHeader = styled.div<any>`
  display: flex;
  flex-direction: row;

  font-family: Montserrat Bold;
  font-size: 18px;
  color: #2d3436;
  margin-bottom: 20px;
  margin-top: 40px;

  p {
    margin-bottom: 0px;
  }

  ${({ marginRight }) =>
    marginRight &&
    `
      margin-right: ${marginRight}px;
  `}

  @media (max-width: 700px) {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 17px;
    ${({ hideOnMobile }) =>
      hideOnMobile &&
      `
      display: none;
      `}
  }
`;

export default SubHeader;
