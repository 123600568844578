import { takeEvery } from 'redux-saga/effects';
import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import types from '../types';
import { AuthenticationService } from '../../../../../services/AuthenticationService';
import {
  resetPasswordFailed,
  resetPasswordSucceeded,
  setPasswordFailed,
  setPasswordSucceeded
} from '../actions';
import { push } from 'connected-react-router';

function* resetPassword(action) {
  try {
    const authService = AuthenticationService.instance;
    const success = yield call(
      authService.resetPassword.bind(authService),
      action.payload
    );
    if (success === true) {
      toast.success(
        'Die Anfrage zum Zurücksetzen des Passworts wurde erfolgreich gesendet. Bitte prüfen Sie Ihre E-Mails.',
        {
          position: 'top-right',
          toastId: 401
        }
      );
      yield put(resetPasswordSucceeded());
    } else {
      throw new Error('Reset password failed');
    }
  } catch (error) {
    toast.error(
      'Die Anfrage zum Zurücksetzen des Passworts konnte nicht gesendet werden.',
      {
        position: 'top-right',
        toastId: 300
      }
    );
    yield put(resetPasswordFailed());
  }
}

function* setPassword(action) {
  try {
    const authService = AuthenticationService.instance;
    const success = yield call(
      authService.setPassword.bind(authService),
      action.payload.password,
      action.payload.token
    );
    if (success === true) {
      toast.success('Das Passwort wurde erfolgreich zurückgesetzt.', {
        position: 'top-right',
        toastId: 401
      });
      yield put(setPasswordSucceeded());
      yield put(push('/login'));
    } else {
      throw new Error('Set password failed');
    }
  } catch (error) {
    console.log(error);
    toast.error('Das Passwort konnte nicht zurückgesetzt werden.', {
      position: 'top-right',
      toastId: 300
    });
    yield put(setPasswordFailed());
  }
}

const sagas = [
  takeEvery(types.SET_PASSWORD, setPassword),
  takeEvery(types.RESET_PASSWORD, resetPassword)
];

export default sagas;
