import { createAction } from 'redux-actions';
import types from '../types';

export const toggleListView = createAction(types.TOGGLE_LIST_VIEW);

export const toggleAddButtonModal = createAction(types.TOGGLE_ADD_BUTTON_MODAL);
export const addButton = createAction(types.ADD_BUTTON);
export const addButtonSucceeded = createAction(types.ADD_BUTTON_SUCCEEDED);
export const addButtonFailed = createAction(types.ADD_BUTTON_FAILED);

export const autoAcceptAlarm = createAction(types.AUTO_ACCEPT_ALARM);
export const autoAcceptAlarmSucceeded = createAction(
  types.AUTO_ACCEPT_ALARM_SUCCEEDED
);
export const autoAcceptAlarmFailed = createAction(
  types.AUTO_ACCEPT_ALARM_FAILED
);
