import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  scanTime: 6,
  scanningCycle: 24,
  minimalRssi: 0,
  timeSlot: 300,
  advInterval: 10,
  advTxPower: 0,
  advTimeout: 30,
  advCompanyId: 0,
  advDeviceId: 0,
  beaconInterval: 5,
  beaconTxPower: 0,
  beaconMajor: 1,
  beaconMinor: 1,
  masterConnectionInterval: 6,
  masterSlaveLatency: 499,
  masterSupervisionTimeout: 376,
  uuid: 'd664d4ce-f626-475e-8efa-78a63c839ffc'
};

export default handleActions(
  {
    [types.GET_DEVICE_SUCCEEDED]: (state, action: any) => {
      return action.payload.secufy_Bluetoothettings
        ? action.payload.secufy_BluetoothSettings
        : initialState;
    },

    [types.EDIT_BLUETOOTH_SETTINGS]: (state, action: any) => {
      const { scope, value } = action.payload;

      return {
        ...state,
        [scope]: value
      };
    }
  },
  initialState
);
