import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MatTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoPopover from '../../../../../../components/InfoPopover';

import EditHelperModal from '../../components/EditHelperModal/container';
import FilledRecipient from '../../components/FilledHelper';
import EmptyRecipient from '../../components/EmptyHelper';
import GeneralSettings from '../../components/GeneralSettings/container';
import SubHeader from '../../components/SubHeader';
import LoadingIndicator from '../../../../../../components/LoadingIndicator';
import EmergencyPlans from '../../components/EmergencyPlans/container';
import ButtonSettings from '../../components/ButtonSettings/container';
import Settings from '../../components/Settings/container';
import BeaconSettings from '../../components/BeaconSettings/container';
import TrackingSettings from '../../components/TrackingSettings/container';
import BluetoothSettings from '../../components/BluetoothSettings/container';
import Info from '../../components/Info/container';
import { matchesScope } from '../../../../../../components/DisplayController';
import { DisplayScope } from '../../../../../../services/AuthenticationService';
import CloseAlarmModal from '../../../../../../components/DeviceTile/components/CloseAlarmModal/container';
import AvatarEditor from '../../../../../../components/AvatarEditor/container';
import { useEffect } from 'react';
import { requestButtonInfo } from '../../actions';

const TapWrapper = styled.div`
  margin: 0px -30px -30px -30px;
  background-color: #fafafa !important;
  margin-top: -40px;
  padding: 0px 30px 30px 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  min-width: 800px;

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none !important;
`;

const StyledTabs = styled(MatTabs)`
  background-color: #fafafa;

  .MuiTab-textColorInherit {
    color: #707070 !important;
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: #ea6000 !important;
  }
`;

const PopoverLine = styled.p`
  margin-bottom: 0px;
`;

const StyledBox = styled(Box)`
  padding: 24px 0px 0px 0px !important;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox p={3}>
          <Typography>{children}</Typography>
        </StyledBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const buildRecipients = (recipients, openHelperModal, device) => {
  const recipientElements: JSX.Element[] = [];
  let i = 0;
  for (i; i < recipients.length; i++) {
    const recipient = recipients[i];
    if (recipient.role === 'helper') {
      recipientElements.push(
        <FilledRecipient
          key={recipient.id}
          {...recipient}
          edit={() => openHelperModal(recipient)}
          editable={matchesScope(DisplayScope.OWNER, device.device?.id)}
        ></FilledRecipient>
      );
    }
  }

  if (
    matchesScope(DisplayScope.OWNER, device.device?.id) &&
    recipients.filter((recipients) => recipients.role === 'helper').length < 9
  ) {
    recipientElements.push(
      <EmptyRecipient
        role="helper"
        key={i}
        title={'Sicherheitskontakt'}
        add={() => openHelperModal({ role: 'helper' })}
      ></EmptyRecipient>
    );
  }
  return recipientElements;
};

const Tabs = ({
  overview,
  openHelperModal,
  recipients,
  device,
  history,
  match
}) => {
  const classes = useStyles();
  const selectedTab = parseInt(match?.params?.tabId || 0, 10);

  const handleChange = (event, newValue) => {
    history.push(`/device/${device.device?.id}/${newValue}`);
  };

  useEffect(() => {
    if (device.device) requestButtonInfo(device.device?.id);
  }, [device.device]);

  return (
    <TapWrapper className={classes.root}>
      <StyledAppBar position="static">
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab label="Detail" {...a11yProps(0)} />
          <Tab label="Kontakte" {...a11yProps(1)} />
          <Tab label="Notfallpläne" {...a11yProps(2)} />
          {matchesScope(DisplayScope.OWNER, device.device?.id) && (
            <Tab label="Homezone" {...a11yProps(3)} />
          )}
          {matchesScope(DisplayScope.OWNER, device.device?.id) && (
            <Tab label="Einstellungen" {...a11yProps(4)} />
          )}
          {matchesScope(DisplayScope.SECUFY_ADMIN, device.device?.id) && (
            <Tab label="Erweiterte Einstellungen" {...a11yProps(5)} />
          )}
          {matchesScope(DisplayScope.SECUFY_ADMIN, device.device?.id) && (
            <Tab label="Tracking" {...a11yProps(6)} />
          )}
          {matchesScope(DisplayScope.SECUFY_ADMIN, device.device?.id) && (
            <Tab label="BLE" {...a11yProps(7)} />
          )}
        </StyledTabs>
      </StyledAppBar>

      <TabPanel value={selectedTab} index={0}>
        <Container>
          <Content>
            <Info />
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        {!overview.isLoading && (
          <Container>
            <Content>
              <GeneralSettings />
              <SubHeader>
                <p>Sicherheitskontakte:</p>

                <InfoPopover
                  title="Sicherheitskontakte:"
                  id="emergency-contacts"
                >
                  <PopoverLine>
                    Als Administrator tragen sie hier bis zu 9 Personen ein, die
                    im Notfall eine Nachricht erhalten sollen (per E-Mail, SMS
                    und/oder Anruf). E-Mail-Adresse ist Pflicht. Bis zu zwei
                    Telefonnummern sind optional, bitte kontrollieren Sie die
                    Korrektheit der Telefonnummern sorgfältig. Wählen sie eine
                    der Kontaktarten aus, über die der Sicherheitskontakt im
                    Alarmfall informiert wird. Die Sicherheitskontakte müssen
                    den Empfang von Alarmen bestätigen, danach wechseln die
                    Symbole für die eingestellten Kontaktarten von Rot auf Grün.
                    Wenn ein Sicherheitskontakt vorübergehend (z.B. Urlaub)
                    nicht erreichbar ist, entfernen Sie das Häkchen bei
                    „Empfangen von Alarmen“, die Symbole für die Kontaktarten
                    sind dann wieder rot gefärbt.
                  </PopoverLine>
                </InfoPopover>
              </SubHeader>
              <div>{buildRecipients(recipients, openHelperModal, device)}</div>
            </Content>
          </Container>
        )}
        {overview.isLoading && <LoadingIndicator></LoadingIndicator>}
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <Container>
          <Content>
            <EmergencyPlans />
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={3}>
        <Container>
          <Content>
            <BeaconSettings></BeaconSettings>
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={4}>
        <Container>
          <Content>
            <Settings />
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={5}>
        <Container>
          <Content>
            <ButtonSettings />
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={6}>
        <Container>
          <Content>
            <TrackingSettings />
          </Content>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={7}>
        <Container>
          <Content>
            <BluetoothSettings />
          </Content>
        </Container>
      </TabPanel>

      <AvatarEditor />
      <CloseAlarmModal />
      <EditHelperModal />
    </TapWrapper>
  );
};

export default Tabs;
