import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { editButtonSettings, saveButtonSettings } from '../../../actions';

import ButtonSettings from '..';

const mapStateToProps = (state, ownProps) => ({
  buttonSettings: state.buttonSettings
});

const mapDispatchToProps = (dispatch) => ({
  edit: (scope, value) => dispatch(editButtonSettings({ scope, value })),
  save: () => dispatch(saveButtonSettings())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ButtonSettings)
);
