import { C8yBaseService } from './C8yBaseService';

export type Config = {
  recipients: Recipient[];
  userName: string;
  ownerEmail: string;
  batteryWarning: string;
  alertPlan: string;
};

export interface Recipient {
  id: number;
  name: string;
  email: string;
  phonenumber: string;
}
export class ConfigService extends C8yBaseService {
  /**
   * Fetches the list of repicients/helpers from the device configuration
   * @param deviceId id of the cumulocity device
   */
  async getConfig(deviceId: string): Promise<Config> {
    const url = `${this.baseUrl}/inventory/managedObjects/${deviceId}`;
    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer' // no-referrer, *client
    });
    const device = await response.json();
    try {
      return JSON.parse(device.c8y_Configuration.config);
    } catch (e) {
      return {
        recipients: [],
        userName: '',
        ownerEmail: '',
        batteryWarning: '',
        alertPlan: ''
      };
    }
  }

  async getHardwareInfo(
    deviceId: string
  ): Promise<{ firmwareVersion: string }> {
    const url = `${this.baseUrl}/inventory/managedObjects/${deviceId}`;
    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer' // no-referrer, *client
    });
    const device = await response.json();
    try {
      return JSON.parse(device.secufy_Hardware);
    } catch (e) {
      return {
        firmwareVersion: ''
      };
    }
  }

  /**
   * Write recipients into the device configuration
   * @param deviceId id of the cumulocity device
   * @param recipients the repicients to write
   */
  private async setConfig(deviceId: string, config: Config): Promise<boolean> {
    const url = `${this.baseUrl}/inventory/managedObjects/${deviceId}`;

    const Authorization = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({
        c8y_Configuration: {
          config: JSON.stringify(config)
        }
      })
    });

    return response.status === 200;
  }

  /**
   * Write username into the device configuration
   * @param deviceId id of the cumulocity device
   * @param userName username
   */
  async setUserName(deviceId: string, userName: string): Promise<boolean> {
    let config: Config = await this.getConfig(deviceId);
    config.userName = userName;

    return await this.setConfig(deviceId, config);
  }

  /**
   * Write alertPlan into the device configuration
   * @param deviceId id of the cumulocity device
   * @param alertPlan alertPlan
   */
  async setAlertPlan(deviceId: string, alertPlan: string): Promise<boolean> {
    let config: Config = await this.getConfig(deviceId);
    config.alertPlan = alertPlan;

    return await this.setConfig(deviceId, config);
  }

  /**
   * Write settings into the device configuration
   * @param deviceId id of the cumulocity device
   * @param userName username
   */
  async setSettings(deviceId: string, settings: object): Promise<boolean> {
    let config: Config = await this.getConfig(deviceId);

    config = {
      ...config,
      ...settings
    };

    return await this.setConfig(deviceId, config);
  }

  /**
   * Writes email into config
   * @param deviceId id of the cumulocity device
   * @param email the repicients to write
   */
  async setOwnerEmail(deviceId: string, email: string): Promise<boolean> {
    let config: Config = await this.getConfig(deviceId);
    config.ownerEmail = email;

    return await this.setConfig(deviceId, config);
  }

  /**
   * Write recipients into the device configuration
   * @param deviceId id of the cumulocity device
   * @param recipients the repicients to write
   */
  async setRecipients(deviceIds: string | string[], recipients: Recipient[]) {
    const set = async (deviceId: string, recipients: Recipient[]) => {
      let config = await this.getConfig(deviceId);
      config = {
        ...config,
        recipients
      };

      return await this.setConfig(deviceId, config);
    };

    if (Array.isArray(deviceIds)) {
      return await Promise.all(
        deviceIds.map((deviceId) => {
          return set(deviceId, recipients);
        })
      );
    } else {
      return await set(deviceIds, recipients);
    }
  }

  async setGeneralSettings(deviceIds: string | string[], settings: Config) {
    const set = async (deviceId: string, settings: Config) => {
      const { userName = '', ownerEmail = '', batteryWarning = '' } = settings;
      let config = await this.getConfig(deviceId);
      config = {
        ...config,
        userName,
        ownerEmail,
        batteryWarning
      };

      return await this.setConfig(deviceId, config);
    };

    if (Array.isArray(deviceIds)) {
      return await Promise.all(
        deviceIds.map((deviceId) => {
          return set(deviceId, settings);
        })
      );
    } else {
      return await set(deviceIds, settings);
    }
  }
}
