// @ts-nocheck

import { handleActions } from 'redux-actions';
import types from '../../types';

const initialState = {
  loading: false,
  device: null
};

export default handleActions(
  {
    [types.GET_DEVICE]: (state, { payload }) => ({
      ...initialState,
      loading: true
    }),
    [types.GET_DEVICE_SUCCEEDED]: (state, { payload }) => ({
      loading: false,
      device: payload
    }),
    [types.REFETCHED_DEVICE]: (state, { payload }) => ({
      loading: false,
      device: payload
    }),
    [types.GET_DEVICE_FAILED]: (state, { payload }) => initialState
  },
  initialState
);
