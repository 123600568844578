import { Component } from 'react';
import sizeMe from 'react-sizeme';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import { matchesScope } from '../../../../../../components/DisplayController';
import { DisplayScope } from '../../../../../../services/AuthenticationService';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px #e2e2e2
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 25px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Email = styled(EmailIcon)`
  display: flex;
  width: 40px;
  margin-left: auto;
  margin-right: 30px;
`;

const Sms = styled(SmsIcon)`
  display: flex;
  width: 40px;
  margin-left: auto;
  margin-right: 30px;
`;

const Phone = styled(PhoneEnabledIcon)`
  display: flex;
  width: 40px;
  margin-left: auto;
  margin-right: 30px;
`;

const Title = styled.p`
  color: #707070;
  font-size: 17px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
`;

const Text = styled.p`
  color: #707070;
  margin-bottom: 5px;
  font-family: 'Montserrat';
`;

const Icon = styled.p`
  display: flex;
  padding-right: 25px;
`;

const getDisplayName = (firstName: string, lastName: string) => {
  let title = '-';
  if (firstName || lastName) {
    title = (firstName ? firstName : ' ') + ' ' + (lastName ? lastName : ' ');
  }
  return title;
};

/** Page Content */
class FilledHelper extends Component<any, any> {
  render() {
    const {
      role,
      user,
      approved,
      smsEnabled,
      smsEnabled2,
      emailEnabled,
      voicemailEnabled,
      voicemailEnabled2,
      phoneNumber,
      phoneNumber2,
      receiveAlarms,
      edit,
      device,
      firstName,
      lastName
    } = this.props;

    let title = getDisplayName(firstName, lastName);

    function getEmailIcon() {
      if (role === 'wearer') {
        if (
          (!receiveAlarms && approved && emailEnabled) ||
          (!approved && user.email && emailEnabled)
        ) {
          return <Email style={{ color: 'red' }} />;
        }
        if (approved && emailEnabled) {
          return <Email style={{ color: 'lightgreen' }} />;
        }
      } else {
        if (
          (!receiveAlarms && approved && emailEnabled) ||
          (!approved && emailEnabled)
        ) {
          return <Email style={{ color: 'red' }} />;
        }
        if (approved && emailEnabled) {
          return <Email style={{ color: 'lightgreen' }} />;
        }
      }
      return <Email style={{ color: 'lightgrey' }} />;
    }

    function getSmsIcon1() {
      if (role === 'wearer') {
        if (
          (!receiveAlarms && smsEnabled) ||
          (!approved && user.email && smsEnabled)
        ) {
          return <Sms style={{ color: 'red' }} />;
        }
        if (smsEnabled) {
          return <Sms style={{ color: 'lightgreen' }} />;
        }
      } else {
        if ((!receiveAlarms && smsEnabled) || (!approved && smsEnabled)) {
          return <Sms style={{ color: 'red' }} />;
        }
        if (smsEnabled) {
          return <Sms style={{ color: 'lightgreen' }} />;
        }
      }
      return <Sms style={{ color: 'lightgrey' }} />;
    }

    function getSmsIcon2() {
      if (role === 'wearer') {
        if (
          (!receiveAlarms && smsEnabled2) ||
          (!approved && user.email && smsEnabled2)
        ) {
          return <Sms style={{ color: 'red' }} />;
        }
        if (smsEnabled2) {
          return <Sms style={{ color: 'lightgreen' }} />;
        }
      } else {
        if ((!receiveAlarms && smsEnabled2) || (!approved && smsEnabled2)) {
          return <Sms style={{ color: 'red' }} />;
        }
        if (smsEnabled2) {
          return <Sms style={{ color: 'lightgreen' }} />;
        }
      }
      return <Sms style={{ color: 'lightgrey' }} />;
    }

    function getPhoneIcon1() {
      if (role === 'wearer') {
        if (
          (!receiveAlarms && voicemailEnabled) ||
          (!approved && user.email && voicemailEnabled)
        ) {
          return <Phone style={{ color: 'red' }} />;
        }
        if (voicemailEnabled) {
          return <Phone style={{ color: 'lightgreen' }} />;
        }
      } else {
        if (
          (!receiveAlarms && voicemailEnabled) ||
          (!approved && voicemailEnabled)
        ) {
          return <Phone style={{ color: 'red' }} />;
        }
        if (voicemailEnabled) {
          return <Phone style={{ color: 'lightgreen' }} />;
        }
      }
      return <Phone style={{ color: 'lightgrey' }} />;
    }

    function getPhoneIcon2() {
      if (role === 'wearer') {
        if (
          (!receiveAlarms && voicemailEnabled2) ||
          (!approved && user.email && voicemailEnabled2)
        ) {
          return <Phone style={{ color: 'red' }} />;
        }
        if (voicemailEnabled2) {
          return <Phone style={{ color: 'lightgreen' }} />;
        }
      } else {
        if (
          (!receiveAlarms && voicemailEnabled2) ||
          (!approved && voicemailEnabled2)
        ) {
          return <Phone style={{ color: 'red' }} />;
        }
        if (voicemailEnabled2) {
          return <Phone style={{ color: 'lightgreen' }} />;
        }
      }
      return <Phone style={{ color: 'lightgrey' }} />;
    }

    function getEditButton() {
      if (matchesScope(DisplayScope.OWNER, device.device?.id)) {
        return (
          <EditButton onClick={edit}>
            <FontAwesomeIcon color="#707070" size="1x" icon={['far', 'edit']} />
          </EditButton>
        );
      } else {
        return (
          <EditButton>
            <FontAwesomeIcon color="#707070" size="1x" icon={['far', 'edit']} />
          </EditButton>
        );
      }
    }

    return (
      <Container>
        <Header>
          <Title>{title}</Title>

          {getEditButton()}
        </Header>
        <br />{' '}
        <Wrapper>
          <Row>
            <Text>Email: {user?.email || ' -'}</Text>
            <Icon>{getEmailIcon()}</Icon>
          </Row>

          <Row>
            <Text>Tel1: {phoneNumber || '-'}</Text>
            <Icon>
              {getPhoneIcon1()} {getSmsIcon1()}
            </Icon>
          </Row>

          <Row>
            <Text>Tel2: {phoneNumber2 || '-'}</Text>
            <Icon>
              {getPhoneIcon2()} {getSmsIcon2()}
            </Icon>
          </Row>
        </Wrapper>
        {!approved && user?.email ? (
          <Text style={{ color: 'red' }}>
            <br />

            {(firstName ? firstName : 'der Sicherheitskontakt') +
              ' erhält noch keine Alarme. Klicke für die Freigabe den an ' +
              (user.email
                ? user.email
                : 'die Email-Adresse des Sicherheitskontakt') +
              ' gesendeten Link.'}
          </Text>
        ) : (
          ''
        )}
      </Container>
    );
  }
}

// @ts-ignore
export default sizeMe({ monitorHeight: true, noPlaceholder: true })(
  FilledHelper
);
